import {AxiosRequestConfig, AxiosResponse} from "axios";
import { countriesService } from "../axiosServices";


const getCountries = async(config: AxiosRequestConfig = {}): Promise<AxiosResponse<any>> => {
    const result = await countriesService.get(`/`, config,);
    return result.data;
}

const getStates = async(country, config: AxiosRequestConfig = {}): Promise<AxiosResponse<any>> => {
    const result = await countriesService.get(`/states/q?country=${country}`, config,);
    return result.data;
}

export {getCountries, getStates};