import React from "react";
import { useDispatch } from "react-redux";
import { setLanguageChanged } from "../../redux/actions/language";
import { LanguageSelectorDropDown } from 'gigi-component-library';
import { useSelector } from "react-redux";

export default function LanguageDropDown() {
    const dispatch = useDispatch();
    const appLanguage = useSelector((state: any) => state.languageChange.language);

    const setApplicationLangHandler = (target: any) => {
        dispatch(setLanguageChanged(target))
    }

    return (<>
        {/* <select style={{ position: 'fixed' }} value={appLanguage} onChange={setApplicationLangHandler}>
            <option value='en'>
                En
            </option>
            <option value='fr'>
                Fr
            </option>
        </select> */}
        <LanguageSelectorDropDown handleLangChange={setApplicationLangHandler} language={appLanguage} />
    </>);
}