import { profileService } from "../axiosServices";
import { AxiosRequestConfig, AxiosResponse } from "axios";

interface RequestBodyInterface{
    payload:any;
    token:String

}

const getBrandingInfo = async (postBody: RequestBodyInterface, config: AxiosRequestConfig = {}) => {
    const headers = {
        "Bookingchannel": "external", // Example header, adjust as needed
        "Correlationid": " f149d7a0-76e0-4e6b-a629-34944c2a6374",
        "Flighttype":"CAD"
        // Add more headers as needed
    };

    // Merge provided config with headers
    const requestConfig: AxiosRequestConfig = {
        ...config,
        headers: {
            ...config?.headers,
            ...headers
        }
    };
    
    let data:any=await profileService.post(`/travelBusinesses/branding/fetchBranding`,postBody, requestConfig);
    
    return data.data;
}

export { getBrandingInfo };