import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ACTIONS } from "../constants/actions";

export interface giftCardResultState {
    giftCard: {};
}

const initialState: giftCardResultState = {
    giftCard: {}
}

const giftCardResult = createSlice({
    name: "giftCardResult",
    initialState,
    reducers: {
        [`${ACTIONS.GIFTCARD_RESULT}`]: (state, action: PayloadAction<any[]>) => {
            return {...state, giftCard: action.payload}
        }
    }
});

export const { GIFTCARD_RESULT } = giftCardResult.actions;
export default giftCardResult.reducer;
