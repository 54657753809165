
import React, { useState, useEffect } from 'react';
import { Grid, Typography, FormControl, Box, RadioGroup, Radio, Button, FormControlLabel } from '@mui/material';
import { TRIPTYPE, upliftErrorMessage, paymentlabel, UPLIFTTRIPTYPE } from '../../utils/constant';
import PaymentIcon from '@mui/icons-material/Payment';
import { ErrorHandler } from "../Error/ErrorNotifier";
import "../../styles/pages/_Uplift.scss";
import { PriceSummaryComponentProps, AirReservation, Insurance } from './interfaces';
import { translateSegmentsData, formatDOB, getInsuranceData } from './CommonFunction';
import { calculateTotalForPassenger } from '../../utils/util';
// @ts-ignore 
import InfoIcon from '../../assets/icons/Icon-info-circle.svg';
import { useTranslation } from 'react-i18next';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
// @ts-ignore
import {ReactComponent as IconHandDollar } from '../../assets/icons/icon-hand-dollar.svg';
import { useSelector } from 'react-redux';
declare global {
    interface Window {
        Uplift: any;
        upReady: () => void;
    }
}

const PaymentWithUplift: React.FC<PriceSummaryComponentProps> = ({ itineraryFare, travellers, TripInfoData, segments, triptype, handleOtherPaymentChange, onCardInfoUpdate, newQuoteRequest, priceData, validateUpliftForm,handleIsFormValid }) => {
    const { t } = useTranslation();
    const [disable, setdisable] = useState(false);
    const [tokenReady, setTokenReady] = useState(false);
    const [monthlyAmount, setmonthlyAmount] = useState(0);
    const [reasonCode, setReasonCode] = useState(null);
    const [travelersData, setTravelersData] = useState(TripInfoData.travelers);
    const [updatetravelersData, setupdatetravelersData] = useState(false);
    const [paymentOption, setPaymentOption] = useState(paymentlabel.FULLPAYMENT);
    const [cardInfor, setCardInfor] = useState(null);
    const language = useSelector((state: any) => state.languageChange.language);

    const totalFlightPrice = itineraryFare?.paxFares?.reduce((acc: number, current: any) => {
        return acc + calculateTotalForPassenger(current);
    }, 0);
    const displayCurrency = '$';
    const getPhoneNumber = (phoneNumber: any) => {
        if (phoneNumber) {
            const formattedValue = phoneNumber.startsWith('+') ? phoneNumber : `+${phoneNumber}`;
            const phoneNumberParsed = parsePhoneNumberFromString(formattedValue);
            if (phoneNumberParsed) {
                return phoneNumberParsed.nationalNumber || "";
            }
        }
        return ""
    }

    const totalFare = totalFlightPrice !== undefined ? totalFlightPrice.toFixed(2) : '';

    const UPLIFT_CONFIG = process.env.UPLIFT_CONFIG || {};
    const { API_KEY, LOCALE, CURRENCY, UPLIFT_LOGO } = UPLIFT_CONFIG as { [key: string]: string };

    let itineraryData = translateSegmentsData(segments);
    const firstSegment = segments[0]?.flights?.[0];
    const lastSegment = segments[segments.length - 1]?.flights?.[0];

    let paxInsuranceDetails: Insurance[] = [];
    if (newQuoteRequest && newQuoteRequest.paxInsArray) {

        paxInsuranceDetails = getInsuranceData(newQuoteRequest);
    }

    let tripTypelabel = UPLIFTTRIPTYPE.ONEWAY;
    if (triptype.label === TRIPTYPE.ROUNDTRIP) {
        tripTypelabel = UPLIFTTRIPTYPE.ROUNDTRIP;
    }else if(triptype.label === TRIPTYPE.MULTICITY){
        tripTypelabel = UPLIFTTRIPTYPE.MULTICITY
    }

    const airReservations: AirReservation[] = [
        {
            airline_name: firstSegment?.carrierName ?? '',
            origin: firstSegment?.departureAirport ?? '',
            destination: lastSegment?.departureAirport ?? '',
            trip_type: tripTypelabel,
            itinerary: itineraryData,
            insurance: paxInsuranceDetails
        }
    ];

    let billingContact = {
        "phone": getPhoneNumber([...travellers]?.[0]?.mobileNumber) ?? '',
        // "phone": [...travellers][0]?.mobileNumber,
        "email": travellers[0]?.email ?? ''
    }
    let tripInfoDetails = {
        air_reservations: airReservations,
        travelers: travelersData,
        billing_contact: billingContact,
        order_amount: totalFare * 100
    }

    const handlePaymentChange = (option: any) => {

        setPaymentOption(option);
        handleOtherPaymentChange(paymentlabel.FULLPAYMENT);

        if (option === paymentlabel.UPLIFTPAYMENT && !validateUpliftForm()) {
            setPaymentOption(paymentlabel.FULLPAYMENT);
            ErrorHandler('Fill required information', 'Please fill out your first and last names along with any other required information in the Traveller section before selecting Pay Monthly', null, 'Ok', 'warning', 'alertModal');
            return;
        }

        if (option === paymentlabel.UPLIFTPAYMENT && updatetravelersData) {
            handleIsFormValid(false);
            handleOtherPaymentChange(paymentlabel.UPLIFTPAYMENT);
            window.Uplift.Payments.load(tripInfoDetails);
            const timeoutId = setTimeout(function () {
                window.Uplift.Payments.select(function () {
                    clearTimeout(timeoutId);
                });
            }, 100);
        }
    };




    useEffect(() => {
        const checkValidPaxInfo = (travellers: any[]) => {
            const updatedTraveleres = travellers.reduce((acc, pax, key) => {
                if (pax.firstName && pax.lastName && pax.dayOfBirth && pax.monthOfBirth && pax.yearOfBirth) {
                    let combineDOB = `${pax.dayOfBirth}/${pax.monthOfBirth}/${pax.yearOfBirth}`;
                    acc.push({
                        id: key,
                        first_name: pax.firstName,
                        last_name: pax.lastName,
                        date_of_birth: formatDOB(combineDOB),
                    });
                }
                return acc;
            }, []);
            if (updatedTraveleres.length === travellers.length) {
                setupdatetravelersData(true);
                setTravelersData(updatedTraveleres);
            }
        };

        checkValidPaxInfo(travellers);
    }, [travellers]);


    useEffect(() => {
       
        if (totalFare > 0) {
           
            if (!window?.Uplift?.Payments) {
                window.upReady = startUplift;
                return;
            } 
            startUplift();
        }
    }, [totalFare,language]);
    const upliftCallback = (response: any) => {
        const { offer, reason, token } = response;
        // setCardInfor(null);
        const statusHandlers = {
            OFFER_AVAILABLE: function () {
                setdisable(false);
                setTokenReady(false);
            },
            TOKEN_AVAILABLE: function () {
                window.Uplift.Payments.getToken();
                setdisable(false);
                setTokenReady(true);
            },
            TOKEN_RETRIEVED: function () {
                setCardInfor(token);

            },
            OFFER_UNAVAILABLE: function () {
                setReasonCode(reason ? reason[0] : null);
                setdisable(true);
                if (paymentOption == "upliftpayment") {
                    setPaymentOption('fullPayment');
                    window.Uplift.Payments.deselect("fullPayment");
                }
            },
            SERVICE_UNAVAILABLE: function () {
                setdisable(true);
            }
        };
        const status = response.status as keyof typeof statusHandlers;
        const handler = statusHandlers[status];
        if (handler) {
            handler();
        } else {
            console.log(`Unknown status: ${status}`);
        }
    }
    const startUplift = () => {
        try {
            if (tripInfoDetails && window.Uplift?.Payments) {
                window.Uplift.Payments.init({
                    apiKey: API_KEY,
                    locale: language =='fr'?'fr-CA':'en-CA',
                    checkout: true,
                    currency: CURRENCY,
                    container: '#up-pay-monthly-container',
                    onChange: upliftCallback
                });

                const timeoutId = setTimeout(function() {   
                    window.Uplift.Payments.load(tripInfoDetails, function () {
                        clearTimeout(timeoutId);
                    });
                }, 100);
            }
        } catch (error) {
            console.error("Failed to initialize Uplift Payments:", error);
        }
    }
    const optStyle = {
        display: paymentOption === "upliftpayment"
            ? "block"
            : "none",
        height: paymentOption === "upliftpayment" ? "auto" : "0px",
    };
    useEffect(() => {
        if (cardInfor) {
            handleIsFormValid(true);
            onCardInfoUpdate(cardInfor);
        }
    }, [cardInfor]);
    return (
        <>
            {/* <Box className="Booking_TitleBox" mt={2}>
            <PaymentIcon className='Booking_TitleIcon' />
                <Typography variant="h6" className='Booking_SecondaryTitle'>
                    Select Payment option
                </Typography>
            </Box> */}
            <Grid container spacing={2} className='Booking_Flexibleoptions'>
                <Box className='Booking_PayTitleHeader'>
                    <IconHandDollar fill="red" className='iconhanddollar' />
                    <Typography variant='h4' className='Booking_PayTitleTop'>{t('Flexible payment options')}</Typography>
                </Box>
                <Grid item xs={12}>
                    <Box className="Booking_paymentTab">
                        <Box className={paymentOption == 'fullPayment' ? "Booking_payTabs Booking_payActive" : "Booking_payTabs Booking_payDeActive"} onClick={() => handlePaymentChange('fullPayment')}>
                            <Typography className="Booking_payPrice" component='span'>
                                {
                                    priceData.giftCardTotal > 0
                                        ? displayCurrency+priceData.finalAmount.toFixed(2)
                                        : displayCurrency+(totalFlightPrice !== undefined ? totalFare : '')
                                }
                            </Typography>
                            <Typography className="Booking_payTitle">{t('Pay the full amount')}</Typography>
                        </Box>

                        <Box className={paymentOption === 'upliftpayment' ? "Booking_payTabs Booking_payActive" : "Booking_payTabs Booking_payDeActive"}
                            onClick={() => {
                                if (priceData.giftCardTotal == 0 ) {
                                    handlePaymentChange('upliftpayment');
                                }
                            }}
                        >

                            <Box className="Booking_payPopBtn">
                                <Typography className="Booking_payPrice" component={'span'}>{t('from')} </Typography>
                                {totalFare > 0 && (<>
                                    <Button className={disable ? ' Booking_payPrice' : "Booking_payPrice displayFlex"}
                                        data-up-price-value={totalFare * 100}
                                        data-up-price-type='total'
                                        data-up-comparison-type=""
                                        data-up-price-model='total'
                                        data-up-taxes-included='true'
                                    >
                                        <span className="up-from-currency" data-up-from-currency="">$</span>
                                        <span data-up-from-currency-unit-major="" className="mb-0"  ></span>/mo
                                        <span data-up-promo="zero-percent" className="upliftStyle">  </span>
                                    </Button>

                                    <span data-up-error="" className={'upliftNotAvailable ' +`${!disable ? 'upliftErrorHide d-none' : ''} `}>
                                        {t('Not Available')}
                                        <span data-up-tooltip="">
                                            {upliftErrorMessage.hasOwnProperty(reasonCode)
                                                ? upliftErrorMessage[reasonCode as keyof typeof upliftErrorMessage]
                                                : ''}
                                        </span>
                                    </span>
                                </>)}
                            </Box>

                            <Box className="Booking_PayInfo">
                                <Typography className={`Booking_payTitle ${language == 'fr' ? 'payUpliftTitleFr' : ''}`}>{t('Pay monthly with')} <img alt="uplift logo" className="uplift-logo-2" src={UPLIFT_LOGO} />
                                </Typography>
                                <Button className={disable ? 'upliftStyle ' : " displayFlex"}
                                    data-up-price-value={totalFare * 100}
                                    data-up-price-type='total'
                                    data-up-comparison-type=""
                                    data-up-price-model='total'
                                    data-up-taxes-included='true'
                                >
                                    <img src={InfoIcon} />
                                </Button>
                            </Box>
                        </Box>
                    </Box>

                    {/* <FormControl component="fieldset">
                        <RadioGroup
                            aria-label="paymentOption"
                            name="paymentOption"
                            value={paymentOption}
                            onChange={handlePaymentChange}
                        >
                            <FormControlLabel
                                value="fullPayment"
                                control={<Radio />}
                                label={`Pay the full amount ${totalFlightPrice !== undefined ? totalFare + " CAD" : ''}`}
                            />
                            <Grid container alignItems="center" spacing={2} className='displayFlex'>
                                <Grid item className='MR8'>
                                    <FormControlLabel
                                        value="upliftpayment"
                                        control={<Radio />}
                                        label="Pay Monthly"
                                        disabled={disable}
                                    />
                                </Grid>
                                {totalFare > 0 && (
                                    <Grid item>
                                        <Grid container alignItems="center" spacing={2}>
                                            <Grid item>
                                              <Button
                                                    className={disable ? 'upliftStyle' : "d-inline-block monthly-price-container font-weight-bold btn-unstyled displayFlex"}
                                                    data-up-price-value={totalFare * 100}
                                                    data-up-price-type='total'
                                                    data-up-comparison-type=""
                                                    data-up-price-model='total'
                                                    data-up-taxes-included='true'
                                                >
                                                    <div className="mr-1 d-inline-block">from </div>
                                                    <span className="up-from-currency" data-up-from-currency="">$</span>
                                                    <span data-up-from-currency-unit-major="" className="mb-0" id="up-monthly-price"></span>/month
                                                    <span data-up-promo="zero-percent" className="upliftStyle"> Free Payment </span>
                                                </Button> 
                                            </Grid>
                                              <Grid item xs={12} md={6} className={`${!disable ? 'd-none' : ''}`}>
                                                <span data-up-error="" className={`${!disable ? 'upliftErrorHide' : ''} error displayFlex`}>
                                                    Not Available
                                                    <span data-up-tooltip="">
                                                        {upliftErrorMessage.hasOwnProperty(reasonCode)
                                                            ? upliftErrorMessage[reasonCode as keyof typeof upliftErrorMessage]
                                                            : ''}
                                                    </span>
                                                </span>
                                            </Grid>  
                                            <Grid item>
                                                <div>
                                                    Powered by
                                                    <img
                                                        alt="uplift logo"
                                                        className="uplift-logo-2"
                                                        src="https://s3-us-west-2.amazonaws.com/travel-img-assets/logos/uplift.svg"
                                                    />
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )}
                            </Grid>

                        </RadioGroup>
                    </FormControl> */}
                </Grid>
            </Grid>
            <div id="up-pay-monthly-container" style={optStyle} ></div>
        </>
    );
}

export default PaymentWithUplift
