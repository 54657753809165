import { createAction } from "@reduxjs/toolkit";
import { ACTIONS } from "../constants/actions";
import { Dispatch } from "redux";

const giftCardData = createAction<any[]>(`giftCardResult/${ACTIONS.GIFTCARD_RESULT}`);

export const updateGiftCardData: any = (resultData) => {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(giftCardData(resultData));
    } catch (error) {
      // Handle errors
      console.error("Error fetching giftCard booking results:", error);
    }
  };
};