import { AxiosRequestConfig, AxiosResponse } from "axios";
import { profileService } from "../axiosServices";

const getSignInAPI = async (postBody: object): Promise<AxiosResponse<any>> => {
    const config = {
        method: 'post',
        maxBodyLength: Infinity,
        headers: { 
          'Content-Type': 'application/json'
        },
      };
    return await profileService.post(`/auth/users/internal/signIn`,postBody, config);
}

export { getSignInAPI };