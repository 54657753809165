import React, { useState } from 'react';
import {getStatusInfo} from "../services";
const GetStatusAPI = () => {
    const [statusData, setStatusData] = useState(null);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const statusAPI = async() => {
        try {
            setLoading(true);
            setError(false);
            const res: any = await getStatusInfo();
            setStatusData(res)
        } catch (error: any) {
            setError(error);
        } finally {
            setLoading(false);
        }
    }
    return [statusAPI, statusData, loading, error];
};

export {GetStatusAPI};