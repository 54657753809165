import React, { useState } from 'react';
import {getBrandedFaresService} from "../services";

const BrandedFareAPI = () => {
    const [brandedFareData, setBrandedFareData] = useState(null);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const brandedFareAPI = async(fareId:string,token:string,correlationId: string) => {
        console.log("this is called")
        try {
            setLoading(true);
            setError(null);
            const res = await getBrandedFaresService({fareId,token,correlationId});
            // let result=res?.data
            setBrandedFareData(res)
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    }
    return [brandedFareAPI, brandedFareData, loading, error];
};

export {BrandedFareAPI};