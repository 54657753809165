import React, { useState } from 'react';
import { getFrequentFlyerService } from "../services/getFrequentFlyerService";
const GetFrequentFlyerAPI = () => {
    const [frequentFlyerData, setFrequentFlyerData] = useState(null);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const getFrequentFlyerApi =  async (payload:any,correlationId:string) => {
        try {
            setLoading(true);
            setError(null);
            const res = await getFrequentFlyerService({payload,correlationId});
            setFrequentFlyerData(res)
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    }
    return [getFrequentFlyerApi, frequentFlyerData, loading, error];
};

export { GetFrequentFlyerAPI };