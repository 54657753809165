import React from "react";
import { Box, Typography, Paper, Stack } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import PhoneIcon from "@mui/icons-material/Phone";
import { useTranslation } from "react-i18next";


const WhyBookWithUs = () => {

  const {t} = useTranslation();
  const items = [
    {
      icon: <LockIcon className="wbwu-icon" />,
      title: "Secure Shopping",
      description: t("Our checkout")
    },
    {
      icon: <VisibilityOffIcon className="wbwu-icon" />,
      title: "Privacy Protection",
      description: t("Your privacy")
    },
    {
      icon: <PhoneIcon className="wbwu-icon" />,
      title: "Exceptional Customer Service",
      description: t("Have questions")
    },
  ];

  return (
    <Paper className="wbwu-wrapper" elevation={3} sx={{ p: 2, borderRadius: 2 }}>
      <Typography className="wbwu-title" variant="h6" gutterBottom>
        <span>
          {t("Why book with")}
        </span>
      </Typography>
      <Stack spacing={2}>
        {items.map((item, index) => (
          <Box key={index} display="flex" alignItems="flex-start" gap={2}>
            {item.icon}
            <Box>
              <Typography className="wbwu-subtilte" variant="h4" >
                {t(item.title)}
              </Typography>
              <Typography className="wbwu-desc" variant="h3">{item.description}</Typography>
            </Box>
          </Box>
        ))}
      </Stack>
    </Paper>
  );
};

export default WhyBookWithUs;
