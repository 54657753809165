import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ACTIONS } from "../constants/actions";

export interface BookingResultState {
    booking: {};
}

const initialState: BookingResultState = {
    booking: {}
}

const BookingResult = createSlice({
    name: "bookingResult",
    initialState,
    reducers: {
        [`${ACTIONS.BOOKING_RESULT}`]: (state, action: PayloadAction<any[]>) => {
            return {...state, booking: action.payload}
        }
    }
});

export const { BOOKING_RESULT } = BookingResult.actions;
export default BookingResult.reducer;
