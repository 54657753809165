import { AxiosRequestConfig } from "axios";
import { relayService } from "../axiosServices";

interface RequestBodyInterface{
    payload:String;
    correlationId:String;
}

const getGiftcardService = async (postBody: RequestBodyInterface, config: AxiosRequestConfig = {}) => {
    const { payload, correlationId }=postBody
    const headers = {
        "Bookingchannel": "external", // Example header, adjust as needed
        "Correlationid": `${correlationId}`,
        "Flighttype":"CAD"
        // Add more headers as needed
    };

    // Merge provided config with headers
    const requestConfig: AxiosRequestConfig = {
        ...config,
        headers: {
            ...config?.headers,
            ...headers
        }
    };
    let data:any=await relayService.post(`?forwardUrl=${process.env.GIFTCARD_API}/giftcard/check-balance/`,payload, requestConfig);
    return data.data;
}

const preAuthGiftcardService = async (postBody: RequestBodyInterface, config: AxiosRequestConfig = {}) => {
    const { payload, correlationId }=postBody
    const headers = {
        "Bookingchannel": "external", // Example header, adjust as needed
       "Correlationid": `${correlationId}`,
        "Flighttype":"CAD"
        // Add more headers as needed
    };

    // Merge provided config with headers
    const requestConfig: AxiosRequestConfig = {
        ...config,
        headers: {
            ...config?.headers,
            ...headers
        }
    };
    let data:any=await relayService.post(`?forwardUrl=${process.env.GIFTCARD_API}/giftcard/preauth/`,payload, requestConfig);
    return data.data;
}

const postAuthGiftcardService = async (postBody: RequestBodyInterface, config: AxiosRequestConfig = {}) => {
    const { payload,correlationId }=postBody
    const headers = {
        "Bookingchannel": "external", // Example header, adjust as needed
        "Correlationid": `${correlationId}`,
        "Flighttype":"CAD"
        // Add more headers as needed
    };

    // Merge provided config with headers
    const requestConfig: AxiosRequestConfig = {
        ...config,
        headers: {
            ...config?.headers,
            ...headers
        }
    };
    let data:any=await relayService.post(`?forwardUrl=${process.env.GIFTCARD_API}/giftcard/capture/`,payload, requestConfig);
    return data.data;
}

const cancelAuthGiftcardService = async (postBody: RequestBodyInterface, config: AxiosRequestConfig = {}) => {
    const { payload,correlationId }=postBody
    const headers = {
        "Bookingchannel": "external", // Example header, adjust as needed
        "Correlationid": `${correlationId}`,
        "Flighttype":"CAD"
        // Add more headers as needed
    };

    // Merge provided config with headers
    const requestConfig: AxiosRequestConfig = {
        ...config,
        headers: {
            ...config?.headers,
            ...headers
        }
    };
    let data:any=await relayService.post(`?forwardUrl=${process.env.GIFTCARD_API}/giftcard/cancel-preauth/`,payload, requestConfig);
    return data.data;
}

export { getGiftcardService,preAuthGiftcardService,postAuthGiftcardService,cancelAuthGiftcardService};