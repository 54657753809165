import { AxiosRequestConfig, AxiosResponse } from "axios";
import {  staticService } from "../axiosServices";

interface RequestBodyInterface {
    payload:any;
    correlationId:String;
}

const getFrequentFlyerService = async (postBody: RequestBodyInterface, config: AxiosRequestConfig = {}) => {
    const { payload, correlationId }= postBody

    const headers = {
        "Bookingchannel": "external", // Example header, adjust as needed
        "Correlationid": `${correlationId}`, //" f149d7a0-76e0-4e6b-a629-34944c2a6374",
        "Flighttype": "CAD"
        // Add more headers as needed
    };

    // Merge provided config with headers
    const requestConfig: AxiosRequestConfig = {
        ...config,
        headers: {
            ...config?.headers,
            ...headers
        }
    };

    let data: any = await staticService.post(`/frequentFlyer/agreementCarriers`, payload, requestConfig);

    return data.data;
}

export { getFrequentFlyerService };