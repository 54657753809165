import axios from "axios";
import { requestFailCallback, requestSuccessCallback, responseFailCallback, responseSuccessCallback } from "../util";



const baseService = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    timeout: 59000,
});
baseService.defaults.headers.common["Content-Type"] = "application/json";
baseService.interceptors.request.use(requestSuccessCallback,requestFailCallback)
baseService.interceptors.response.use(responseSuccessCallback,responseFailCallback)

export default baseService;