export const englishMessages = {
    // Search Page
    "Booking": "Booking",
    "Confirmation": "Confirmation",
    "Round Trip": "Round Trip",
    "One Way": "One Way",
    "Multi City": "Multi City",
    "Economy Class": "Economy Class",
    "Premium Economy": "Premium Economy",
    "Business Class": "Business Class",
    "First Class": "First Class",
    "Departure dates": "Departure dates",
    "Adult": "Adult",
    "Adults": "Adults",
    "Children": "Children",
    "Search": "Search",
    "Filter": "Filter",
    "Clear All": "Clear All",
    "From": "From",
    "to": "to",
    "Airlines": "Airlines",
    "Email address": "Email address",
    "Operated by": "Operated by",
    "or from": "or from",
    "Flight Details": "Flight Details",
    "Add Flight": "Add Flight",
    "fare": "fare",
    "Layover": "Layover",
    "Price Range": "Price Range",
    "Select Flight": "Select Flight",
    "Departing From": "Departing From",
    "Going To": "Going To",
    "No options": "No options",
    "Best Departing ": "Best Departing Flights",
    "Best": "Best",
    "Departing": "Departing",
    "Flights": " Flights",
    "Ranked based on price and convenience": "Ranked based on price and convenience",
    "Recommended": "Recommended",
    "Stop": "Stop",
    "stop": "stop",
    "Only": "Only",
    "Stay updated with the latest travel deals": "Stay updated with the latest travel deals",
    "Get Updates": "Get Updates",
    "SEGMENT": "SEGMENT",
    "Enter date": "Enter date",
    "Remove Last Flight": "Remove Last Flight",
    "in": "in",
    "NonStop": "NonStop",
    "minimumValue": "minimumValue",
    "maximumValue": "maximumValue",
    "count": "count",
    "Baggage Allowance": "Baggage Allowance",
    "Clear": "Clear",
    "Timings": "Timings",
    "Take-off from": "Take-off from",
    "Travel Time": "Travel Time",
    "Flight Duration": "Flight Duration",
    "Layover Airports": "Layover Airports",
    "Airports": "Airports",
    "Help us improve your booking experience": "Help us improve your booking experience",
    "Give feedback": "Give feedback",

    // Branded fares
    "Cabin": "Cabin",
    "Bags": "Bags",
    "PremiumEconomy": "PremiumEconomy",
    "BusinessClass": "BusinessClass",
    "FirstClass": "FirstClass",
    "Select your fare": "Select your fare",
    "Flexibility": "Flexibility",
    "Select": "Select",
    "Seat": "Seat",
    "Priority": "Priorité",

    // Payment Confirmation
    "Title": "Title",
    "None": "None",
    "MR": "MR",
    "MS": "MS",
    "MRS": "MRS",
    "First Name": "First Name",
    "Middle Name": "Middle Name",
    "Last Name": "Last Name",
    "Date Of Birth": "Date Of Birth",
    "Day": "Day",
    "Month": "Month",
    "Year": "Year",
    "Gender": "Gender",
    "Male": "Male",
    "Female": "Female",
    "Email": "Email",
    "Enter phone number": "Enter phone number",
    "Frequent Flyer Program": "Frequent Flyer Program",
    "Expiry Date": "Expiry Date",
    "CVV Code": "CVV Code",
    "City": "City",
    "Flight": "Flight",
    "Price Summary": "Price Summary",
    "Taxes and fees": "Taxes and fees",
    "Passenger": "Passenger",
    "selected": "selected",
    "Total": "Total",
    "Traveller Details": "Traveller Details",
    "Traveller": "Traveller",
    "Fare type": "Fare type",
    "of": "of",

    // Booking Confirmation
    "Departure Date": "Departure Date",
    "Departure": "Departure",
    "Return": "Return",
    "Not Available": "Not Available",
    "Please scroll up to review":
        "Please scroll up to review the error messages and update the data accordingly.",
    "Please select the above checkbox to proceed.": "Please select the above checkbox to proceed.",
    "You are about to book an unaccompanied minor (UMNR).":
        "You are about to book an unaccompanied minor (UMNR). You must contact the carrier to check if your passenger will be accepted on board and if there are any mandatory procedures that must be followed.",
    "I agree to contact the airline.":
        "I agree to contact the airline. Continue and book.",
    "Remove": "Remove",

    "Traveller Information": "Traveller Information",
    "Program Number": "Program Number",
    "Protect Your Travel Investment with our Insurance Plans": "Protect Your Travel Investment with our Insurance Plans",
    "Coverage valid for Canadian residents only": "Coverage valid for Canadian residents only.",
    "Customize Your Booking": "Customize Your Booking",
    "Choose seats": "Choose seats",
    "Show Seatmap": "Show Seatmap",
    "Select Payment option": "Select Payment option",
    "Pay the full amount": "Pay the full amount",
    "Pay Monthly": "Pay Monthly",
    "Payment Details": "Payment Details",
    "We Use Secure Transmission & Protect Your Personal Information": "We Use Secure Transmission & Protect Your Personal Information",
    "We accept all major credit cards": "We accept all major credit cards",
    "Name On Card": "Name On Card",
    "Credit Card Number": "Credit Card Number",
    "Billing Address": "Billing Address",
    "Street Name And House No": "Street Name And House No.",
    "Country": "Country",
    "State": "State",
    "Province": "Province",
    "Postal Code": "Postal Code",
    "Complete Purchase": "Complete Purchase",
    "Full Flight Details": "Full Flight Details",
    "Flight Price": "Flight Price",
    "Base price": "Base price",
    "Total price": "Total price",
    "Full price breakdown": "Full price breakdown",
    "Confirmation will be sent here": "Confirmation will be sent here",
    "Seat selected": "Seat selected",
    "Seatmap Key": "Seatmap Key",
    "middle": "middle",
    "Add Seat": "Add Seat",
    "Skip": "Skip",
    "Halte": "Halte",
    "Filter & Sort by": "Filter & Sort by",
    "Thank you for booking with": "Thank you for booking with",
    "Your flight booking is currently": "Your flight booking is currently.",
    "You have declined travel insurance.": "You have declined travel insurance.",
    "Important Information": "Important Information",
    "Please do not attempt to rebook as it may result in duplicating your booking error and payment. Prices and availability are subject to change and are not guaranteed until payment has been processed.":
        "Please do not attempt to rebook as it may result in duplicating your booking error and payment. Prices and availability are subject to change and are not guaranteed until payment has been processed.",
    "Please ensure that all your booking details are correct and verify that all passenger names are exactly as they appear on your valid passport(s).":
        "Please ensure that all your booking details are correct and verify that all passenger names are exactly as they appear on your valid passport(s).",
    "Rates and availability are subject to change and are not guaranteed until payment has been processed. Call us at 1.866.573.3824 if you have any questions.":
        "Rates and availability are subject to change and are not guaranteed until payment has been processed. Call us at 1.866.573.3824 if you have any questions.",
    "You are responsible for reconfirming your flight times with Air Canada at least 24 hours prior to your flight departure via their website, by calling them or check with your local airport authority. You must check in at least 3 hours prior to your flight departure.":
        "You are responsible for reconfirming your flight times with Air Canada at least 24 hours prior to your flight departure via their website, by calling them or check with your local airport authority. You must check in at least 3 hours prior to your flight departure.",
    "Terms Of Booking": "Terms Of Booking",
    "Your itinerary consists of two one-way fares which are subjects to their own rules and restrictions. If one of your flights is changed or canceled, it will not automatically change the other flight. You may incur a penalty free for each flight for additional itinerary changes.":
        "Your itinerary consists of two one-way fares which are subjects to their own rules and restrictions. If one of your flights is changed or canceled, it will not automatically change the other flight. You may incur a penalty free for each flight for additional itinerary changes.",
    "Taxes and fees are included in the total ticket costs. Prices may not include (baggage fee) or other fees charged directly by the airline. Fares are not guaranteed until ticketed. Service fees are non-refundable. Cancellations, routing and date changes are subject to (fare rules) and our (fees), should these changes be allowed by the airline.":
        "Taxes and fees are included in the total ticket costs. Prices may not include (baggage fee) or other fees charged directly by the airline. Fares are not guaranteed until ticketed. Service fees are non-refundable. Cancellations, routing and date changes are subject to (fare rules) and our (fees), should these changes be allowed by the airline.",
    "Travel Insurance Details": "Travel Insurance Details",
    "Manulife Policy": "Manulife Policy",
    "DECLINED": "DECLINED",
    "You Have DECLINED Travel Insurance. Travel insurance is designed to offer you a worry free travelling experience. Emergencies can occur anywhere. Travel insurance protects you against unforseen circumstances.":
        "You Have DECLINED Travel Insurance. Travel insurance is designed to offer you a worry free travelling experience. Emergencies can occur anywhere. Travel insurance protects you against unforseen circumstances.",
    "Billing Details": "Billing Details",
    "Supplier": "Supplier",
    "Booking Code": "Booking Code",
    "Paid - Not Ticketed": "Paid - Not Ticketed",
    "Full Name": "Full Name",
    "Credit Card Holder": "Credit Card Holder",
    "Card Type": "Card Type",
    "Payment Total": "Payment Total",
    "Payment Method": "Payment Method",
    "Insurance": "Insurance",
    "You have applied too many filters": "You have applied too many filters.",
    "Click": "Click",
    "here": "here",
    "to View all results": "to View all results.",
    "Interest-free payments also available":
        "Interest-free payments also available",
    "Pay monthly with": "Pay monthly with",
    "Something went wrong": "Something went wrong", // Remain to map with t
    "Child's age upon return": "Child's age upon return",
    "Multiple flights": "Multiple flights",
    "For Coverage amounts click": "For Coverage amounts click",
    "Show Less": "Show Less",
    "Show More": "Show More",
    "per day": "per day",
    "Starting from": "Starting from",
    "per person": "per person",
    "Enter a valid mobile number": "Enter a valid mobile number",
    "Your Booking": "Your Booking",
    "is processing please wait": "is processing please wait",
    "Woops": "Woops",
    "Someting went wrong": "Someting went wrong",
    "There was a problem processing the request": "There was a problem processing the request",
    "Go to Home": "Go to Home",
    "Oops! Page not found": "Oops! Page not found",
    "The page you are looking for was not found": "The page you are looking for was not found.",
    "Select a flight": "Select a flight",
    "Passengers with same name are not allowed": "Passengers with same name are not allowed",
    "I read and agree to redtag.ca's": "I read and agree to redtag.ca's",
    "terms & conditions": "terms & conditions",
    "and the supplier’s": "and the supplier’s",
    "Add More": "Add More",
    "As per your Passport": "As per your Passport",
    "Mobile Number": "Mobile Number",
    "Facilities": "Facilities",
    "with": "with",
    "Change Flight": "Change Flight",
    "Address":"Address",
    "Postal":"Postal",
    "Infant": "Infant",
    "Child": "Child",
}