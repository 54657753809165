import axios from "axios";
import { requestFailCallback, requestSuccessCallback, responseFailCallback, responseSuccessCallback } from "../util";

const fraudService = axios.create({
    baseURL: process.env.REACT_APP_FRAUD_CHECK_URL,
    timeout: 59000,
});
fraudService.defaults.headers.common["Content-Type"] = "application/json";
fraudService.interceptors.request.use(requestSuccessCallback,requestFailCallback)
fraudService.interceptors.response.use(responseSuccessCallback,responseFailCallback)

export default fraudService;