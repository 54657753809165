import axios from "axios";

const relayService = axios.create({
    baseURL: process.env.RELAY_SERVICE_URL,
    timeout: Number(process.env.API_TIMEOUT),
});
relayService.defaults.headers.common["Content-Type"] = "application/json";
relayService.defaults.headers.common["Accept"] = "*";
relayService.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

export default relayService;