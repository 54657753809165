import { createAction } from "@reduxjs/toolkit";
import { ACTIONS } from "../constants/actions";
import { Dispatch } from "redux";

const bookingData = createAction<any[]>(`bookingResult/${ACTIONS.BOOKING_RESULT}`);

export const updateBookingData: any = (resultData) => {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(bookingData(resultData));
    } catch (error) {
      // Handle errors
      console.error("Error fetching search results:", error);
    }
  };
};