import React from 'react';
import { Box, Typography, Radio, Button, List, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import { DEFAULT_FUNCTION } from '../../utils/constant';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import DollarIcon from '@mui/icons-material/MonetizationOn';
import { useTranslation } from 'react-i18next';

interface Detail {
    title: string;
    icon: JSX.Element;  
}

interface Feature {
    application: keyof typeof applicationStatus;
    commercialName?: string;
}

interface BrandedFeature {
    subCode: string;
    commercialName: string;
}

interface Props {
    brandData: any;
    selectedOption: any
    handleRadioChange: any
    radioBtnCount: any
    recommended: any
    selectedCardTotal: any,
    pricingCall:(id:null|Object)=> void
}

const applicationStatus = {
    DisplayedButNotOffered: "Not offered",
    NotOffered: "Not offered",
    ServiceIsFree: "Free",
    Chargeable: "Chargeable"
};

const FareTypeCrads: React.FC<Props> = ({
    brandData,
    selectedOption,
    handleRadioChange,
    radioBtnCount,
    recommended,
    selectedCardTotal,
    pricingCall
}) => {
    const { t } = useTranslation();
    const {data: {brandFeatures}} = brandData;
    const getProcessesBrandedFare = (brandedFeatures: BrandedFeature[]) => {
        const subCodeMappings: Record<string, BrandedFeature> = {};
        const commercialNameMappings: Record<string, BrandedFeature> = {};
        brandedFeatures.forEach((feature) => {
            if (!subCodeMappings[feature.subCode]) {
                subCodeMappings[feature.subCode] = feature;
            }
            if (!commercialNameMappings[feature.commercialName]) {
                commercialNameMappings[feature.commercialName] = feature;
            }
        });
    
        return { commercialNameMappings, subCodeMappings };
    };
    
    const getSymbol = (feature: Feature) => {
        if (feature.application) {
            switch (feature.application) {
                
                case 'DisplayedButNotOffered':
                case 'NotOffered':
                        return (<CloseIcon className='uncheckIcon'/>)
                        
                case "ServiceIsFree":
                    return (<CheckIcon className='checkIcon'/>)
                case "Chargeable":
                    return (<DollarIcon className='dollarIcon'/>)
                            
                default:
                    return null;
            }
        }
        return null;
    };
    
    const RenderFeature = ({ feature, name = "" }: any) => {
        if (feature) {
            return (
                <List className='fareType_IconWithTitle'>

                    <ListItem>
                        <ListItemAvatar>
                            {getSymbol(feature)}
                        </ListItemAvatar>
                        <ListItemText primary={name || feature.commercialName} />
                    </ListItem>

                </List>
            );
        }
        return null;
    };

    const handleSelect = async () => {
        let selectedId = brandData?.id
        let obj={
            "fareId":selectedId
        }
        let res = await pricingCall(obj)
    }

    
    const {commercialNameMappings, subCodeMappings} = getProcessesBrandedFare(brandFeatures)

      const groupBySectionTitle = (features:any) => {
        return features.reduce((acc:any, feature:any) => {
          const { sectionTitle } = feature;
          if (!sectionTitle) return acc;

          if (!acc[sectionTitle]) {
            acc[sectionTitle] = [];
          }
          acc[sectionTitle].push(feature);
          return acc;
        }, {});
      };
      const groupedFeatures = groupBySectionTitle(brandFeatures);

    let oneCabinBag = commercialNameMappings["1 CABIN BAG UP TO 8KG"];
    let twoCabinBag = commercialNameMappings["2 CABIN BAGS UP TO 8KG"];
    let subPrice:any=Number(brandData.total-selectedCardTotal).toFixed(2)
    let chargedBaggages = brandData?.chargedBaggage
    let freeBaggageData = brandData?.freeBaggage?.[0]?.quantity
    let freeBaggageDataWeight = brandData?.freeBaggage?.[0]?.weight;
    return (
        <>
            <Box className={"fareTypeCrads " +
                ("fareTypeActive ") + (recommended && " fareTypeRecommended")
            }>
                {recommended ? <Typography className='fareType_Recommended'>{t('Recommended')}</Typography> : null}
                <Box className="fareType_Top">
                    <Box className="fareTypePrice">
                        <Typography className='inOutTop_mainTitle'>$ {brandData.total}</Typography>
                        <Typography className='fareType_title' component={'span'}>{brandData?.brandName}</Typography>
                        <Typography className='fareType_cabin' component={'span'}> {brandData?.cabin}</Typography>
                    </Box>
                    <Box>
                        {/* <Radio
                            checked={selectedOption === radioBtnCount}
                            onChange={() => handleRadioChange(radioBtnCount)}
                            value={radioBtnCount}
                            name="radio-buttons"
                        /> */}
                    </Box>
                </Box>
                <Box className="fareType_Content">
                    <Box className="fareType_top">
                        
                        
                    </Box>
                    {
                        (oneCabinBag || twoCabinBag || brandData?.freeBaggage?.length || chargedBaggages?.length) ?
                            <>
                                <Typography className='fareType_subTitle'>{t('Bags')}</Typography>
                                {(freeBaggageData !== undefined || freeBaggageDataWeight !== undefined) &&
                                    <>
                                        <List className='fareType_IconWithTitle'>
                                            <ListItem>
                                                <ListItemAvatar>
                                                    <CheckIcon className='checkIcon' />
                                                </ListItemAvatar>
                                                <ListItemText primary={"CHECKED BAGS INCLUDED"} />
                                                {freeBaggageData === 0 && freeBaggageDataWeight === 0 ?
                                                    <>
                                                        {freeBaggageData}

                                                    </>
                                                    :
                                                    <>
                                                        {freeBaggageData
                                                            ? freeBaggageData
                                                            : freeBaggageDataWeight + " Kg"}
                                                    </>
                                                }
                                            </ListItem>
                                        </List>
                                    </>
                                }
                                {chargedBaggages &&
                                    chargedBaggages.map(
                                        (bagData: any) => {
                                            let firstPiece = bagData && bagData.firstPiece;
                                            let lastPiece = bagData && bagData.lastPiece;
                                            let bagAmount = bagData && bagData.amount;
                                            let chargeBaggageValue;

                                            if (lastPiece) {
                                                if (firstPiece === lastPiece)
                                                    chargeBaggageValue = ("Fee for additional bag ") + lastPiece;

                                                if (firstPiece !== lastPiece)
                                                    chargeBaggageValue =("Fee for additional bag ") + firstPiece +"-" +lastPiece;

                                            }
                                            if (!lastPiece) {
                                                chargeBaggageValue = ("Fee for additional bag ") + firstPiece;
                                            }

                                            if (firstPiece || lastPiece)
                                                return (
                                                    <>
                                                        <List className='fareType_IconWithTitle'>
                                                            <ListItem>
                                                                <ListItemAvatar>
                                                                    {<DollarIcon className='dollarIcon' />}
                                                                </ListItemAvatar>
                                                                <ListItemText primary={(chargeBaggageValue.toUpperCase())} />
                                                                $ {bagAmount}
                                                            </ListItem>
                                                        </List>
                                                    </>
                                                );
                                        }
                                    )
                                }
                                {oneCabinBag && <RenderFeature feature={oneCabinBag} />}
                                {twoCabinBag && <RenderFeature feature={twoCabinBag} />}

                            </>
                            :
                            null
                    }


                    {groupedFeatures && Object.keys(groupedFeatures).map((sectionTitle: any) => (
                        <>
                            <Typography className='fareType_subTitle'>{sectionTitle}</Typography>
                            {groupedFeatures[sectionTitle].map((feature: any) => (
                                <List className='fareType_IconWithTitle' key={feature.commercialName}>
                                    <ListItem>
                                        <ListItemAvatar>
                                            {getSymbol(feature)}
                                        </ListItemAvatar>
                                        <ListItemText primary={feature.commercialName} />
                                    </ListItem>
                                </List>
                            ))}
                        </>
                    ))}
                </Box>
                <Box className="fareType_btnSection">
                    <Button className={"fareTypeBtn " + ( "fareTypeBtnActive")} onClick={handleSelect}>{t('Select')}</Button>
                </Box>
            </Box>
        </>
    );
}

export default FareTypeCrads;
