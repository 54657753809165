import React from 'react';
import { Container, Typography, Button, Box } from '@mui/material';
// import { useNavigate } from 'react-router-dom';
import ReportIcon from '@mui/icons-material/Report';
import ArrowBack from '@mui/icons-material/KeyboardBackspace';
import { useTranslation } from 'react-i18next';
const NotFound = () => {
  const { t } = useTranslation();
  // const navigate = useNavigate();

  // const handleGoBack = () => {
  //   navigate('/');
  // };

  return (

    <Box className="notFound_Wrapper">
      <Box className="notFound_Main">
        <Typography variant="h1" component="h2" gutterBottom className='notFound_title'>
          404
        </Typography>

        <Box className="notFound_rightSide">
          <Typography variant="h5" component="h3" gutterBottom className='notFound_mainTitle'>
            <ReportIcon />  {t('Oops! Page not found')}</Typography>

          <Typography variant="h5" component="h3" gutterBottom className='notFound_subTitle'>
            {t('The page you are looking for was not found')}
          </Typography>
          <Button variant="text" sx={{ marginTop: '1rem' }}> <ArrowBack/> {t('Go to Home')}   </Button>
        </Box>
      </Box>
    </Box >
  );
};

export default NotFound;
