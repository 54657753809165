import * as React from 'react';
import { Box, Button, Container, Grid, Table, TableCell, TableRow, Typography } from '@mui/material';

export const CardLabel = ({ title, subTitle }: any) => {
    return (<>
        <Box className="bookingSum__card">
            <Typography className='bookingSum_Title' component={'span'}>{title} </Typography>
            <Typography className='bookingSum_subTitle' component={'span'}>{subTitle}  </Typography>
        </Box>
    </>);
}