import React, { useEffect, useState } from 'react';
import { Drawer, Button, Box, Typography, FormControl, Select, MenuItem, FormHelperText, Grid } from '@mui/material';
import AirlineIcon from '@mui/icons-material/AirlineSeatReclineNormal';
import { SelectChangeEvent } from '@mui/material/Select';
import CloseIcon from '@mui/icons-material/Close';
import SeatMapDetails from './SeatMapDetails';
import { TravellerData } from '../BookWithPaymentComponents/PassengerDetails';
import { getFlightsFromSegments } from './helper';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

interface SeatMap {
    segments: object[];
}

export interface SegmentFlight {
    id?: string;
    aircraftType?: string;
    arrivalAirport?: string;
    arrivalAirportCity?: string;
    arrivalDateTime?: string;
    carrier?: string;
    carrierName?: string;
    departureAirport?: string;
    departureAirportCity?: string;
    departureDateTime?: string;
    fareBasisCode?: string;
    number?: string;
    operatingCarrier?: string;
    operatingCarrierName?: string;
    operatingFlightNumber?: string;
}

interface SeatMapProps {
    travellers: TravellerData[];
    segments: object[];
    itineraryFare: any;
    updateSelectedSeat: (flightId: string, letter: string, row: number, travellerId: string, isSwapping: boolean) => void;
    selectedSeats: any;
    SeatMapAPI: any;
    token: string;
}

function SeatMap({
    travellers,
    segments,
    itineraryFare,
    updateSelectedSeat,
    selectedSeats,
    SeatMapAPI,
    token
}: SeatMapProps) {
    const { t } = useTranslation();
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [selectedFlightId, setSelectedFlightId] = useState<string | null>(null);
    const correlationId = useSelector((state: any) => state.search.correlationId);
    const seatMapApi = SeatMapAPI();

    const [flights, setFlights] = useState<SegmentFlight[]>([]);

    useEffect(() => {
        const flightsData = getFlightsFromSegments(segments);
        setFlights(flightsData);

        // Automatically select the first flight if none is selected
        if (flightsData.length > 0 && !selectedFlightId) {
            setSelectedFlightId(flightsData[0].id || null);
        }
    }, [segments, selectedFlightId]);

    const toggleDrawer = (open: boolean) => (event: any) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setIsDrawerOpen(open);
    };

    const handleChange = (event: SelectChangeEvent) => {
        setSelectedFlightId(event.target.value);
    };

    const selectedFlight = flights.find(flight => flight.id === selectedFlightId);
    const selectedFlightIndex = flights.findIndex(flight => flight.id === selectedFlightId) + 1;

    const onClickSeat = (letter: string, row: number, travellerId: string, isSwapping: boolean) => {
        updateSelectedSeat(selectedFlightId!, letter, row, travellerId, isSwapping); 
    };

    useEffect(() => {
        if (!selectedFlight || !itineraryFare) { return; }
        seatMapApi.getSeatMap(itineraryFare.id, selectedFlight?.id, token, correlationId);
    }, [selectedFlight]);

    return (
        <div>
            <Button onClick={toggleDrawer(true)} className='seatMap_showbtn'>{t('Show Seatmap')}</Button>
            <Drawer anchor="right" open={isDrawerOpen} onClose={toggleDrawer(false)} >
                <Box id='seatmap-header-container' sx={{ minWidth: 450 }} style={{ backgroundColor: 'white', position: 'sticky', top: 0, zIndex: 100 }}>
                    <Box className="SeatMap_header">
                        <Box className="SeatMap_titleContent">
                            <AirlineIcon />  <Typography className='SeatMap_Title'> {t('Choose seats')}</Typography>
                        </Box>
                        <Button onClick={toggleDrawer(false)}>
                            <CloseIcon />
                        </Button>
                    </Box>
                    <Box className='SeatMap_dropdown'>
                        <FormControl fullWidth variant="standard">
                            <FormHelperText>
                                {selectedFlightId ? `${t('Flight')} ${selectedFlightIndex} ${t('of')} ${flights.length}` : `${('Select a flight')}`}
                            </FormHelperText>
                            <Select
                                value={selectedFlightId || ''}
                                onChange={handleChange}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                            >
                                {flights.map((flight) => (
                                    <MenuItem key={flight.id} value={flight.id}>
                                        {flight.departureAirportCity} ({flight.departureAirport}) {t('to')} {flight.arrivalAirportCity} ({flight.arrivalAirport})
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                </Box>
                <Grid container direction="column" className="SeatMap_Wrapper">
                    <SeatMapDetails
                        travellers={travellers}
                        onClickSeat={onClickSeat} 
                        selectedSeats={(selectedSeats[selectedFlight?.id] ?? {}).selectedSeats ?? []}
                        seatMap={seatMapApi}
                        selectedFlightIndex={selectedFlightIndex}
                        totalFlights={flights.length}
                    />
                </Grid>
            </Drawer>
        </div>
    );
}

export default SeatMap;
