import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom'; // Import useLocation
import { Routes as RouteConfig } from '../router/routes'; // Import Routes array
import SearchComponent from './search/SearchComponent';
import LanguageDropDown from './languageSelector/LanguageDropComp';
import { useSelector } from "react-redux";
import { GetBrandingData } from 'gigi-api-layer/src';
import extractDomain from 'extract-domain';

const App = () => {
  const location = useLocation(); // Get the current location
  const pathName = location.pathname;
  const language = useSelector((state: any) => state.languageChange.language);
  const [getBranding, brandingData, brandingLoader, brandingError] = GetBrandingData();
  const isHeader = document.getElementById("B2CHeader");
  const isFooter = document.getElementById("B2CFooter");

    console.log("@@@@@@@",isHeader,isFooter)
  async function addRemoveHeaderFooter(headerURL,footerURL) {
      try {
        await loadHeaderScript(headerURL);
        console.log("Header script loaded successfully");
      } catch (error) {
        console.error(error.message);
      }
      loadFooterContent(footerURL);
    
  
    async function loadHeaderScript(url) {
      try {
        const response = await fetch(url);
        if (!response.ok) throw new Error(`Failed to load header content: ${response.statusText}`);
        const headerHTML = await response.text();
        document.body.insertAdjacentHTML("beforebegin", `<div id="B2CHeader">${headerHTML}</div>`);
      } catch (error) {
        console.error(error.message);
      }
    }
  
    async function loadFooterContent(url) {
      try {
        const response = await fetch(url);
        if (!response.ok) throw new Error(`Failed to load footer content: ${response.statusText}`);
        const footerHTML = await response.text();
        document.body.insertAdjacentHTML("beforeend", `<div id="B2CFooter">${footerHTML}</div>`);
      } catch (error) {
        console.error(error.message);
      }
    }
  }

  React.useEffect(() => {
    if(pathName=="/"){
      let url = extractDomain(window.location.hostname);
      getBranding({ site: url })
    }
  }, [pathName])

  React.useEffect(() => {
    if (pathName == "/" && brandingData?.length) {
      
      let [branding] = brandingData;
      document.documentElement.style.setProperty('--main-primary-branding', branding.main_branding_color);
      document.documentElement.style.setProperty('--main-secondary-color', branding.main_branding_color_secondary);
      document.documentElement.style.setProperty('--main-primary-Color', branding.button_color);
      document.documentElement.style.setProperty('--main-primary-Button', branding.button_color);
      document.documentElement.style.setProperty('--Button-Text-Color', branding.button_text_color);
      document.documentElement.style.setProperty('--anchor-links', branding.anchor_links);
      document.documentElement.style.setProperty('--SearchBackground', branding.search_background);
      document.title = branding.site?.toUpperCase();
      let headerURL = brandingData?.headerURL ? brandingData?.headerURL : process.env.DEFAULT_HEADER;
      let footerURL = brandingData?.footerURL ? brandingData?.footerURL : process.env.DEFAULT_FOOTER;
      // if (brandingData.headerURL && brandingData.footerURL) {
      const header = document.querySelector('#B2CHeader')
      const footer = document.querySelector('#B2CFooter')
      if (!header || !footer) {
        addRemoveHeaderFooter(headerURL, footerURL);
      }
      // }
      
    }
  }, [brandingData])

  return (
    <>
      <LanguageDropDown />
      {(pathName !== "/booking" && pathName !== "/bookingConfirmation") && <SearchComponent />}
      <Routes>
        {RouteConfig.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            element={<route.component />}
          />
        ))}
      </Routes>
    </>
  );
};

const AppWrapper = () => (
  <Router>
    <App />
  </Router>
);

export default AppWrapper;
