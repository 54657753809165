import React from 'react';
import { Paper, Typography, Grid, Box, Skeleton, Divider, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const InsuranceLoader = () => {
    return (
        <Box className='Booking_FlightDetails'>
            <Box className='Booking_flightHeader'>
                <Skeleton variant="circular" width={40} height={40} />
                <Skeleton variant="text" sx={{ ml: 2 }} width={200} height={30} />
            </Box>
            <Box>
                {[0, 1].map((_, index) => (
                    <Box key={index} padding={2}>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <Skeleton variant="text" width="80%" />
                                <Skeleton variant="text" width="60%" />
                                <Skeleton variant="text" width="70%" />
                            </Grid>
                            <Grid item xs={4} textAlign="center">
                                <Skeleton variant="text" width="80%" />
                                <Skeleton variant="text" width="50%" />
                            </Grid>
                            <Grid item xs={4} textAlign="right">
                                <Skeleton variant="text" width="80%" />
                                <Skeleton variant="text" width="60%" />
                                <Skeleton variant="text" width="70%" />
                            </Grid>
                        </Grid>
                        <Divider variant="middle" sx={{pt: 1, mb: 1}} />
                        <Grid container sx={{marginBottom: '5px'}} spacing={2} alignItems="center" justifyContent="space-between">
                            <Grid item xs={8}>
                                <Box display="flex" alignItems="center">
                                    <Skeleton variant="circular" width={20} height={20} />
                                    <Skeleton variant="text" sx={{ ml: 1 }} width={50} height={30} />
                                </Box>
                            </Grid>
                            <Grid item xs={4}>
                                <Skeleton variant="text" width="80%" />
                            </Grid>
                        </Grid>
                    </Box>
                ))}
            </Box>
        </Box>
    );
};

export default InsuranceLoader;
