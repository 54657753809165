import * as React from 'react';
import { Box, Button, Container, Grid, Table, TableCell, TableRow, Typography } from '@mui/material';
import { BookingFrom } from '../../../utils/constant';

interface SeatDetailsInterface {
    segmentData: Segment[];
    paxGroupedData: any;
}

interface FlightData {
    flights: any;  // Define the proper type for flights
}

interface Segment {
    [key: string]: FlightData;
}

function capitalizeTitle(text) {
    return text
      .toLowerCase()
      .replace(/\b\w/g, char => char.toUpperCase());
  }

export default function SeatSelectionDetails(props: SeatDetailsInterface) {
    // let quoteBookingData = props?.quoteBookingData || {};
    let groupedpax = props?.paxGroupedData || {};
    let segmentData = props?.segmentData || [];

     // Flatten the segmentdata to map flights by flight number
  const flightSegments = segmentData.flatMap((segment:any) =>
    Object.values(segment).flatMap((segmentDetails:any) =>
      segmentDetails?.flights.map((flight:any) => ({
        key: `${flight.departureAirport}-${flight.arrivalAirport}`,
        origin: flight.departureAirport,
        destination: flight.arrivalAirport,
        duration: segmentDetails.durationMinutes,
        flightNumber: flight.number,
        carrier: flight.operatingCarrier,
      }))
    )
  );
  
    return (
    <Box className="conftion__wrapper">
        <Box className="conftion__header">
            <Typography className='conftionTitle'>✈ {BookingFrom.SEATSELECTIONDETAILS}</Typography>
        </Box>
        <hr className='conftion_hr' />
      {flightSegments.map((segment:any) => {
        const passengers = groupedpax[segment.flightNumber] || [];
        return passengers && passengers.length > 0 && (
            <Box className="conftion_card" key={segment.key + segment.flightNumber}>
            {/* Header */}
            <Box className="conftion_cardHeader">
                <Typography className='conftion_cardTitle'>
                    ✈ {segment.origin}-{segment.destination} |{' '}
                    <b>{segment.carrier + segment.flightNumber}</b>
                </Typography>
            </Box>
            {/* Passenger Seat Details */}
            <Box className="conftion_cardBody">
            {passengers.map(pax => (
                <Box key={pax.paxId}>
                    <Grid container spacing={2} alignItems="center">
                        {/* Passenger Id */}
                        <Grid item xs={1}>
                            <Typography className="confition_bodyTitle" component={'span'}>
                                {pax.paxId}.
                            </Typography>
                        </Grid>
                        {/* Passenger Name */}
                        <Grid item xs={5}>
                            <Typography className="confition_bodyTitle" component={'span'}>
                                {capitalizeTitle(pax.paxFirstName +' '+pax.paxMiddleName+' '+ pax.paxLastName)}
                            </Typography>
                        </Grid>

                        {/* Seat Number */}
                        <Grid item xs={2}>
                            <Typography className="confition_bodyTitle" component={'span'}>
                                🪑 <b>{pax.seatNumber}</b>
                            </Typography>
                        </Grid>

                        {/* Seat Total Amount */}
                        <Grid item xs={2}>
                            <Typography className="confition_bodyTitle" component={'span'}>
                                $<b>{pax.seatTotalAmount}</b>
                            </Typography>
                        </Grid>

                        {/* Seat Status */}
                        <Grid item xs={2}>
                            <Typography className="confition_bodyTitle" component={'span'}>
                                {pax.seatStatus === "Confirmed" ? (
                                    <span style={{ color: 'green' }}>✅</span>
                                ) : (
                                    <span style={{ color: 'orange' }}>🟡</span>
                                )}
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            ))}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
}