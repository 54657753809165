import React from 'react'
import { Typography, Box } from '@mui/material';


const FilterTitle = ({ Title }: any) => {
    return (
        <>
            <Box className='searchRes_FliNameBox'>
                <Typography className='searchRes_FlightName'>{Title}</Typography>
            </Box>
        </>
    )
}

export default FilterTitle;