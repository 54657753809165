import React, { useState, useEffect, useRef } from 'react';
import { Typography, TextField, Box, Button, Link, Grid } from '@mui/material';
// @ts-ignore 
import  Redeem  from '../../assets/icons/Icon-amenities-gift.svg';
import "../../styles/pages/_Giftcard.scss";
import { PROMOCODESTR } from '../../utils/constant';
import { useTranslation } from 'react-i18next';
interface CouponCodeInterface {
    correlationId: any;
    promoconfig: any;
    lastTravelDate: string;
    allSegmentCarrier: any;
    changePromoCode: (couponstr: string) => void;
    promoCodeData: any;
    updatePromoPrice: (promoPrice: number) => void;

}
const CouponCode: React.FC<CouponCodeInterface> = ({ correlationId, promoconfig, lastTravelDate, allSegmentCarrier, promoCodeData, ...props }) => {
    const { t } = useTranslation();
    const [couponCode, setCouponCode] = useState('');
    const [showRemoveButton, setShowRemoveButton] = useState(false);
    const [showAlertMessage, setShowAlertMessage] = useState('');
    const [showSuccessMessage, setShowSuccessMessage] = useState('');
    const [disableButton, setDisableButton] = useState(false);
    const [couponValue, setCouponValue] = useState(0);
    const [showClickHere, setShowClickHere] = useState(false);
    
    useEffect(() => {
        props.updatePromoPrice(couponValue);
    }, [couponValue]);

    const checkValidCoupon = (coupon: string): boolean => {
        let isExistCouponCode = false;
        let couponDiscountValue = 0;
        
        if (promoCodeData[coupon]) {
            const currentDate = new Date();
            const currentDateFormatted = currentDate.toISOString().split('T')[0]; // Format to YYYY-MM-DD

            const { rules, discount } = promoCodeData[coupon];
            const couponStartDate = rules.startday;
            const couponEndDate = rules.enddate;
            setShowClickHere(false)
            //check if coupon is available for airline
            let airlineVerified = false;
            if(promoconfig.APPLY_COUPON_BY_AIRLINES[0] == 'all'){
                airlineVerified = true;
            }else{
                const uniqueAirlines = allSegmentCarrier.filter((airline:any, index: number, self: any) => self.indexOf(airline) === index);
                if (promoconfig.APPLY_COUPON_BY_AIRLINES.includes(uniqueAirlines[0])) {
                    airlineVerified = true;
                }
            }
            
            // check if coupon traveldate is expired
            let travelDateExpired = false;
            if(rules.travelEndDate && rules.travelEndDate < lastTravelDate){
                travelDateExpired = true;
            }

            // Check if the current date falls within the coupon's valid period
            if (currentDateFormatted >= couponStartDate && currentDateFormatted < couponEndDate && !travelDateExpired && airlineVerified) {
                isExistCouponCode = true;
                couponDiscountValue = discount;
                setShowSuccessMessage(PROMOCODESTR.PROMOAPPLIED);
            }else{
                isExistCouponCode = false;
                let errorMsg = t(PROMOCODESTR.ITSEEMSPROMOERROR) + promoconfig.OTAPHONETOLL + t(PROMOCODESTR.FORASSISTANCE);
                setShowAlertMessage(errorMsg);
            }
        }else{
            setShowAlertMessage(PROMOCODESTR.PROMOINVALIDMSG);
            setShowClickHere(true)
        }

        setCouponValue(couponDiscountValue);
        return isExistCouponCode;
    };

    useEffect(() => {
        if(promoCodeData){
            setDisableButton(false);

             // Validate the coupon here
             const isValidCoupon = checkValidCoupon(couponCode);

             if (isValidCoupon) {
                setShowRemoveButton(true);
             } else {
                setShowRemoveButton(false);
             }
        }
    }, [promoCodeData]);

    const handleInputChange = (value: string) => {
        setCouponCode(value);
    };

    const removePromocode = () => {
        setCouponCode('');
        setShowRemoveButton(false);
        props.changePromoCode('');
        setShowSuccessMessage(PROMOCODESTR.PREVIOUSPROMOREMOVAL);
        // add api call here
    }

    const applyPromocode = () => {
        // Logic to apply or remove the promo code
        setDisableButton(true);
        setShowAlertMessage('');
        setShowSuccessMessage('');

        if(couponCode != ''){
            props.changePromoCode(couponCode);
        }else{  
            setDisableButton(false);
            setShowAlertMessage(t(PROMOCODESTR.PLEASEENTERPROMOCODE));
        }
    };

    return (
        <>
            <Box className="Booking_TitleBox">
                <img src={Redeem} className="Booking_TitleIcon" />
                <Typography variant="h4" className="Booking_SecondaryTitle">
                    {t(PROMOCODESTR.APPLYPROMOCODE)}
                </Typography>
            </Box>

            <Box>
                <Box className="Booking_CodeInput">
               
                        <TextField
                            label={t("Enter Your Promo Code")}
                            name="couponCode"
                            variant="standard"
                            className="Booking_Label"
                            value={couponCode}
                            onChange={(e) => handleInputChange(e.target.value.toUpperCase())}
                        />
                    <Box>
                        <Button
                            variant="contained"
                            color="primary"
                            className="Booking_CodeButton"
                            disabled={disableButton}
                            onClick={showRemoveButton ? removePromocode : applyPromocode}
                            //disabled={giftCard.loading}
                        >
                            {showRemoveButton ? t('Remove') : t('Add')}
                        </Button>
                    </Box>
                </Box>

                {showAlertMessage && (
                    <Typography color="error" variant="body2">
                        {t(showAlertMessage)} {showClickHere && 
                        <Link href="https://redtag.ca/promo/terms.php" target="_blank"> {t('click here.')}</Link>
                        }
                    </Typography>
                )}
                
                {showSuccessMessage && (
                    <Typography color="primary" variant="body2">
                        ✔️{t(showSuccessMessage)}
                    </Typography>
                )}
            </Box>
        </>
    );
};

export default CouponCode;
