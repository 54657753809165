import React from 'react'
import { Button, Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

 const   FilterTopTitle = ({ mainTitle, subTitle, onClear }: any) => {
     const { t } = useTranslation();
    return (
        <>
            <Box className="searchRes_TopTitle">
                <Typography className='searchRes_filtMainTitle' variant='h4'>{mainTitle}</Typography>
                {subTitle == "Clear" ? <Button className='searchRes_flInerClrBtn' onClick={()=>onClear()}>{t('Clear')}</Button> : <Typography variant='h3' className='searchRes_filtSubTitle'>{subTitle}</Typography>}
            </Box>
        </>
    )
}

export default FilterTopTitle;