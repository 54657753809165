import React, { useEffect, useState } from 'react'
import { Grid, Box, Typography, TextField, Container, RadioGroup, Radio, FormControlLabel, Button, Checkbox, FormControl, InputLabel, NativeSelect, Select, MenuItem } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import Dollars from '@mui/icons-material/MonetizationOn';
import CloseIcon from '@mui/icons-material/Close';
import useMediaQuery from '@mui/material/useMediaQuery';
import { TRIPTYPE } from '../../utils/constant';
import moment from 'moment';
import FareTypeCrads from '../common/FareTypeCrads';
import Slider from "react-slick";
import CustomIconButton from "../common/CustomIconButton";
import ArrowRight from '@mui/icons-material/ArrowForwardIos';
import ArrowLeft from '@mui/icons-material/ArrowBackIosNew';
// @ts-ignore
import { convertCamelCase } from "../../utils/commonFunctions";
import { useTranslation } from 'react-i18next';
import 'moment/locale/fr';

interface BrandedFareProps {
    toggleDrawer: boolean;
    setToggleDrawer: (open: boolean, id: null | number) => void;
    fareData: {
        itineraryFares: [
            {
                fareBasisDetails: [
                    {
                        fareDetails: [
                            {
                                fareDetails: [
                                    {
                                        chargedBaggages: [];
                                        freeBaggages: [];
                                        brand:{
                                            brandName:String,
                                            code:String
                                        },
                                        cabin:string

                                    },

                                ]
                            }
                        ]
                    }
                ],
                totalAmount:number,
                id: String,
                paxFares: PaxFares[]
            }
        ],
        segments: {
            [key: string]: Segment;
        }[];
    }
    pricingCall:(id:null|Object)=> void;
    triptype:any;
    language:string;
    processAmountAsPerPaxCount(amount: number): number;
}

interface Flight {
    id: string;
    number: string;
    operatingFlightNumber: string;
    carrier: string;
    carrierName: string;
    operatingCarrier: string;
    operatingCarrierName: string;
    departureAirport: string;
    departureAirportCity: string;
    arrivalAirport: string;
    arrivalAirportCity: string;
    arrivalDateTime: string;
    departureDateTime: string;
    aircraftType: string;
    departureTerminal: string;
    arrivalTerminal: string;
    durationMin: string;
    frequency: string;
    totalMilesFlown: string;
    noOfStops: string;
}

interface Layover {
    airportCode: string;
    changeOfAirport: boolean;
    durationMinutes: string;
}

interface Segment {
    flights: Flight[];
    layovers: Layover[];
    durationMinutes: string;
}

interface CustomArrowProps {
    className?: string;
    style?: React.CSSProperties;
    onClick?: () => void;
    icon: React.ReactNode;
    currentSlide: number;
    slideCount: number;
}

interface PaxFares {
    passengerDetails: PassengerDetails;
    total: number;
    supplierBaseFare: number;
    taxes: number;
    taxBreakdown: Tax[];
    fees: Fees;
    fareBasis: FareBasis[];
}

interface Tax {
    code: string;
    desc: string;
    type: string;
    amount: number;
    currency: string;
}

interface Fees {
    bookingFee: {
        amount: number;
        currency: string;
    };
    ticketingFee: {
        amount: number;
        currency: string;
    };
}

interface FareBasis {
    flightId: string;
    fareBasisCode: string;
}

interface PassengerDetails {
    type: string;
    quantity: number;
    code: string;
}

const CustomPrevArrow: React.FC<CustomArrowProps> = ({ onClick, icon, currentSlide ,slideCount}) => {
    const isDisabled = currentSlide === 0;
    const newClass = slideCount === 1 || slideCount === 0 ? 'slick-Hide' : '';
    return (
        <CustomIconButton
            className={`fareType-arrowLeft ${isDisabled ? 'slick-disabled' : ''} ${newClass}`}
            onClick={!isDisabled ? onClick : undefined}
            icon={icon}
        />
    );
};

const CustomNextArrow: React.FC<CustomArrowProps> = ({ onClick, icon, currentSlide, slideCount }) => {
    const isDisabled = currentSlide === slideCount - 2 ||  slideCount === 2 ;
    const newClass = slideCount === 1 || slideCount === 0 ? 'slick-Hide' : '';
    return (
        <CustomIconButton
            className={`fareType-arrowRight ${isDisabled ? 'slick-disabled' : ''} ${newClass}`}
            onClick={!isDisabled ? onClick : undefined}
            icon={icon}
        />
    );
};

export const BrandedFare = ({toggleDrawer,setToggleDrawer,fareData,pricingCall, triptype, language, processAmountAsPerPaxCount}:BrandedFareProps) => {
    const { t } = useTranslation();
    const [open, setOpen] = React.useState(false);
    const [segmentData, setSegmentData]= React.useState([]);
    let now=new Date();
    let nowM=moment(now).format("MMMM DD, YYYY")
    const [departureDate, setDepartureDate] = React.useState(nowM)
    const [arrivalDate, setArrivalDate] = React.useState(nowM)
    const [brandData, setBrandData] = React.useState([])
    const [selectedOption, setSelectedOption] = useState(0);
    const [selectedCardTotal,setSelectedCardTotal] = useState(0);

    const [currentSlide, setCurrentSlide] = useState(0);
    const [slideCount, setSlideCount] = useState(0);
    const [paxCount, setPaxCount] = React.useState<{
        adult: number;
        child: number;
        infantinlap: number;
    }>({
        adult: 0,
        child: 0,
        infantinlap: 0,
    });

    const paxFares = fareData?.itineraryFares?.[0]?.paxFares;

    useEffect(()=>{
        paxFares && paxFares.forEach((item)=>{
            let data:any=paxCount;
            data[item.passengerDetails.type.toLowerCase()]=item.passengerDetails.quantity;
            setPaxCount(data)
        })
    },[paxFares])

    const getCountDisplayValue = () => {
        let displayValue = "";
        Object.keys(paxCount).forEach((key) => {
            const paxKey = key as keyof typeof paxCount;
            if (paxCount[paxKey] !== 0) {
                displayValue += `${paxCount[paxKey]} ${t(convertCamelCase(key))} `;
            }
        });
        return displayValue.trim();
    };




    const fareTypeSearch =[
        'London (LHR) to Toronto (YYZ)',
        'Newark (EWR) to London (LHR)',
        'London (LHR) to Toronto (YYZ)'
    ]


    const matches = useMediaQuery('(min-width:600px)');

    useEffect(() => {
        if (!fareData) return;

        const itineraryFares = fareData.itineraryFares || [];

        const brandFeatures = itineraryFares.map((fare, index) => {
            const fareDetails = fare?.fareBasisDetails?.[0]?.fareDetails?.[0]?.fareDetails?.[0];
            const currentFare = fare?.totalAmount;
            const nextFare = itineraryFares?.[index - 1]?.totalAmount;
            const brandDifference = null; //currentFare !== undefined && nextFare !== undefined ? (currentFare - nextFare).toFixed(2) : null;
            return {
                total: fare?.totalAmount,
                data: fareDetails,
                code: fareDetails?.brand?.code,
                brandName: fareDetails?.brand?.brandName,
                cabin: fareDetails?.cabin,
                id: fare?.id,
                freeBaggage:fareDetails?.freeBaggages,
                chargedBaggage:fareDetails?.chargedBaggages,
                brandDifference: brandDifference
            };
        });
        setBrandData(brandFeatures)


        const segments = fareData.segments || [];
        const segmentsData = segments.flatMap(segment =>
            Object.values(segment).map(flight => flight.flights)
        );

        const travelData = segmentsData.map(segment => ({
            departureCity: segment?.[0]?.departureAirportCity,
            departureAirport: segment?.[0]?.departureAirport,
            arrivalCity: segment?.[segment.length - 1]?.arrivalAirportCity,
            arrivalAirport: segment?.[segment.length - 1]?.arrivalAirport
        }));

        const firstSegment = segmentsData[0]?.[0];
        const lastSegment = segmentsData[segmentsData.length - 1]?.[0];

        setDepartureDate(moment(firstSegment?.departureDateTime).locale(language).format("MMMM DD, YYYY"));
        setArrivalDate(moment(lastSegment?.arrivalDateTime).locale(language).format("MMMM DD, YYYY"));
        setSegmentData(travelData);
    }, [fareData]);

    const handleRadioChange = (value: number) => {
        setSelectedOption(value);
    };
    
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        prevArrow: <CustomPrevArrow icon={<ArrowLeft />} currentSlide={currentSlide} slideCount={slideCount} />,
        nextArrow: <CustomNextArrow icon={<ArrowRight />} currentSlide={currentSlide} slideCount={slideCount} />,
        beforeChange: (oldIndex: number, newIndex: number) => setCurrentSlide(newIndex),
    };

    useEffect(()=>{
        let price=brandData?.[selectedOption]?.total;
        if(price){
            setSelectedCardTotal((price).toFixed(2))
        }
    },[brandData,selectedOption]);
    useEffect(() => {
        setSlideCount(brandData.length);
    }, [brandData]); 
    const mappedBrands = brandData.map((brand, index) => (
        <FareTypeCrads
            key={index} // Add a key prop for better rendering optimization
            brandData={brand}
            selectedOption={selectedOption}
            handleRadioChange={handleRadioChange}
            radioBtnCount={index}
            recommended={index === 1} // Using strict equality for clarity
            selectedCardTotal={selectedCardTotal}
            pricingCall={(fareID) => pricingCall(fareID)}
            language={language}
            processAmountAsPerPaxCount={processAmountAsPerPaxCount}
        />
    ));
    return (
        <>

            <Drawer open={toggleDrawer} anchor={"right"} >
                <Box className="fareTypeSection" sx={{ width: matches ?'50vw':'100vw',overflow:'hidden'  }}>
                    <Box className="inOut_top">
                        <Box className="inOut_Inner">
                            <Dollars className='InOutDollarIcon' />  <Typography variant='h6' className='fareType_mainTile'>{t('Select your fare')} </Typography>
                            <Typography className='fareType_mainSub'>({t('per person')})</Typography>
                        </Box>
                        <CloseIcon className='inOutClose' onClick={()=>setToggleDrawer(false,null)} />
                    </Box>
                    <Box className="inOutContent">
                        <Typography className='inOutContent_Title' variant='h6'>
                            {segmentData && segmentData.map((item,index) => {
                                return (<>
                                        {`${item.departureCity} (${item.departureAirport}) - ${item.arrivalCity} (${item.arrivalAirport}) ${index < segmentData.length  - 1 ? ` | ` : ''} ` }
                                </>)
                            })}
                        </Typography>
                        <Typography variant='h4'>
                            {triptype?.label == TRIPTYPE.ONEWAY ? t("One Way"):triptype?.label == TRIPTYPE.ROUNDTRIP ? t("Round Trip"):t("Multi City")} | {departureDate} - {arrivalDate} | {getCountDisplayValue()}</Typography>
                    </Box>

                    <Box className="inOutSlider">
                        <Slider {...settings}>
                            {mappedBrands}
                        </Slider>
                    </Box>
                </Box>
            </Drawer>
        </>
    );
}