import * as React from 'react';
import { Box, Button, Container, Grid, Table, TableCell, TableRow, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { CardLabel } from '../CardLabel';
import { INSURANCESTR } from '../../../utils/constant';
import { useTranslation } from 'react-i18next';

export default function GiftCardDetails(props: any) {
    const { t } = useTranslation();
    let cardData = props?.giftCardData;

    function maskCreditCardNumber(cardNumber: any) {
        // Ensure the input is a string
        cardNumber = cardNumber.toString();

        // Get the first 4 and last 4 digits
        const firstFour = cardNumber.slice(0, 4);
        const lastFour = cardNumber.slice(-4);

        // Calculate the number of '*' to be placed in between
        const maskedSectionLength = cardNumber.length - 8;
        const maskedSection = '*'.repeat(maskedSectionLength);

        // Concatenate the parts and return
        return firstFour + maskedSection + lastFour;
    }

    return (
        <Box className="conftion__wrapper">
            <Box className="conftion__header">
                <Typography className='conftionTitle'>{t('Gift Card Details')}</Typography>
            </Box>
            <hr className='conftion_hr' />
            <Box className="BillingDetails">
                {cardData && cardData.map((item: any) => {
                    return (

                        <Box className="Booking_SummaryContent">
                            <CardLabel title={`${t('Gift Card Number')}:`} subTitle={item.number && maskCreditCardNumber(item.number)} />
                            <CardLabel title={`${t('Amount Charged')}:`} subTitle={item.charge} />
                        </Box>

                    )
                })}
            </Box>
        </Box>
    )
}