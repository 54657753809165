import React, { FC } from 'react';
import { Grid, Box, Container } from '@mui/material';
import SearchStepper from '../common/SearchStepper';
import { stepperConfig } from '../../utils/constant';
import { useTranslation } from 'react-i18next';

interface StepperProps {
    page: StepperConfigKey;
}

export type StepperConfigKey = keyof typeof stepperConfig;

export const Stepper: FC<StepperProps> = ({ page }) => {
    const { t } = useTranslation();
    const dynamicComponent = stepperConfig[page];

    return (
        <Container>
            <Grid item sm={12}>
                <Box className="SearchStepper">
                    {dynamicComponent.map((step, index) => (
                        <SearchStepper
                            key={index}
                            StepperTitle={t(step.StepperTitle)}
                            StepperFinish={step.StepperFinish}
                            StepperCount={step.StepperCount}
                            StepperActive={step.StepperActive}
                        />
                    ))}
                </Box>
            </Grid>
        </Container>
    );
};