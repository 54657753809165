import React from 'react';
import moment from 'moment';
// @ts-ignore
import logoRedtag from '../../../assets/icons/logo-redtag.svg';
// @ts-ignore
import iconTravelPath from '../../../assets/icons/icon-travel-flight-path.svg';
// @ts-ignore
import icondatetime from '../../../assets/icons/icon-date-time.svg';
import { Box, Typography } from '@mui/material';
import { formatDate, formatTime } from '../../../utils/util';
import { useTranslation } from 'react-i18next';

const FlightDetailCardHeader = ({ title, BookingCode }: any) => {
    
    return (<>
        <Box className="flightDet_cardHeader">
            <Typography className='flightDet_cardTitle'> {title}</Typography>
            {BookingCode && <Box>
                <Typography className='flightDet_cardTitle' component={'span'}> Booking Code:</Typography>
                <Typography className='flightDet_cardCode' component={'span'}> {BookingCode} </Typography>
            </Box>}

        </Box>
    </>)
}

const FlightDetailLayover = ({ duration }: any) => {
    const { t } = useTranslation();
    const calculateLayoverDuration = (durationInMinutes: string) => {
        const duration = parseInt(durationInMinutes);
        let days = Math.floor(duration / (24 * 60));
        let remainingMinutesAfterDays = duration % (24 * 60);
        let hours = Math.floor(remainingMinutesAfterDays / 60);
        let remainingMinutes = remainingMinutesAfterDays % 60;
        let result = "";
        result += days ? `${days}d` : result;
        result += !days && !hours ? result : !days ? `${hours}h ` : `${days}d ${hours}h `;
        result += `${remainingMinutes}m`;
        return result;
    }
    return (<>
        <Box className='filght_layover'>
            <Box className='filght_Title'>
                <img src={icondatetime} />
                <Typography component={'span'} className='layover_title'>{t('Layover')}: </Typography>
                <Typography component={'span'} className='layover_time'>{calculateLayoverDuration(duration)}</Typography>
                {/* <Typography component={'span'} className='layover_code'>(ORD)</Typography> */}
            </Box>
            <Box className="flight_border"></Box>
        </Box>
    </>);
}

const FlightDetailsCard = ({ segments,recordLocator }: any) => {
    const { t } = useTranslation();
    const calculateDuration = (departure: string, arrival: string) => {
        const departureTime = new Date(departure);
        const arrivalTime = new Date(arrival);
        const durationInMinutes = (arrivalTime.getTime() - departureTime.getTime()) / (1000 * 60);
        const hours = Math.floor(durationInMinutes / 60);
        const minutes = durationInMinutes % 60;
        return `${hours}h ${minutes}m`;
    };
    
    const getNormalizedLayoverData = (layover: any) => {
        let layoverObj: any = {}
        layover.forEach((info: any) => {
            if (layoverObj[info.departingFlightId]) {
                let data = layoverObj[info.departingFlightId];
                layoverObj[info.departingFlightId] = [...data, info]
            } else {
                layoverObj[info.departingFlightId] = [info]
            }
        })
        return layoverObj;
    }
    const getFareDirectionDetails = (flights: any[], index: number) => {
        let title = index == 0 ? t("Departure") : t("Return");
        let direction = `${title}`; 
        if(flights?.length){
            if(flights?.length > 1){
                direction += `: ${flights?.[0]?.departureAirportCity} ${t('to')} ${flights[flights.length - 1]?.arrivalAirportCity}`
            }else{
                direction +=  `: ${flights?.[0]?.departureAirportCity} ${t('to')} ${flights[0]?.arrivalAirportCity}`
            }
        }
        return direction;
    }
    return (
        segments?.map((route: any, index: number) => {
            const routeKey = Object.keys(route)[0];
            const routeData = route[routeKey];
            const { flights = [], layovers = [] } = routeData;
            const normalizedLayovers = getNormalizedLayoverData([...layovers]);
            let headerTitle = getFareDirectionDetails(flights, index); 
            return (<>
                <FlightDetailCardHeader title={headerTitle} BookingCode={flights[index]?.airlineRecordLocator || ''}  />
                {flights.map((flight: any, idx: number) => {
                    const airlineLogo = `${flight?.carrier?.toLowerCase()}.png`;
                    const duration = calculateDuration(flight.departureDateTime, flight.arrivalDateTime);
                    return (<>
                        <Box className="flightDet_cardBody">
                            <Box className="flight_IconSection">
                                <img src={`${process.env.REACT_APP_FLIGHT_LOGO_URL}/${airlineLogo}`} className='flightDet_Icon' alt={`${airlineLogo} logo`} />
                                <Typography className='flightDet_name'>{flight.carrierName} • {flight.carrier} {flight.number} • {t(flight.cabin)}</Typography>
                            </Box>
                            <Box className="flightDetSection">
                                <Box className="flightDet_Flightdata flightSectionLeft">
                                    <Box className="flightDet_dateTime">
                                        <Typography className='flightDet_Date' component={'span'}>{formatDate(flight.departureDateTime)} |</Typography>
                                        <Typography className='flightDet_Time' component={'span'}> {formatTime(flight.departureDateTime)}</Typography>
                                    </Box>
                                    <Box className="flightDet_Flights">
                                        <Typography className='flight_name'>{flight.departureAirport}</Typography>
                                        <Typography className='flightDet_place'>{flight.departureAirportCity}</Typography>
                                    </Box>
                                    <Typography className='flightDet_opratBy'> {t('Operated by')}: {flight.operatingCarrierName}</Typography>
                                </Box>
                                <Box className="flightDet_timeInfo">
                                    <Typography className='timeInfo_time'>{duration}</Typography>
                                    <img src={iconTravelPath} className='flightDet_Icon' />
                                    <Typography className='timeInfo_code'>{flight.carrier}{flight.number}</Typography>
                                    {/* <Typography className='timeInfo_code'>AC1961</Typography> */}
                                </Box>
                                <Box className="flightDet_Flightdata flightSectionRight">
                                    <Box className="flightDet_dateTime">
                                        <Typography className='flightDet_Date' component={'span'}>{formatDate(flight.arrivalDateTime)} |</Typography>
                                        <Typography className='flightDet_Time' component={'span'}> {formatTime(flight.arrivalDateTime)}</Typography>
                                    </Box>
                                    <Box className="flightDet_Flights">
                                        <Typography className='flightDet_place'>{flight.arrivalAirportCity}</Typography>
                                        <Typography className='flight_name'>{flight.arrivalAirport}</Typography>
                                    </Box>
                                    {/* <Box className='flightDet_seats' >
                            <Typography className='flightDet_seatTitle' component={'span'}>Seats :</Typography>
                            <Typography className='flightDet_code' component={'span'}>15E, 15F</Typography>
                        </Box> */}
                                </Box>
                            </Box>
                        </Box>
                        {normalizedLayovers[flight.id]?.length ? normalizedLayovers[flight.id].map(({ durationMinutes }: any) => (
                            <FlightDetailLayover duration={durationMinutes} />
                        )) : null}
                    </>)
                })}
            </>)
        })
    );
};

export default FlightDetailsCard;