import * as React from 'react';
import { Box, Button, Container, Grid, Table, TableCell, TableRow, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { CardLabel } from '../CardLabel';
import moment from 'moment';
import { INSURANCESTR } from '../../../utils/constant';

interface InsuranceDetailsInterface {
    quoteBookingData: any;
    passengerData: [];
}

interface Passenger {
    passengerId: string;
    name: string;
    firstName: string;
    middleName: string;
    lastName: string;
    birthDate: string;
    price: string;
    tax: string;
    total: string;
  }
  
  interface Plan {
    planCode: string;
    planName: string;
    planPrice: string;
    planTax: string;
    planTotal: string;
    passengers: {
      passenger: Passenger[];
    };
  }

export default function InsuranceDetails(props: InsuranceDetailsInterface) {
    let quoteBookingData = props?.quoteBookingData || {};

    const {number = ''} = quoteBookingData;
    const error = 'error' in quoteBookingData ? (quoteBookingData as any).error : '';
    let passengerData = props?.passengerData;
    
    let insuranceStatusText = '';
    let insuranceFooterMsg = '';
    if (number) { // you have selected insurance
        insuranceStatusText = number;
        insuranceFooterMsg = INSURANCESTR.comEmailInsConfMsg;
    } else if (error) { // you have selected insurance but got error
        insuranceStatusText = INSURANCESTR.comError;
        insuranceFooterMsg = INSURANCESTR.comEmailInsCurrentlyProcessing
    } else { // you have declined insurance
        insuranceStatusText = INSURANCESTR.comDecline;
        insuranceFooterMsg = INSURANCESTR.comDeclineMsg
    }


    return (
        <Box className="conftion__wrapper">
            <Box className="conftion__header">

                <Typography className='conftionTitle'>{INSURANCESTR.travelInsuranceDetails}</Typography>

            </Box>
            <hr className='conftion_hr' />
            <Box className="conftion_card">
                <Box className="conftion_cardHeader">
                    <Typography className='conftion_cardTitle'>{INSURANCESTR.comManulifePolicy} # {insuranceStatusText}
                    </Typography>
                </Box>

                {insuranceStatusText != INSURANCESTR.comDecline ?
                <Box className="conftion_cardBody">
                    <Grid container spacing={2}>
                        {quoteBookingData?.policyPlans?.plan?.map((item: Plan, planIndex: number) => {
                            return (
                                <React.Fragment key={planIndex}>
                                    {/* Loop through passengers */}
                                    {item.passengers?.passenger?.map((passenger: Passenger, passengerIndex: number) => {
                                        return (
                                            <Grid item sm={6} xs={12} key={passengerIndex}>
                                                <Box>
                                                    <Typography className='confition_bodysubTitle' component={'span'}>
                                                        {passenger.firstName} {passenger.lastName}
                                                    </Typography>
                                                    <br />
                                                    <Typography className='confition_bodyTitle' component={'span'}>
                                                        {item.planName}
                                                    </Typography>

                                                </Box>
                                            </Grid>
                                        );
                                    })}
                                </React.Fragment>
                            )
                        })}
                        {quoteBookingData.error && passengerData ?
                            <React.Fragment>
                                {passengerData.map((passenger: any, passengerIndex: number) => {
                                    return (
                                        <Grid item sm={6} xs={12} key={passengerIndex}>
                                            <Box>
                                                <Typography className='confition_bodysubTitle' component={'span'}>
                                                    {passenger.firstName} {passenger.lastName}
                                                </Typography>
                                                <br />
                                                <Typography className='confition_bodyTitle' component={'span'}>
                                                    {INSURANCESTR.comPending}
                                                </Typography>

                                            </Box>
                                        </Grid>
                                    );
                                })}
                            </React.Fragment>
                            : ''}
                    </Grid>
                </Box>
                :""}
            </Box>
            <Box className="conftion_cardBFooter">
                <Box>
                    <Typography className='confition_bodyTitle' component={'span'}>{insuranceFooterMsg}</Typography>
                </Box>
            </Box>
        </Box>
    )
}