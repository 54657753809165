import { configureStore, Store, Middleware } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage
import rootReducer, { RootState } from './rootReducer'; // Assuming RootState is exported from rootReducer

interface PersistConfig {
  key: string;
  storage: typeof storage;
  whitelist?: string[];
  blacklist?: string[];
}

interface Action{
  type: string;
  payload: any;
}

// Define middleware for logging actions

const persistConfig: PersistConfig = {
  key: 'root',
  storage
};

const persistedReducer = persistReducer<RootState, any>(persistConfig, rootReducer); // Use RootState for the state type

const store: Store<RootState> = configureStore({ 
  reducer: persistedReducer
});
const persistor = persistStore(store);

export default store;
export { persistor }; // Export store and persistor
