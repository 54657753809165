import * as React from 'react';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';

 

export default function FilterRangeTilte({ToRange,FromRange}:any) {
 
  return (

      <Box className="searchRes_RangeTop" px={'20px'}>
        {/* <Typography component={'span'} className='searchRes_RangeTitle'>{ToRange} </Typography>
        <Typography component={'span'} className='searchRes_RangeTitle'>{FromRange}</Typography> */}
      </Box>
 
  );
}


