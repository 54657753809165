import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import { Grid, Box, Autocomplete, Typography, TextField, RadioGroup, Radio, FormControlLabel, Button, Checkbox, IconButton, FormControl, Select, MenuItem } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import AddIcon from '@mui/icons-material/Add';
import FlightIcon from '@mui/icons-material/Flight';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import DateRange from '../common/DateRangepicker';
import SearchWidgetChildren from '../common/SearchWidgetChildren';
import AutocompleteComponent from '../common/Form/Autocomplete';
import { CREATE_ELEMENT } from '../../utils/form';
import { CREATE_SEGMENT, TRIPTYPE, getPopulatedDataArray, onlyDateFormat } from '../../utils/constant';
import SelectComponent from '../common/select';
import AdvancedForm, { ChildRef, FlightDetails } from '../common/AdvancedForm/AdvancedForm';
import { SearchRequests }from '../../module/index';
import { ErrorHandler } from "../Error/ErrorNotifier";
import { processFromToDate } from '../../utils/util';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const SerachTabContent = ({ setTrip, urlData, ...props }: any,) => {
    const { t } = useTranslation()
    const cabinOptions = ["Economy", "Premium Economy", "Business", "First"];
    const currentDate = dayjs();
    const nextDay = currentDate.add(1, 'day');
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [searchToTerm, setSearchToTerm] = useState<string>('');
    const [fromDateDisplay, setFromDateDisplay] = useState<any | null>(null);
    const [toDateDisplay, setToDateDisplay] = useState<any | null>(null);
    const [fromDate, setFromDate] = useState<string | null>();
    const [toDate, setToDate] = useState<string | null>();
    const [adultCount, setAdultCount] = useState<number>(1);
    const [childCount, setChildCount] = useState<number>(0);
    const [childAge, setChildAge] = useState<number[]>([]);
    const [infantCount, setInfantCount] = useState<number>(0);
    const [freeBag, setFreeBag] = useState<number>(1);
    const [isNonStop, setIsNonStop] = useState(false)
    const [cabin, setCabin] = useState("Economy")
    const [autoSuggestCity, setAutoSuggestCity] = useState([])
    const [flyingFrom, setFlyingFrom] = useState<any>()
    const [flyingTo, setFlyingTo] = useState<any>();
    const [timeoutId, setTimeoutId] = useState(null);
    const advancedFormRef = useRef<ChildRef>(null);
    const [advancedFormUrlData, setAdvancedFormUrlData] = useState([])
    const [dateErrorMessage, setDateErrorMessage] = useState("")
    const correlationId = useSelector((state: any) => state.search.correlationId);

    useEffect(() => {
        if (props.data) {
            setAutoSuggestCity(props.data)
        }
    }, [props.data])

    useEffect(() => {
        if(urlData){
            setUrlData();
        }
    }, [urlData])

    useEffect(() => {
        if(dateErrorMessage){
            setFromDateDisplay(null)
            setFromDate(null)
        }
    }, [dateErrorMessage]);

    const setUrlData = () => {
        const {legs, infants, childrens, adults, tripType, multiSuggest} = urlData;
        let {fromTo, advancedForm = []} = processFromToDate(tripType, legs);
        setFlyingFrom(fromTo.flyingFrom);
        setFlyingTo(fromTo.flyingTo);
        setSearchTerm(fromTo.fromLabel);
        setSearchToTerm(fromTo.toLabel);
        setAdultCount(adults);
        setChildCount(childrens.length);
        setInfantCount(infants.length);
        setChildAge([...infants, ...childrens])
        setFromDateDisplay(fromTo.fromDate)
        setFromDate(fromTo.fromDate)
        if(tripType?.toUpperCase() === TRIPTYPE.ROUNDTRIP?.toUpperCase() || tripType?.toUpperCase() === TRIPTYPE.ROUNDTRIP_CNAME?.toUpperCase()){
            setToDateDisplay(fromTo.toDate)
            setToDate(fromTo.toDate)
        }
        multiSuggest && setAutoSuggestCity(multiSuggest);
        setAdvancedFormUrlData(advancedForm);
    }

    interface CityOption {
        code: string;
        value: {
            country: string;
            code: string;
        };
        label: string;
        customAbbreviation: string;
    }

    const debouncedAutoSuggestAPICall = async (searchValue: string) => {
        await props.autoSuggestCall(searchValue)
    };

    const handleFlyingToFromChange = (event: ChangeEvent<{}>, newValue: { label: string; value: CityOption } | null, updateMethod: Function) => {
        if(!event)
            return;
        if (newValue) {
            updateMethod(newValue.value.code);
        }
    };

    const handleFlyingToFromInputChange = async (event: ChangeEvent<{}>, newInputValue: string, setToFromValue: Function) => {
        if(!event || event?.type === "blur")
            return;
        setToFromValue(newInputValue);
        if (timeoutId) {
            clearTimeout(timeoutId);
        }
        const newTimeoutId = setTimeout(() => {
            if (newInputValue) {
                const hasSpecialCharacters = /[^a-zA-Z0-9 ]/.test(newInputValue);
                if (!hasSpecialCharacters) {
                    debouncedAutoSuggestAPICall(newInputValue);
                }
            }
        }, 500);
        setTimeoutId(newTimeoutId);
    }

    const handleDateChange = (date: dayjs.Dayjs | null, dateType: string) => {
        const formattedDate = date ? onlyDateFormat(date) : null;
        setDateErrorMessage("");
        if (dateType === 'from') {
            setFromDate(formattedDate);
            setFromDateDisplay(date)
        } else {
            setToDate(formattedDate);
            setToDateDisplay(date)
        }
    };



    const search = async () => {
        const queryParams = new URLSearchParams({ uid: correlationId });
        // Update the URL without reloading the page
        const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
        window.history.pushState(null, '', newUrl);
        let errorMessage = '';
        if (!flyingFrom || !flyingTo || !searchTerm || !searchToTerm) {
            ErrorHandler('Fill required information','Please select valid Origin / Destination', null, 'Ok', 'warning', 'alertModal');
            return;
        }
        if (!fromDate) {
            ErrorHandler('Fill required information', 'Please select valid dates', null, 'Ok', 'warning', 'alertModal');
            return;
        }
        if (!toDate && setTrip === TRIPTYPE.ROUNDTRIP) {
            ErrorHandler('Fill required information','Please select valid dates', null, 'Ok', 'warning', 'alertModal');
            return;
        }
        let ages = childAge.filter(age => !age && age !== 0);
        if (ages?.length) {
            ErrorHandler('Fill required information', 'Please select valid age', null, 'Ok', 'warning', 'alertModal');
            return;
        }
        if (adultCount < infantCount) {
            ErrorHandler('Fill required information', 'Please select number of infants', null, 'Ok', 'warning', 'alertModal');
            return;
        }
        props.searchWidgetHide(false)
        let obj = {
            adultCount: adultCount,
            childCount: childCount,
            childAge:childAge,
            infantCount: infantCount,
            flyingFrom: flyingFrom,
            flyingTo: flyingTo,
            fromDate: fromDate,
            toDate: toDate,
            setTrip: setTrip,
            isNonStop: false,
            freeBag: 0,
            advancedFormRef: advancedFormRef.current?.getFormValue(),
            cabinType: props.cabinType,
        }
        try {
            let { requestPayload,searchFormValid } = new SearchRequests(obj, advancedFormRef.current?.getFormValue());
            if(!searchFormValid){
                ErrorHandler('Fill required information', 'Please fill out required information in SearchBox section. ', null, 'Ok', 'warning', 'alertModal');
                return;
            }
            let payload = {...requestPayload,childAge};
            await props.searchResultData(payload);
        } catch (error) {
            console.error('Search request failed:', error);
        }
        
    }

    const BASE_FORM_CONFIG = [
        {
            component: AutocompleteComponent,
            props: {
                gridOptions: { item: true, sm: 3, xs: 12 },
                className: "SearchWidget__Input",
                disablePortal: true,
                id: "combo-box-demo",
                name: "departingFrom",
                autoSuggestOptions: autoSuggestCity,
                onChangeMethod: handleFlyingToFromChange,
                onChangeKeyMethod: setFlyingFrom,
                label: t('Departing From'),
                typographyClassName: "search_FieldTitle",
                inputValue: searchTerm,
                onInputChangeMethod: handleFlyingToFromInputChange,
                onInputChangeKeyMethod: setSearchTerm,
                iconClass: "searchW_FilghtIcon searchW_Icon",
                Icon: FlightIcon
            }
        },
        {
            component: AutocompleteComponent,
            props: {
                gridOptions: { item: true, sm: 3, xs: 12 },
                className: "SearchWidget__Input",
                disablePortal: true,
                id: "combo-box-demo",
                name: "goingTo",
                autoSuggestOptions: autoSuggestCity,
                onChangeMethod: handleFlyingToFromChange,
                onChangeKeyMethod: setFlyingTo,
                label: t('Going To'),
                typographyClassName: "search_FieldTitle",
                inputValue: searchToTerm,
                onInputChangeMethod: handleFlyingToFromInputChange,
                onInputChangeKeyMethod: setSearchToTerm,
                iconClass: "searchW_Icon",
                Icon: LocationOnIcon
            }
        },
        {
            component: DateRange,
            props: {
                gridOptions: { item: true, sm: 3, xs: 12 },
                onChangeMethod: handleDateChange,
                allowDateRangeSelection: setTrip === TRIPTYPE.ROUNDTRIP,
                name: "dateRange",
                label: t("Departure dates"),
                labelCalssName:'search_FieldTitle',
                Icon: CalendarMonthIcon,
                iconPosition: "start",
                iconClass: "search_dateIcon",
                dateRangeTextFieldClass: "search_datePicker SearchWidget__Input",
                showNumberOfMonths: 2,
                startDate: fromDateDisplay ? new Date(fromDateDisplay) : null,
                endDate: toDateDisplay ? new Date(toDateDisplay) : null,
                errorMessage: dateErrorMessage
            }
        },
        {
            component: SelectComponent,
            props: {
                gridOptions: { item: true, sm: 1, xs: 6 },
                selectClassName: "SearchWidget__Input",
                label: t("Adult"),
                onChangeMethod: setAdultCount,
                options: getPopulatedDataArray(6),
                labelClassName: "search_FieldTitle search_FieldAdult",
                placeHolder: t("Adult"),
                fullWidth: true,
                name: "adultAge",
                value: adultCount
            }
        },
        {
            component: SearchWidgetChildren,
            props: {
                name: "pax",
                gridOptions: { item: true, sm: 1, xs: 6 },
                childCount: childCount,
                childAge: childAge,
                infantCount: infantCount,
                setChildCount: setChildCount,
                setChildAge:setChildAge,
                setInfantCount: setInfantCount
            }
        }
    ]


    let FORM_CONFIG: any = {
        [TRIPTYPE.ROUNDTRIP]: [...BASE_FORM_CONFIG],
        [TRIPTYPE.ONEWAY]: [...BASE_FORM_CONFIG],
        [TRIPTYPE.MULTICITY]: [...BASE_FORM_CONFIG]
    }

    const createDynamicElement = (field: object) => {
        return CREATE_ELEMENT(field);
    }

 

    return (
        <>
            <Box className="SearchTabPanel">
                <Grid container spacing={2} >
                    {FORM_CONFIG[setTrip].map((elem: object, index: number) => (createDynamicElement(elem)))}
                    <Grid item sm={1} xs={12}>
                        <Box className='searchBtnSection'>
                            <Button className='SearchWidget_SearchBtn' onClick={search}>{t('Search')}</Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            {setTrip === TRIPTYPE.MULTICITY && (
                <AdvancedForm
                    advancedFormData={advancedFormUrlData}
                    allowListLength={5}
                    autoSuggestDataCall={props.autoSuggestCall}
                    autoSuggestData={props.data}
                    ref={advancedFormRef} 
                    fromDate={fromDate}
                    setDateErrorMessage={setDateErrorMessage}
                />
            )}
        </>
    )
}

export default SerachTabContent;