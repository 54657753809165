import React, { useState } from 'react';
import {getSearchResultService, getSearchResultServiceV2} from "../services";
const SearchResultAPI = () => {
    const [searchResultData, setSearchResultData] = useState(null);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const searchResultAPI = async(arg:string) => {
        console.log("this is called")
        try {
            setLoading(true);
            setError(null);
            let autoComplete=[]
            let autoCompleteResult: { value: any; label: string; customAbbreviation: string; }[]=[]
            const res = await getSearchResultService(arg);
            // let result=res?.data
            setSearchResultData(res)
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    }
    return [searchResultAPI, searchResultData, loading, error];
};

const SearchResultAPIV2 = () => {
    const [searchResultData, setSearchResultData] = useState(null);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const searchResultAPI = async(payload:string,correlationId:string) => {
        console.log("this is called")
        try {
            setLoading(true);
            setError(null);
            let autoComplete=[]
            let autoCompleteResult: { value: any; label: string; customAbbreviation: string; }[]=[]
            const res = await getSearchResultServiceV2({payload,correlationId});
            // let result=res?.data
            setSearchResultData(res)
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    }
    return [searchResultAPI, searchResultData, loading, error];
};

export {SearchResultAPI, SearchResultAPIV2};