import React from "react";
import { Box, Container, Typography, Skeleton } from "@mui/material";

const BookingHeaderLoader = () => {
  return (
    <Box className="inOutContent booking_headerBox">
      <Container>
        <Typography className="booking_headerTilte" variant="h6">
          <Skeleton variant="text" width={300} height={30} />
        </Typography>
        <Typography className="booking_headerSubTilte">
          <Skeleton variant="text" width={400} height={20} />
        </Typography>
      </Container>
    </Box>
  );
};

export default BookingHeaderLoader;
