import React, { useState, useEffect, useRef } from 'react';
import { Typography, TextField, Box, Button, Link } from '@mui/material';
import { Redeem } from '@mui/icons-material';
import "../../styles/pages/_Giftcard.scss";

interface GiftCardInputs {
    number: string;
    pin: string;
    error: string;
    balance: string;
    charge: any;
    successMessage: string;
    loading: boolean;
    totalCharge: any;
}

interface GiftCardApplyInterface {
    giftCardAPI: any;
    giftCardResult: any;
    availableGiftCard: any;
    priceData: any;
    correlationId: any;
}

const GiftCard: React.FC<GiftCardApplyInterface> = ({ giftCardAPI, giftCardResult = null, availableGiftCard, priceData, correlationId }) => {
    const initialGiftCardData = {
        number: '',
        pin: '',
        error: '',
        balance: '',
        charge: '',
        successMessage: '',
        loading: false,
        totalCharge: 0
    };

    const [giftCardCount, setGiftCardCount] = useState(1);
    const [giftCards, setGiftCards] = useState<GiftCardInputs[]>([initialGiftCardData]);
    const [currentGiftCardIndex, setCurrentGiftCardIndex] = useState<number | undefined>(undefined);
    const [totalChargeGiftCard, settotalChargeGiftCard] = useState(0);
    const [addMoreGiftCardsLink, setAddMoreGiftCardsLink] = useState(true);

    const prevGiftCardResult = useRef<any>(null);

    const addMoreGiftCards = () => {
        setAddMoreGiftCardsLink(false);
        if (giftCardCount < 5) {
            setGiftCardCount(giftCardCount + 1);
            setGiftCards([...giftCards, initialGiftCardData]);
        }
    };

    // const handleInputChange = (index: number, field: keyof GiftCardInputs, value: string) => {
    //     const updatedGiftCards = [...giftCards];
    //     updatedGiftCards[index] = {
    //         ...updatedGiftCards[index],
    //         [field]: value,
    //     };
    //     setGiftCards(updatedGiftCards);
    // };

    const handleInputChange = (index: number, field: keyof GiftCardInputs, value: string) => {
        setGiftCards((prevGiftCards) => {
            const updatedGiftCards = [...prevGiftCards];
            updatedGiftCards[index] = {
                ...updatedGiftCards[index],
                [field]: value,
            };
            return updatedGiftCards;
        });
    };

    const handleApplyClick = (index: number) => {
        const { number, pin } = giftCards[index];

        let error = '';
        if (!number) {
            error = 'Please enter gift card number';
        } else if (!pin) {
            error = 'Please enter gift card pin';
        } else if (!/^\d{4}$/.test(pin)) {
            error = 'PIN must be a 4-digit number';
        }

        const updatedGiftCards = [...giftCards];
        updatedGiftCards[index].error = error;

        setGiftCards(updatedGiftCards);

        const reqPayload = {
            sid: correlationId,
            lang: "en",
            cardNumber: number,
            pin: pin,
        };

        if (!error) {
            setCurrentGiftCardIndex(index);
            updatedGiftCards[index].loading = true;
            setGiftCards(updatedGiftCards);
            giftCardAPI(reqPayload);
        }
    };

    // const removeGiftCard = (index: number) => {
    //     const updatedGiftCards = [...giftCards];
    //     updatedGiftCards[index] = { ...initialGiftCardData };
    //     setGiftCards(updatedGiftCards);
    //     setCurrentGiftCardIndex(undefined);
    // };
    const removeGiftCard = (index: number) => {
        if (giftCards.length === 1) {
            setGiftCards([initialGiftCardData]);
        } else {
            const updatedGiftCards = giftCards.filter((_, i) => i !== index);
            setGiftCards(updatedGiftCards);
        }
        const newTotalCharge = giftCards
            .filter((_, i) => i !== index)
            .reduce((acc, card) => acc + Number(card.charge), 0);

        settotalChargeGiftCard(newTotalCharge);
        if (newTotalCharge < priceData.itineryTotal) {
            setAddMoreGiftCardsLink(true);
        }
    };


    const updateGiftCardData = (index: number) => {
        setGiftCards((prevGiftCards) => {
            const updatedGiftCards = [...prevGiftCards];

            updatedGiftCards[index].loading = false;
            updatedGiftCards[index].error = '';
            updatedGiftCards[index].successMessage = '';

            let newCharge = 0;

            if (giftCardResult.status === 'failed') {
                setAddMoreGiftCardsLink(false);
                updatedGiftCards[index].error = giftCardResult.error;
            } else if (giftCardResult.status === 'success') {
                if (giftCardResult.balance === 0) {
                    setAddMoreGiftCardsLink(false);
                    updatedGiftCards[index].error = 'You have an insufficient balance.';
                } else {
                    setAddMoreGiftCardsLink(true);
                    updatedGiftCards[index].successMessage = 'Gift card applied successfully';
                    updatedGiftCards[index].balance = giftCardResult.balance;

                    const remainingItineraryTotal = priceData.itineryTotal - totalChargeGiftCard;

                    // Calculate the charge based on the remaining itinerary total
                    if (Number(giftCardResult.balance) >= remainingItineraryTotal) {
                        updatedGiftCards[index].charge = remainingItineraryTotal;
                    } else {
                        updatedGiftCards[index].charge = Number(giftCardResult.balance);
                    }

                    newCharge = Number(updatedGiftCards[index].charge);

                    // Update total charge directly without relying on previous state
                    const updatedTotalCharge = totalChargeGiftCard + newCharge;
                    updatedGiftCards[index].totalCharge = updatedTotalCharge;

                    settotalChargeGiftCard(updatedTotalCharge);

                    // Disable "Add More Gift Cards" link if total charge exceeds itinerary total
                    if (updatedTotalCharge >= priceData.itineryTotal) {
                        setAddMoreGiftCardsLink(false);
                    }
                }
            }
            return updatedGiftCards;
        });
    };



    useEffect(() => {

        if (giftCardResult && currentGiftCardIndex !== undefined && giftCardResult !== prevGiftCardResult.current) {
            updateGiftCardData(currentGiftCardIndex);
        }
        prevGiftCardResult.current = giftCardResult;
    }, [giftCardResult, currentGiftCardIndex]);

    useEffect(() => {
        const appliedGiftCards = giftCards.filter(card => card.successMessage !== '');
        availableGiftCard(appliedGiftCards);
    }, [giftCards]);

    const renderGiftCards = () => {
        return giftCards.map((giftCard, index) => (
            <div key={index}>
                <Box className="booking_GiftWrapper">

                    <Box className="booking_GiftInputs">
                        <TextField
                            label="Gift Card Number"
                            name={`giftcardnumber_${index}`}
                            variant="standard"
                            required
                            className="Booking_Label"
                            value={giftCard.number}
                            onChange={(e) => handleInputChange(index, 'number', e.target.value)}
                            disabled={giftCard.loading}
                        />
                        <TextField
                            label="4 Digit Pin"
                            name={`giftcardpin_${index}`}
                            variant="standard"
                            required
                            className="Booking_Label"
                            value={giftCard.pin}
                            onChange={(e) => handleInputChange(index, 'pin', e.target.value)}
                            disabled={giftCard.loading}
                            inputProps={{
                                pattern: "[0-9]*",
                                inputMode: "numeric",
                                maxLength: 4,
                            }}
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            className="Booking_SubmitButton"
                            onClick={() => { giftCard.successMessage ? removeGiftCard(index) : handleApplyClick(index) }}
                            disabled={giftCard.loading}
                        >
                            {giftCard.successMessage ? 'Remove' : 'Add'}
                        </Button>
                    </Box>
                    <Box>
                        {giftCard.successMessage && giftCard.balance && (
                            <Typography color="primary" variant="body2" className='booking_GiftInfo'>
                                Available Balance: {giftCard.balance}
                            </Typography>
                        )}
                    </Box>
                </Box>
                <Box className='booking_GiftMsg'>
                    {giftCard.error && (
                        <Typography color="error" variant="body2" className='booking_GiftInfo'>
                            {giftCard.error}
                        </Typography>
                    )}
                    {giftCard.successMessage && (
                        <Typography color="primary" variant="body2" className='booking_GiftInfo booking_successMessage'>
                            {giftCard.successMessage}
                        </Typography>
                    )}
                </Box>
            </div>
        ));
    };

    const isAnyGiftCardApplied = giftCards.some(card => card.successMessage !== '');
    return (
        <>
            <Box className="Booking_TitleBox" >
                <Redeem className="Booking_TitleIcon" />
                <Typography variant="h6" className="Booking_SecondaryTitle">
                    Add Gift Card
                </Typography>
            </Box>

            <Typography variant="body1" className="Booking_GiftCardTips">
                Important: Online redemption only valid for cards purchased after June 2024, please contact a redtag agent to redeem cards issued prior to that date.
            </Typography>
            {renderGiftCards()}
            <Box className='booking_giftcard_linkSection'>
                {giftCardCount < 5 && (
                    <Link
                        underline="always"
                        onClick={isAnyGiftCardApplied && addMoreGiftCardsLink ? addMoreGiftCards : undefined}
                        className="booking_giftcard_link"
                        style={{ cursor: isAnyGiftCardApplied && addMoreGiftCardsLink ? 'pointer' : 'not-allowed', pointerEvents: isAnyGiftCardApplied ? 'auto' : 'none', color: isAnyGiftCardApplied && addMoreGiftCardsLink ? 'inherit' : 'gray' }}
                    >
                        Add More Gift Cards
                    </Link>
                )}
            </Box>
        </>
    );
};

export default GiftCard;
