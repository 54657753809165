import { AxiosRequestConfig, AxiosResponse } from "axios";
import { b2cService } from "../axiosServices";

interface RequestBodyInterface{
    payload:String;
    correlationId:String
}

const getSearchResultService = async (postBody: string, config: AxiosRequestConfig = {}) => {
    console.log("getSearchResultService",postBody)
    const headers = {
        "Bookingchannel": "external", // Example header, adjust as needed
        "Correlationid": " f149d7a0-76e0-4e6b-a629-34944c2a6374",
        "Flighttype":"CAD"
        // Add more headers as needed
    };

    // Merge provided config with headers
    const requestConfig: AxiosRequestConfig = {
        ...config,
        headers: {
            ...config?.headers,
            ...headers
        }
    };
    
    let data:any=await b2cService.post(`/search/V3`,postBody, requestConfig);
    
    return data.data;
}

const getSearchResultServiceV2 = async (postBody: RequestBodyInterface, config: AxiosRequestConfig = {}) => {
    const { payload, correlationId }=  postBody
    console.log("correlationid finalsearchapii ",correlationId);
    
    // let Correlationid = " f149d7a0-76e0-4e6b-a629-34944c2a6374";
    const headers = {
        "Bookingchannel": "external", // Example header, adjust as needed
        "Correlationid": `${correlationId}`,
        "Flighttype":"CAD"
        // Add more headers as needed
    };

    // Merge provided config with headers
    const requestConfig: AxiosRequestConfig = {
        ...config,
        headers: {
            ...config?.headers,
            ...headers
        }
    };
    
    let data:any=await b2cService.post(`/search/getAllResults`,payload, requestConfig);
    
    return data.data;
}

export { getSearchResultService, getSearchResultServiceV2 };