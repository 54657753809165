import React, { useState } from 'react';
import {getAutoSuggestAPI,getMultipleAutoSuggestAPI} from "../services";
const AutoSuggestAPI = () => {
    const [autoSuggestData, setAutoSuggestData] = useState(null);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const autoSuggestAPI = async(arg:string) => {
        try {
            setLoading(true);
            setError(null);
            let autoComplete=[]
            let autoCompleteResult: { value: any; label: string; customAbbreviation: string; }[]=[]
            const res = await getAutoSuggestAPI(arg);
            let result=res?.data
            
            if(result) {       
                result.map((element) => {
                    const label = `${element.code} - ${element.name}`;    
                    const abrre = element.location;   
                    autoCompleteResult.push({value: element, label: label ,customAbbreviation:abrre});   
                  })
            }
            setAutoSuggestData(autoCompleteResult)
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    }
    return [autoSuggestAPI, autoSuggestData, loading, error];
};

const MultipleAutoSuggestAPI = () => {
    const [autoSuggestData, setAutoSuggestData] = useState(null);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const autoSuggestAPI = async(data:string[]) => {
        try {
            setLoading(true);
            setError(null);
            let autoComplete=[]
            let autoCompleteResult: { value: any; label: string; customAbbreviation: string; }[]=[]
            const res = await getMultipleAutoSuggestAPI({allAirports: data});
            let result=res?.data
            if(result && result.airports?.length) {       
                result.airports.map((element) => {
                    const label = `${element.code} - ${element.name}`;    
                    const abrre = element.location;   
                    autoCompleteResult.push({value: element, label: label ,customAbbreviation:abrre});   
                  })
            }
            setAutoSuggestData(autoCompleteResult)
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    }
    return [autoSuggestAPI, autoSuggestData, loading, error];
};

export {AutoSuggestAPI,MultipleAutoSuggestAPI};