import React from "react";
import { Box, Typography } from '@mui/material';
import TaskAltIcon from '@mui/icons-material/TaskAlt';

export default function SearchStepper({ StepperTitle, StepperFinish, StepperCount,StepperActive }: any) {
    return (<>
        <Box className="searchWidget_stepper">
            {StepperFinish ? <TaskAltIcon className="search_stpIcon"/> : <Typography className={"searchWidget_stpDeactive " + (StepperActive && "  searchWidget_stpActive")} component={'span'}>{StepperCount} </Typography>}
            <Typography className='searchWidget_stpTitle'>{StepperTitle}</Typography>
        </Box>
    </>)
}