import React from 'react';
import { Box, Grid, Skeleton } from '@mui/material';

const PriceSummaryLoader = () => {
  return (
    <Box padding={2} border={1} borderColor="divider" borderRadius={2}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box display="flex" alignItems="center">
            <Skeleton variant="circular" width={30} height={30} />
            <Skeleton variant="text" sx={{ ml: 2 }} width={100} height={30} />
          </Box>
        </Grid>
        <Grid item xs={12} display="flex">
          <Skeleton variant="text" width={120} height={30} />
          <Skeleton variant="text" width={100} height={30} sx={{ ml: 'auto' }} />
        </Grid>
        <Grid item xs={12}>
          <Skeleton variant="rectangular" width="100%" height={40} sx={{ bgcolor: 'rgba(0, 0, 0, 0.1)' }} />
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="center">
          <Skeleton variant="text" width={150} height={20} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default PriceSummaryLoader;
