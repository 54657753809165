import React, { useState, useEffect } from 'react';
import "../../styles/main.scss";
import SearchResultsList from '../SearchResults/SearchResultsList';
import SearchWidget  from '../SearchWidget/Searchwidget'

const LoginComponent = (props: any) => {
    const getInboundFilters=(data:any)=>{
        props.getInboundFilters(data)
    }
    //this console log is needed to see what data is returned from API
    
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [btnloader, setBtnloader] = useState(false);
    const [errorMessage, setErrorMessage] = useState('')
    const validation = () => {
        setBtnloader(true);
        if (username === '') {
            console.log("Please enter username.")
            setBtnloader(false);
        } else if (password === '') {
            console.log("Please enter password.")
            setBtnloader(false);
        } else {
            signIn()
        }
    }

    const setUsernameFunction = (e:any) => {
        // setUsernameError('')
        setErrorMessage("");
        setUsername(e.target.value);
    };

    const setPasswordFunction = (e:any) => {
        // setPasswordError('')
        setErrorMessage("")
        setPassword(e.target.value)
    }

    const signIn = async () => {
        let obj: any = {
            username: username,
            password: password,
            portal: "booking-flow",
        }

        let data = await props.apicall(obj)
    };

    useEffect(()=>{
        if(props.data && props.data.hasOwnProperty('accessToken')){
            console.log("login success",props.data)
        }else{
            console.log("login failed",props.data)
        }

    },[props.data])
    return (
        <div>
            <div className="tb-login-wrapper">
            <div className="tb-login-form">

                <form>
                    <div className="tb-login-input">
                        <p className="text-danger">{errorMessage} </p>
                        <label htmlFor="user">User Name</label>
                        <input
                            type="text"
                            id="user"
                            name="user_name"
                            placeholder="Enter username or userId"
                            value={username}
                            onChange={(e) => setUsernameFunction(e)}
                        />
                    </div>
                    <div className="tb-login-input">
                        <label htmlFor="password">Password</label>
                        <input
                            type="password"
                            id="password"
                            name="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPasswordFunction(e)}
                        />
                    </div>
                    <button type="button" onClick={() => validation()}>
                        Sign In
                    </button>
                </form>
            </div>
        </div>

        </div>
    );
};

export default LoginComponent;