import React, { useState } from 'react';
import { Box } from '@mui/material';
import { TravellerData } from '../BookWithPaymentComponents/PassengerDetails';
import Seat from './Seat';
import { getColMapping } from './helper';
import { SeatColumn } from './FirstRow';

interface SeatRowsProps {
  rowsRange: number[];
  columns: SeatColumn[];
  seats: Array<any>;
  travellers: Array<TravellerData>;
  selectedSeats: any;
  onClickSeat: (letter: string, row: number, travellerId: string) => void;
}

const SeatRows = ({ rowsRange, columns, seats, onClickSeat, selectedSeats, travellers }: SeatRowsProps) => {
  const colMapping = getColMapping(columns);
  
  const [selectedTravellerId, setSelectedTravellerId] = useState<string | null>(null); 
  const [isSwapping, setIsSwapping] = useState(false); 

  const handleTravellerSelect = (travellerId: string) => {
    setSelectedTravellerId(travellerId); 
    setIsSwapping(true); 
  };

  const handleSeatClick = (letter: string, row: number, travellerId: string, isSwapping: boolean) => {
    onClickSeat(letter, row, selectedTravellerId ?? travellerId);
    setIsSwapping(false); 
    setSelectedTravellerId(null); 
  };

  return (
    <>
      {rowsRange.map((rowNumber) => (
        <Box key={rowNumber} className="seat_row">
          <Box className="seat_Number">{rowNumber}</Box>

          {colMapping.map((col, seatIndex) => {
            const seatData = seats.find(
              (seat) => seat.rowNumber === rowNumber && seat.columnCharacter === col.character
            );

            const isSeatDisabled = () => {
              if (selectedSeats.length === travellers.length) { true }
              if (travellerId) {
                  return false;
              }
              if (seatData?.characteristics.includes('NoSeat')) {
                  return true;
              }
              return !isAvailable;
            };
            const isAvailable = seatData?.isAvailable ?? false;
            const seatId = `${rowNumber}${col.character}`;
            const selectedSeat = selectedSeats.find((seat: any) => seat.seatId === seatId);
            const travellerId = selectedSeat ? selectedSeat.travellerId : null;

            if (seatData) {
              return (
                <Seat
                  key={seatId}
                  seatData={seatData}
                  letter={col.character}
                  row={rowNumber}
                  isSeatDisabled={isSeatDisabled()}
                  isAvailable={isAvailable}
                  onClickSeat={handleSeatClick}
                  travellerId={travellerId}
                  isSwapping={isSwapping}
                />
              );
            } else {
              return <Box key={seatIndex} className='seats seats_Space'></Box>;
            }
          })}
        </Box>
      ))}
    </>
  );
};

export default SeatRows;
