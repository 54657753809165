import React, { useState } from 'react';
import { getQuoteService, bookQuoteService } from "../services";

const GetQuoteAPI = () => {
    const [quotesData, setQuotesData] = useState(null);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const getQuoteAPI = async (payload:string,correlationId:string) => {
        try {
            setLoading(true);
            setError(null);
            const quoteData = await getQuoteService({payload,correlationId});
            setQuotesData(quoteData);
        } catch (error) {
            console.log("error",error)
            setError(error);
        } finally {
            setLoading(false);
        }
    }
    return [getQuoteAPI, quotesData, loading, error];
};

const BookQuoteAPI = () => {
    const [bookQuotesData, setBookQuotesData] = useState(null);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const bookQuoteAPI = async (payload:string,correlationId:string) => {
        try {
            setLoading(true);
            setError(null);
            const bookQuoteData = await bookQuoteService({payload,correlationId});
            setBookQuotesData(bookQuoteData);
        } catch (error) {
            console.log("error",error)
            setError(error);
        } finally {
            setLoading(false);
        }
    }
    return [bookQuoteAPI, bookQuotesData, loading, error];
};
export { GetQuoteAPI , BookQuoteAPI};
