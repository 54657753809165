import React from 'react';
import FlightLandIcon from '@mui/icons-material/FlightLand';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
 

const LogosAnimation = () => {
  const icons = [
    <FlightLandIcon style={{ fontSize: 80 }} />,
    <FlightTakeoffIcon style={{ fontSize: 80 }} />,
    <FlightLandIcon style={{ fontSize: 80 }} />,
    <FlightTakeoffIcon style={{ fontSize: 80 }} />,
  ];
  const imgLog= [
   "https://travel-img-assets.s3.us-west-2.amazonaws.com/flights/carrier-48x48/ac.png",
   "https://travel-img-assets.s3.us-west-2.amazonaws.com/flights/carrier-48x48/dl.png",
   "https://travel-img-assets.s3.us-west-2.amazonaws.com/flights/carrier-48x48/ws.png",
   "https://travel-img-assets.s3.us-west-2.amazonaws.com/flights/carrier-48x48/ua.png",
   "https://travel-img-assets.s3.us-west-2.amazonaws.com/flights/carrier-48x48/ts.png",
   "https://travel-img-assets.s3.us-west-2.amazonaws.com/flights/carrier-48x48/aa.png",
   "https://travel-img-assets.s3.us-west-2.amazonaws.com/flights/carrier-48x48/af.png",
   "https://travel-img-assets.s3.us-west-2.amazonaws.com/flights/carrier-48x48/de.png",
   "https://travel-img-assets.s3.us-west-2.amazonaws.com/flights/carrier-48x48/tp.png",
   "https://travel-img-assets.s3.us-west-2.amazonaws.com/flights/carrier-48x48/lx.png",
   "https://travel-img-assets.s3.us-west-2.amazonaws.com/flights/carrier-48x48/os.png",
   "https://travel-img-assets.s3.us-west-2.amazonaws.com/flights/carrier-48x48/ac.png",
   "https://travel-img-assets.s3.us-west-2.amazonaws.com/flights/carrier-48x48/dl.png",
   "https://travel-img-assets.s3.us-west-2.amazonaws.com/flights/carrier-48x48/ws.png",
   "https://travel-img-assets.s3.us-west-2.amazonaws.com/flights/carrier-48x48/ua.png",
   "https://travel-img-assets.s3.us-west-2.amazonaws.com/flights/carrier-48x48/ts.png",
   "https://travel-img-assets.s3.us-west-2.amazonaws.com/flights/carrier-48x48/aa.png",
   "https://travel-img-assets.s3.us-west-2.amazonaws.com/flights/carrier-48x48/af.png",
   "https://travel-img-assets.s3.us-west-2.amazonaws.com/flights/carrier-48x48/de.png",
   "https://travel-img-assets.s3.us-west-2.amazonaws.com/flights/carrier-48x48/tp.png",
   "https://travel-img-assets.s3.us-west-2.amazonaws.com/flights/carrier-48x48/lx.png",
   "https://travel-img-assets.s3.us-west-2.amazonaws.com/flights/carrier-48x48/os.png",
   "https://travel-img-assets.s3.us-west-2.amazonaws.com/flights/carrier-48x48/ei.png",
   "https://travel-img-assets.s3.us-west-2.amazonaws.com/flights/carrier-48x48/ws.png",
   "https://travel-img-assets.s3.us-west-2.amazonaws.com/flights/carrier-48x48/ua.png",
   "https://travel-img-assets.s3.us-west-2.amazonaws.com/flights/carrier-48x48/ts.png",
   "https://travel-img-assets.s3.us-west-2.amazonaws.com/flights/carrier-48x48/aa.png",
   "https://travel-img-assets.s3.us-west-2.amazonaws.com/flights/carrier-48x48/af.png",
   "https://travel-img-assets.s3.us-west-2.amazonaws.com/flights/carrier-48x48/de.png",
   "https://travel-img-assets.s3.us-west-2.amazonaws.com/flights/carrier-48x48/tp.png",
   "https://travel-img-assets.s3.us-west-2.amazonaws.com/flights/carrier-48x48/fi.png",
   "https://travel-img-assets.s3.us-west-2.amazonaws.com/flights/carrier-48x48/os.png",
   "https://travel-img-assets.s3.us-west-2.amazonaws.com/flights/carrier-48x48/ei.png",
   "https://travel-img-assets.s3.us-west-2.amazonaws.com/flights/carrier-48x48/ws.png",
   "https://travel-img-assets.s3.us-west-2.amazonaws.com/flights/carrier-48x48/ua.png",
   "https://travel-img-assets.s3.us-west-2.amazonaws.com/flights/carrier-48x48/ts.png",
   "https://travel-img-assets.s3.us-west-2.amazonaws.com/flights/carrier-48x48/aa.png",
   "https://travel-img-assets.s3.us-west-2.amazonaws.com/flights/carrier-48x48/af.png",
   "https://travel-img-assets.s3.us-west-2.amazonaws.com/flights/carrier-48x48/de.png",
   "https://travel-img-assets.s3.us-west-2.amazonaws.com/flights/carrier-48x48/tp.png",
   "https://travel-img-assets.s3.us-west-2.amazonaws.com/flights/carrier-48x48/fi.png",

  ];

  return (
    <div className="Loader_iconsWrapper">
      {/* {icons.map((icon, index) => (
        <div
          key={index}
          className="icon"
          style={{ animationDelay: `${index * 3}s` }} // Stagger the start time for each icon
        >
          {icon}
        </div>
      ))} */}
       {imgLog.map((icon, index) => (
        <div
          key={index}
          className="icon"
          // style={{ animationDelay: `${ 0.1}s` }} // Stagger the start time for each icon
        >
       <img src={icon} width={'50px'}/>
        </div>
      ))}
    </div>
  );
};





export default LogosAnimation;
