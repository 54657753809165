import React from 'react'
import "../../styles/pages/_Uplift.scss";
function ShowUpLift(props:any) {
   const t = props.t;
  return (
    <div className='btn-unstyled uplift-click search_upliftSection'  >
                <div className=" pb-1" 
                    data-up-price-value={props.upliftfare} 
                    data-up-price-type='total' 
                    data-up-price-model='total' 
                    data-up-taxes-included='true'
                    
                >
                    <span className="up-from-or-text">{t('or from')}</span>
                    <div className="search_upliftDetails">
                        <div className="search_uplifInof">
                            <span className="monthly-price up-from-currency" data-up-from-currency="">$</span>
                            <span className="monthly-price" data-up-from-currency-unit-major=""></span>
                            <span className="  up-from-per-month">/mon</span>
                        </div>
                        <svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="InfoIcon" aria-label="Ranked based on price and convenience" data-mui-internal-clone-element="true"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2m1 15h-2v-6h2zm0-8h-2V7h2z"></path></svg>
                    </div>
                    <span data-up-promo="zero-percent" className="upliftStyle">
                    {t('Interest-free payments also available')}
                    </span>
                </div>
            </div>
  )
}

export default ShowUpLift
