import React from 'react'
import { Skeleton, Box } from '@mui/material';
// @ts-ignore
import iconTravelPath from '../../assets/icons/icon-travel-flight-path.svg';
import { Margin } from '@mui/icons-material';

const FlightDetailsCardSkeleton = () => {
    return (
        <Box className="flightDet_card">
            <Box className="flightDet_cardHeader">
                <Skeleton variant="text" width={200} height={40} />
                <Box sx={{display:'flex'}}>
                    <Skeleton variant="text" width={150} height={30} sx={{margin:'0 5px'}} />
                    <Skeleton variant="text" width={100} height={30} />
                </Box>
            </Box>
            {[1, 2].map((_, idx) => (
                <Box key={idx} className="flightDet_cardBody">
                    <Box className="flight_IconSection">
                        <Skeleton variant="circular" width={20} height={20} sx={{marginRight:'10px'}}/>
                        <Skeleton variant="text" width={300} height={30} />
                    </Box>
                    <Box className="flightDetSection">
                        <Box className="flightDet_Flightdata flightSectionLeft">
                            <Box className="flightDet_dateTime">
                                <Skeleton variant="text" width={150} height={20} />
                                <Skeleton variant="text" width={100} height={20} />
                            </Box>
                            <Box className="flightDet_Flights">
                                <Skeleton variant="text" width={200} height={20} sx={{marginRight:'5px'}}/>
                                <Skeleton variant="text" width={150} height={20} />
                            </Box>
                            <Skeleton variant="text" width={250} height={20} />
                        </Box>
                        <Box className="flightDet_timeInfo">
                            <Skeleton variant="rectangular" width={30} height={10} style={{margin:"0 auto"}} />
                            <img src={iconTravelPath} className='flightDet_Icon'  />
                            <Skeleton variant="rectangular" width={30} height={10} style={{margin:"0 auto"}} />
                        </Box>
                        <Box className="flightDet_Flightdata flightSectionRight">
                            <Box className="flightDet_dateTime">
                                <Skeleton variant="text" width={150} height={20} />
                                <Skeleton variant="text" width={100} height={20} />
                            </Box>
                            <Box className="flightDet_Flights">
                                <Skeleton variant="text" width={150} height={20} sx={{marginRight:'5px'}} />
                                <Skeleton variant="text" width={200} height={20} />
                            </Box>
                        </Box>
                    </Box>
                    <Box className='filght_layover'>
                        <Box className='filght_Title'>
                            <Skeleton variant="circular" width={20} height={20} sx={{marginRight:'10px'}}/>
                            <Skeleton variant="text" width={100} height={20}  sx={{margin:'0 5px'}}/>
                            <Skeleton variant="text" width={50} height={20} />
                        </Box>
                        <Box className="flight_border">
                        </Box>
                    </Box>
                </Box>
            ))}
        </Box>
    );
};


export default FlightDetailsCardSkeleton