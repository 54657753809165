import { SeatColumn } from "./FirstRow";
import { SegmentFlight } from "./SeatMap";

export const getColMapping = (columns: SeatColumn[] = []) => {
const result = [];

  for (let i = 0; i < columns.length; i++) {
    result.push(columns[i]);
    
    // Check if the current item and the next item are both Aisles
    if (columns[i].type === "Aisle" && columns[i + 1]?.type === "Aisle") {
      result.push({ "character": "", "type": "Gap" });  // Add gap after the current aisle
    }
  }

  return result;
}

export const getFlightsFromSegments = (segments: object[]) => {
  const flights: SegmentFlight[] = [];
  segments.forEach((item: any) => {
      item.flights?.forEach((flight: SegmentFlight) => {
          flights.push(flight);
      });
  });
  return flights;
};