import React from "react";
import { Box, Typography } from '@mui/material';
// import TaskAltIcon from '@mui/icons-material/TaskAlt';
// @ts-ignore
import TaskAltIcon from '../../assets/icons/Icon_DoneTrick.svg';

export default function SearchStepper({ StepperTitle, StepperFinish, StepperCount,StepperActive }: any) {
    return (<>
        <Box className="searchWidget_stepper">
            {StepperFinish ? <img  src={TaskAltIcon} className="search_stpIcon"/> : <Typography className={"searchWidget_stpDeactive " + (StepperActive && "  searchWidget_stpActive")} component={'h3'}>{StepperCount} </Typography>}
            <Typography className='searchWidget_stpTitle' variant="h3">{StepperTitle}</Typography>
        </Box>
    </>)
}