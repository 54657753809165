import { AxiosRequestConfig, AxiosResponse } from "axios";
import { relayService } from "../axiosServices";

interface RequestBodyInterface{
    payload:String;
    correlationId:String;
}

const applyPromoService = async (postBody: RequestBodyInterface, config: AxiosRequestConfig = {}) => {
    const { payload, correlationId }=postBody
    const headers = {
        "Bookingchannel": "external", // Example header, adjust as needed
        "Correlationid": `${correlationId}`, //" f149d7a0-76e0-4e6b-a629-34944c2a6374",
        "Flighttype":"CAD"
        // Add more headers as needed
    };

    // Merge provided config with headers
    const requestConfig: AxiosRequestConfig = {
        ...config,
        headers: {
            ...config?.headers,
            ...headers
        }
    };
    //let data:any=await relayService.post(`/?forwardUrl=${process.env.INSURANCE_API}/bookings/`,payload, requestConfig);
    //return data.data;
    console.log("payload promo",payload)
    let response = {
        "AMAZON20": {
            discount: 20,
            rules: {
                startday: '2024-09-25',
                enddate: '2026-10-14',
                travelStartDate: '2024-09-25',
                travelEndDate: '2026-08-31',
            }
        },
        "SUMMER50": {
            discount: 50,
            rules: {
                startday: '2024-07-01',
                enddate: '2026-08-31',
                travelStartDate: '2024-07-01',
                travelEndDate: '2026-12-31',
            }
        }// Add more coupons here
    }
    return response;
}

export { applyPromoService};