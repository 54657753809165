import React, { forwardRef } from "react";
export interface SEARCHFORMINTERFACE {
    component: string;
    props: {
        class: any;
        disablePortal: any;
        id: any;
        options: any;
        onChangeFunction: any;
        label: any;
        inputValue: any;
        onInputChange: any;
        isMulticity?: any;
    }
}

export const CREATE_ELEMENT = (data:any) => {
    const { component, gridOptions, props, children = null } = data;
    
    // Ensure props is an object and contains the necessary properties
    if (!props || typeof props !== 'object' || !props.name) {
        console.error("Invalid props object or missing 'name' property.");
        return null;
    }
    let CustomComponent = component;

    return (
            <CustomComponent {...props}>
                {children}
            </CustomComponent>
    );
};