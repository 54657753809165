import React, { useState } from 'react';
import {getBookWithPaymentService} from "../services";
const BookWithPaymentApi = () => {
    const [paymentData, setPaymentData] = useState(null);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const bookWithPaymentApi = async(payload: any,token:string,correlationId:string) => {
        try {
            setLoading(true);
            setError(null);
            const res = await getBookWithPaymentService({payload,token,correlationId});
            setPaymentData(res)
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    }
    return [bookWithPaymentApi, paymentData, loading, error];
};

export {BookWithPaymentApi};