import React from "react";
import { Dialog, DialogTitle, DialogContent, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AirplanemodeActiveIcon from "@mui/icons-material/AirplanemodeActive";

interface TermsModalProps {
  open: boolean;
  onClose: () => void;
  rules: FareRulesInterface[];
}

interface FareRulesInterface {
  fareBasisCode: string;
  fareDirection: string;
  carrier: string;
  departureAirport: string;
  arrivalAirport: string;
  departureDateTime: string;
  FareRuleDetails: FareRuleDetail[];
}

interface FareRuleDetail {
  title: string;
  text: string;
}

const TermsModal: React.FC<TermsModalProps> = ({ open, onClose, rules }) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <div className="Booking_TermsCondHedaer">
        <div className="Booking_TermsCondLeft">
          <AirplanemodeActiveIcon className="Booking_TermsCondIcon"/>
          <span className="Booking_TermsCondTitle">Supplier's Terms & Conditions</span>
        </div>
        <IconButton onClick={onClose} className="Booking_TermsCondClose">
          <CloseIcon />
        </IconButton>
      </div>
      <DialogContent dividers className="Booking_TermsCondBody">
        {rules?.map((rule, ruleIndex) => (
          <div key={ruleIndex} className="mb-6">
            <h2 className="Booking_TermsCondBodyRule">{`Fare Rule (${rule.carrier} - ${rule.fareBasisCode})`}</h2>
            {rule?.FareRuleDetails?.map((fare, index) => (
              <div key={index} className="mb-4">
                <h3 className="Booking_TermsCondBodySubTitle">{`${index.toString().padStart(2, "0")}. ${fare.title}`}</h3>
                <p className="Booking_TermsCondBodyInfo">{fare.text}</p>
              </div>
            ))}
          </div>
        ))}
      </DialogContent>


    </Dialog>
  );
};

export default TermsModal;
