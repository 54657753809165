import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ACTIONS } from "../constants/actions";

export interface SearchResultState {
    searchResults: any[];
    searchIsLoading: boolean;
    tripType: String;
    searchRequest: any[];
    correlationId: String;
    searchClicked: boolean;
}

const initialState: SearchResultState = {
    searchResults: [],
    searchIsLoading: false,
    tripType: "return",
    searchRequest: [],
    correlationId: "",
    searchClicked: false,
}

const searchResultsSlice = createSlice({
    name: "searchResults",
    initialState,
    reducers: {
        [`${ACTIONS.UPDATE_SEARCH_RESULTS}`]: (state, action: PayloadAction<any[]>) => {
            return {...state, searchResults: action.payload}
        },
        [`${ACTIONS.SEARCH_IS_LOADING}`]: (state, action: PayloadAction<any>) => {
            return {...state, searchIsLoading: action.payload}
        },
        [`${ACTIONS.TRIP_TYPE}`]: (state, action: PayloadAction<any>) => {
            return {...state, tripType: action.payload}
        },
        [`${ACTIONS.SEARCH_REQUEST}`]: (state, action: PayloadAction<any>) => {
            return {...state, searchRequest: action.payload}
        },
        [`${ACTIONS.CORRELATIONID}`]: (state, action: PayloadAction<any>) => {
            return {...state, correlationId: action.payload}
        },
        [`${ACTIONS.SEARCHRESULTHIT}`]: (state, action: PayloadAction<any>) => {
            return { ...state, searchClicked: action.payload }
        },
    }
});

export const { UPDATE_SEARCH_RESULTS, SEARCH_IS_LOADING, TRIP_TYPE, SEARCH_REQUEST } = searchResultsSlice.actions;
export default searchResultsSlice.reducer;
