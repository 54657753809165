import React from 'react'
import {Typography, Box, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { SvgIcon } from '@mui/material';
import { useTranslation } from 'react-i18next';

const FilterCheckBox = ({onChangeFunction, CheckBoxlable, CheckBoxValue,TripInfoData, icon,name, checkValue, handleSelectOnly,showDollarSign }: any) => {
    const { t } = useTranslation();
    const CustomIcon = icon;
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const timeoutId = setTimeout(function() {   
            window.Uplift.Payments.load(TripInfoData, function () {
                clearTimeout(timeoutId);
            });
        }, 100);
        onChangeFunction(e,name);
    };

    const handleSpanClick =()=>{    
        handleSelectOnly()
    }

    return (
        <>
            <Box className="searchRes_filterCheck">
                <FormGroup>
                    <FormControlLabel control={<Checkbox name={name} onChange={handleChange} checked={checkValue}/>} label={<React.Fragment>
                        {CustomIcon && <SvgIcon component={CustomIcon} ></SvgIcon>}
                        {CheckBoxlable}
                    </React.Fragment>} />
                </FormGroup>
                {<span className="hover-only" onClick={()=>handleSpanClick()}>{t('Only')}</span>}
                <Typography variant='h3'>{showDollarSign && <>$</>}{CheckBoxValue}</Typography>
            </Box>
        </>
    )
}


export default FilterCheckBox;