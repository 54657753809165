import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ACTIONS } from "../constants/actions";

export interface tripInfoResultState {
    uplift: {};
}

const initialState: tripInfoResultState = {
    uplift: {}
}

const tripInfoResult = createSlice({
    name: "tripInfoResult",
    initialState,
    reducers: {
        [`${ACTIONS.UPLIFT_RESULT}`]: (state, action: PayloadAction<any[]>) => {
            return {...state, uplift: action.payload}
        }
    }
});

export const { UPLIFT_RESULT } = tripInfoResult.actions;
export default tripInfoResult.reducer;
