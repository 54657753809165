import { SelectedSeats } from "../../BookWithPayment/Booking";
import { SegmentFlight } from "../SeatMap/SeatMap";
// import { TravellerData } from "./PassengerDetails";

export const getFlightsWithSelectedSeats = (flights: SegmentFlight[], selectedSeatsData: SelectedSeats): object[] => {
    return flights.map((flight) => {
        const selectedSeats = selectedSeatsData[flight.id]?.selectedSeats;
        return {
            ...flight,
            selectedSeats
        }
    })
}
