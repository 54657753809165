import React, { useState } from 'react';
import { Container, Grid, Paper, Typography, Button, Box, Link, FormControlLabel, Checkbox } from '@mui/material';
import {INSURANCEDETAILCONFIG} from '../../../utils/constant';
import { useTranslation } from 'react-i18next';

interface InsuranceDetailProps {
    insCode: string; 
    url: string;
    item: any;
}

const InsuranceDetail:React.FC<InsuranceDetailProps>  = ({ insCode, url, item }) => {
  const { t } = useTranslation();
  const [collapseToggle, setCollapseToggle] = useState(false);
  const insDetailConfig = INSURANCEDETAILCONFIG;

  const [isVisible, setIsVisible] = useState(false);

  const handleToggle = () => {
    setIsVisible(!isVisible);
  };
  const config = insDetailConfig['redtag']['en'];
  const insDetail = item.details || null;


  return insDetail ? (
    <div className="insurance-check-list">
      <Box>
        <Box className="d-flex py-2">
        <Typography className='InsuranceDetail_point'>{insDetail[0]}</Typography>
        </Box>
        {isVisible && ( 
          <React.Fragment>
            {insDetail.map((content: string, i: number) => {
              return i > 0 ? (
                <Box key={`${insCode}-opt-${i}`} className="d-flex py-2 mb-2 border-top ">
                  <Typography className='InsuranceDetail_point'>{content}</Typography>
                </Box>
              ) : null;
            })}
            <Box key={`${insCode}-opt-end`} className="InsuranceDetail_amounts">
              <Typography className="InsuranceDetail_AmuClick"> {t('For Coverage amounts click')} </Typography> <a href="#" className="InsuranceDetail_clickhere"> {t('here')}</a>
            </Box>
          </React.Fragment>
        )}
      </Box>
      <Button  className="InsuranceDetailBtn" onClick={handleToggle}>
        {isVisible  ? t('Show Less') : t('Show More')}
      </Button >
    </div>
  ) : null;
};

export default InsuranceDetail;
