import {SegmentData, Value,Insurance } from './interfaces';
import moment from "moment";
export const translateSegmentsData = (segments: Value[] = []): SegmentData[] => {
    let itinerary: SegmentData[] = [];

    if (segments.length > 0) {
        segments.forEach((value) => {
            value.flights.forEach((flightsdata) => {
                itinerary.push({
                    departure_apc: flightsdata.departureAirport,
                    arrival_apc: flightsdata.arrivalAirport,
                    departure_time: formatDate(flightsdata.departureDateTime),
                    arrival_time: formatDate(flightsdata.arrivalDateTime),
                    fare_class: '',
                    carrier_code: flightsdata.carrier,
                    flight_number: flightsdata.number
                });
            });
        });
    }

    return itinerary;
}

const formatDate = (dateString: string) => {
    return moment(dateString).format('YYYYMMDD');
};

export const formatDOB = (dateString: any) => {
    const monthNames = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN",
        "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];

    const [day,monthStr,year] = dateString.split('/');

    const monthIndex = monthNames.indexOf(monthStr.toUpperCase());
    const month = (monthIndex + 1).toString().padStart(2, '0');

    return `${month}/${day}/${year}`;
}

export const calculateTotalForPassenger = (passengerFare: any) => {
    const baseAmount = passengerFare?.sellingBaseAmount?.amount ? passengerFare.sellingBaseAmount.amount : passengerFare?.fareBreakDown?.sellingBaseAmount?.amount;
    const taxesAndFees = passengerFare.totalTaxes.amount;
    return baseAmount + taxesAndFees;
};

export const getInsuranceData = (newQuoteRequest: any): Insurance[] => {
    const insurance_data: Insurance[] = [];  
    
    newQuoteRequest.paxInsArray.map((value: Insurance, key: number) => {
        if (value.insCode !== 'DECLINED') {
            const temp_insData: Insurance = {
                id: key,
                amount: value.amount, 
                insCode: value.insCode,
                types: [value.insCode === 'GEMU' ? 'travel' : 'cancellation']
            };
            insurance_data.push(temp_insData);
        }
    });

    return insurance_data; 
};