import React, { useState } from 'react';
import {getSignInAPI} from "../services";
const signIn = () => {
    const [signInData, setSignInData] = useState(null);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const signIn = async(arg:object) => {
        console.log("this is called")
        try {
            setLoading(true);
            setError(null);
            const res = await getSignInAPI(arg);
            let result=res?.data
            setSignInData(result)
            return result;
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    }
    return [signIn, signInData, loading, error];
};

export {signIn};