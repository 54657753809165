type Flight = {
    id: string;
    number: string;
    operatingFlightNumber: string;
    carrier: string;
    carrierName: string;
    operatingCarrier: string;
    operatingCarrierName: string;
    departureAirport: string;
    departureAirportCity: string;
    arrivalAirport: string;
    arrivalAirportCity: string;
    arrivalDateTime: string;
    departureDateTime: string;
    aircraftType: string;
    fareBasisCode: string;
};

type Segment = {
    id: string;
    flights: Flight[];
    cabin: { type: string; flightIds: string[] }[];
    brand: { code: string; brandName: string; flightIds: string[] }[];
    layovers: { airport: string; airportCity: string }[];
    freeBaggageAllowance: { quantity: string; unit: string; flightIds: string[] }[];
    carryOnBaggageAllowance: { quantity: string; unit: string; flightIds: string[] }[];
    chargedBaggageAllowance: any[];
};

type ConvertedSegment = {
    [key: string]: Segment;
};

export class SegmentConverter {
    private segments: Segment[];

    constructor(segments: Segment[]) {
        this.segments = segments;
    }

    convertSegments(): { segments: ConvertedSegment[] } {
        return {
            segments: this.segments.map((segment) => {
                // Use the first and last flight's airports to create the dynamic key
                const firstFlight = segment.flights[0];
                const lastFlight = segment.flights[segment.flights.length - 1];
                const dynamicKey = `${firstFlight.departureAirport}-${lastFlight.arrivalAirport}`;

                return {
                    [dynamicKey]: segment
                };
            })
        };
    }
}
