import {AxiosRequestConfig, AxiosResponse} from "axios";
import {staticService} from "../axiosServices";

const getAutoSuggestAPI = async(postBody: string,config: AxiosRequestConfig = {}): Promise<AxiosResponse<any>> => {
    return await staticService.get(`/searchCityAirport/v2/${postBody}`, config,);
}

const getMultipleAutoSuggestAPI = async(postBody: object,config: AxiosRequestConfig = {}): Promise<AxiosResponse<any>> => {
    return await staticService.post(`/searchCityAirport/processAllAirports`,postBody, config,);
}

export {getAutoSuggestAPI,getMultipleAutoSuggestAPI};