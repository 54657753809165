import React, { ElementType, useEffect, useState } from 'react'
import Tooltip from '@mui/material/Tooltip';

interface TooltipInterface {
    text?: string;
    arrow?: boolean;
    tooltipClass?: string;
    Icon?: ElementType<any>;
}

const ArrowTooltips = ({ text = "", arrow = true, tooltipClass = "", Icon = null }: TooltipInterface) => {
    return (
        <Tooltip title={text} arrow={arrow} classes={{ popper: tooltipClass }} >
            {Icon && <Icon />}
        </Tooltip>
    );
}

export default ArrowTooltips;