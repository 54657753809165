import React, { ReactNode } from "react";
import { IconButton } from '@mui/material';
import {  DEFAULT_FUNCTION } from '../../utils/constant';

interface CustomIconButtonProps {
  onClick?: () => void;
  icon: ReactNode;
  className?: string;
}

const CustomIconButton: React.FC<CustomIconButtonProps> = ({ onClick = DEFAULT_FUNCTION, icon = '', className = '' }) => {
  return (
    <IconButton className={className} onClick={onClick}>
      {icon}
    </IconButton>
  );
};

export default CustomIconButton;
