import { AxiosRequestConfig, AxiosResponse } from "axios";
import { relayService } from "../axiosServices";

interface RequestBodyInterface{
    payload:String;
    correlationId:String;
}

const getQuoteService = async (postBody: RequestBodyInterface, config: AxiosRequestConfig = {}) => {
    const { payload, correlationId }=postBody
    const headers = {
        "Bookingchannel": "external", // Example header, adjust as needed
        "Correlationid": `${correlationId}`, //" f149d7a0-76e0-4e6b-a629-34944c2a6374",
        "Flighttype":"CAD"
        // Add more headers as needed
    };

    // Merge provided config with headers
    const requestConfig: AxiosRequestConfig = {
        ...config,
        headers: {
            ...config?.headers,
            ...headers
        }
    };
    let data:any=await relayService.post(`?forwardUrl=${process.env.INSURANCE_API}/quotes/`,payload, requestConfig);
    return data.data;
}

const bookQuoteService = async (postBody: RequestBodyInterface, config: AxiosRequestConfig = {}) => {
    const { payload, correlationId }=postBody
    const headers = {
        "Bookingchannel": "external", // Example header, adjust as needed
        "Correlationid": `${correlationId}`, //" f149d7a0-76e0-4e6b-a629-34944c2a6374",
        "Flighttype":"CAD"
        // Add more headers as needed
    };

    // Merge provided config with headers
    const requestConfig: AxiosRequestConfig = {
        ...config,
        headers: {
            ...config?.headers,
            ...headers
        }
    };
    let data:any=await relayService.post(`/?forwardUrl=${process.env.INSURANCE_API}/bookings/`,payload, requestConfig);
    return data.data;
}

export { getQuoteService, bookQuoteService };