import moment from "moment";
import { TRIPTYPE } from "./constant";

export const calculateAgeAtDeparture = (dob: string, departureDate: string) => {
    const dobDate = moment(dob, 'DDMMMYYYY'); // Assuming input format 'DDMMMYYYY'
    const departure = moment(departureDate);
    return departure.diff(dobDate, 'years');
};

export const emailValidation = (email: string) => {
    if(!email?.trim()){
        return false;
    }
    let emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (emailRegex.test(email)) {
        return true
    }
    return false;
  };

  export const processFromToDate = (tripType: string, legs: any) => {
    const advancedForm: any = [];
    let fromTo: any = {};

    // Normalize tripType to uppercase for consistent comparison
    const normalizedTripType = tripType.toUpperCase();
    // Common structure for the first leg
    if (legs.length > 0) {
        console.log(legs, legs[0])
        fromTo = {
            flyingFrom: legs[0].from,
            flyingTo: legs[0].to,
            fromDate: legs[0].date,
            fromLabel: legs[0].fromLabel,
            toLabel: legs[0].toLabel
        };
    }else{
        return;
    }

    switch (normalizedTripType) {
        case TRIPTYPE.ROUNDTRIP.toUpperCase():
        case "ROUNDTRIP":
            fromTo["toDate"] = legs[1]?.date;
            break;
        
        case TRIPTYPE.ONEWAY.toUpperCase():
            break;

        case TRIPTYPE.MULTICITY.toUpperCase():
            legs.slice(1).forEach((leg: any, i: number) => {
                advancedForm.push({
                    departingFromCode: leg.from,
                    goingToCode: leg.to,
                    dateRange: leg.date,
                    fromLabel: leg.fromLabel,
                    toLabel: leg.toLabel
                });
            });
            break;

        default:
            throw new Error('Invalid trip type');
    }

    return { fromTo, advancedForm };
};

export const calculateTotalForPassenger = (passengerFare: any) => {
    const { bookingFee, ticketingFee, passengerDetails, taxes, fareBreakDown} = passengerFare;
    // let totalAmount = passengerFare?.sellingBaseAmount?.amount ? passengerFare.sellingBaseAmount.amount: passengerFare?.fareBreakDown?.sellingBaseAmount?.amount;
       let totalAmount = passengerFare?.sellingBaseAmount?.amount  ? passengerFare.sellingBaseAmount.amount: passengerFare?.fareBreakDown?.sellingBaseAmount?.amount || 0;
    const bFee  = bookingFee?.amount || fareBreakDown?.bookingFee?.amount || 0;
    const tFee  = ticketingFee?.amount || fareBreakDown?.ticketingFee?.amount || 0;
    const taxesAndFees = (passengerFare.totalTaxes.amount + bFee + tFee) * passengerDetails.quantity;
    totalAmount = (totalAmount) * passengerDetails.quantity;
    return totalAmount + taxesAndFees;
  };

export const formatDate = (dateTime: string) => {
    return moment(dateTime).format('ddd. MMM D, YYYY');
};

export const formatTime = (dateTime: string) => {
    const date = new Date(dateTime);
    return `${date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;
};

export function isFutureDate(dayOfBirth: string, monthOfBirth: string, yearOfBirth: string): boolean {
    let dateOfBirthString: string;
    if (/^\d{2}$/.test(monthOfBirth)) {
        dateOfBirthString = `${dayOfBirth}/${monthOfBirth}/${yearOfBirth}`;
    } else {
        dateOfBirthString = `${dayOfBirth}-${monthOfBirth}-${yearOfBirth}`;
    }
    const dateOfBirth = moment(dateOfBirthString, ['DD/MM/YYYY', 'DD-MMM-YYYY']);
    const today = moment();
    return dateOfBirth.isAfter(today);
  }

export function dateChangeAndDaysCount(dateTime1: any, dateTime2: any) {
    const date1 = moment(dateTime1).startOf('day');
    const date2 = moment(dateTime2).startOf('day');
    const dateChanged = date1.isSame(date2) ? false : true;    
    const daysDifference = date2.diff(date1, 'days');
    return daysDifference;
}

export const debounce = (func: Function, delay: number) => {
  let timeout: NodeJS.Timeout;
  return (...args: any[]) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      func.apply(null, args);
    }, delay);
  };
};
