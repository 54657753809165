import React from 'react'
import { getColMapping } from './helper';
import { Box, Typography } from '@mui/material';


export interface SeatColumn {
  character: string;
  type: string;
}

interface SeatRowProps {
  columns: SeatColumn[];
}

const FirstSeatRow = ({ columns }: SeatRowProps) => {

  const colMapping = getColMapping(columns);
  return (
    <Box className="seat_row seats_Contentword">
      <Box className="seat_Number"></Box>
      {colMapping.map((col, index) => {
        if (col.type !== 'Gap') {
          return (
            <Typography key={index} className="seats seat_letter">
              {col.character}
            </Typography>
          );
        } else {
          return <Typography key={index} className="seats seat_Space" />
        }
      })}
    </Box>
  );
};

export default FirstSeatRow;
