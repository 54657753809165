import Moment from 'moment';
export function findMinMaxValues(arrayOfObjects, key) {

    let minimumValue = arrayOfObjects[0][key];
    let maximumValue = arrayOfObjects[0][key];

    arrayOfObjects.forEach(obj => {
        if (obj[key] < minimumValue) {
            minimumValue = obj[key];
        }
        if (obj[key] > maximumValue) {
            maximumValue = obj[key];
        }
    });
    return {
        minimumValue,
        maximumValue
    }
}

export const noOfStops = (arrayOfObjects, key) => {
    let obj = {};

    arrayOfObjects.forEach(({ outbound, ...item }) => {
        let customKey = outbound?.layovers?.length === 1 ? "1 Stop" : outbound?.layovers?.length > 1 ? "2+ Stop" : "NonStop";
        if (obj[customKey]) {
            obj[customKey].push(item[key])
        } else {
            obj[customKey] = [item[key]]
        }

    })

    return obj;

}



export const minValue = (arrayOfObjects, key) => {
    if(key){
        return arrayOfObjects.reduce((min, obj) => obj[key] < min ? obj[key] : min, arrayOfObjects[0][key]);
    }
    return arrayOfObjects.reduce((min, obj) => obj < min ? obj : min, arrayOfObjects[0]);
}

export const findCarriers = (normalisedData) => {
    let flights={}
    normalisedData.forEach((data) => {
        let { outbound: { flightSummary: [flight] }, totalFare } = data || {}
        const selectedFlight = flights[flight.carrier]
        
        if (selectedFlight && selectedFlight.data){
            selectedFlight.fare = selectedFlight.fare > totalFare ? totalFare : selectedFlight.fare;
            selectedFlight.data = [...selectedFlight.data, data]
            flights[flight.carrier] = selectedFlight         

        } else {
            flights[flight.carrier] = { data: [data], fare: totalFare, carrierName:flight.carrierName }
        }

    })
    
    return flights
}

export const getBaggageData=(normalisedData,key)=>{
    let customObj = {
        FREE_BAGGAGES: {},
        CHARGED_BAGGAGES: {},
    }
    let freeBagagePrice = [];
    let chargedBaggagePrice = [];
    
    normalisedData && normalisedData.forEach(itinerary => {
        const {outbound: {itinerarySummary: {freeBaggageAllowance, chargedBaggageAllowance}}} = itinerary;
        if(freeBaggageAllowance && (Number(freeBaggageAllowance?.quantity) > 0 || Number(freeBaggageAllowance?.weight) > 0)){
            freeBagagePrice.push(itinerary.totalFare);
            if(customObj["FREE_BAGGAGES"] && customObj["FREE_BAGGAGES"]["data"]){
                customObj["FREE_BAGGAGES"]["data"] = [...customObj["FREE_BAGGAGES"]["data"], itinerary]
            }else{
                customObj["FREE_BAGGAGES"]["data"] = [itinerary]
            }
        } 
        if(chargedBaggageAllowance && (Number(chargedBaggageAllowance?.[0]?.firstPiece) > 0 || Number(chargedBaggageAllowance?.[0]?.lastPiece) > 0)){
            let fare=Number(chargedBaggageAllowance?.[0]?.amount)
            chargedBaggagePrice.push(fare);
            if(customObj["CHARGED_BAGGAGES"] && customObj["CHARGED_BAGGAGES"]["data"]){
                customObj["CHARGED_BAGGAGES"]["data"] = [...customObj["CHARGED_BAGGAGES"]["data"], itinerary]
            }else{
                customObj["CHARGED_BAGGAGES"]["data"] = [itinerary]
            }
        }
    })
    if(customObj["FREE_BAGGAGES"]?.data){
        customObj["FREE_BAGGAGES"]["fare"] = Math.min(...freeBagagePrice)
    }
    if(customObj["CHARGED_BAGGAGES"]?.data){
        customObj["CHARGED_BAGGAGES"]["fare"] = Math.min(...chargedBaggagePrice)
    }

    return customObj;
}

export const getAirport=(normalisedData,type)=>{
    let uniqueAirports=[]
    
    normalisedData && normalisedData.forEach((data)=>{
        let { outbound:{itinerarySummary:{departureAirport, arrivalAirport, arrivalAirportCity, departureAirportCity}}, totalFare } = data;
        
        
        if(type=="departure"){
            let existingAirportIndex = uniqueAirports.findIndex(airport => airport.departureAirport == departureAirport);
            if (existingAirportIndex == -1) {
                uniqueAirports.push({
                    departureAirport: departureAirport,
                    departureAirportCity:departureAirportCity,
                    minFare: totalFare
                });
            } else {
                
                if (totalFare < uniqueAirports[existingAirportIndex].minFare) {
                    uniqueAirports[existingAirportIndex].minFare = totalFare;
                }
            }
        }else{
            let existingAirportIndex = uniqueAirports.findIndex(airport => airport.arrivalAirport == arrivalAirport);
            if (existingAirportIndex == -1) {
                uniqueAirports.push({
                    arrivalAirport: arrivalAirport,
                    arrivalAirportCity:arrivalAirportCity,
                    minFare: totalFare
                });
            } else {
                
                if (totalFare < uniqueAirports[existingAirportIndex].minFare) {
                    uniqueAirports[existingAirportIndex].minFare = totalFare;
                }
            }
        }

    })
    
    return uniqueAirports;
}

export const getLayovers=(normalisedData,type)=>{
    let uniqueAirports=[]
    
    normalisedData && normalisedData.forEach((data)=>{
        let { outbound:{layovers} } = data;
        
        layovers.forEach((item)=>{
            let {airport,airportCity}= item;
            let existingAirportIndex = uniqueAirports.findIndex(elem => elem.airport == airport);
            if (existingAirportIndex == -1) {
                uniqueAirports.push({
                    airport: airport,
                    airportCity:airportCity,
                });
            }
        })
        
        
        

    })
    
    return uniqueAirports;
}

export const getTimeRange=(normalisedData,type)=>{

    let earliestDepartureTime=Moment(normalisedData[0].outbound.itinerarySummary.departureDateTime);
    let latestDepartureTime=Moment(normalisedData[0].outbound.itinerarySummary.departureDateTime);
    let earliestDepartureDateTime=new Date(normalisedData[0].outbound.itinerarySummary.departureDateTime).getTime();
    let latestDepartureDateTime=new Date(normalisedData[0].outbound.itinerarySummary.departureDateTime).getTime();

    let earliestArrivalTime=Moment(normalisedData[0].outbound.itinerarySummary.arrivalDateTime);
    let latestArrivalTime=Moment(normalisedData[0].outbound.itinerarySummary.arrivalDateTime);
    let earliestArrivalDateTime=new Date(normalisedData[0].outbound.itinerarySummary.arrivalDateTime).getTime();
    let latestArrivalDateTime=new Date(normalisedData[0].outbound.itinerarySummary.arrivalDateTime).getTime();
    
    normalisedData && normalisedData.forEach(itinerary => {
        const {outbound: {itinerarySummary: {departureDateTime, arrivalDateTime}}} = itinerary;

        if(type=="departure"){
            let convertedTime = Moment(departureDateTime);
    
            if(earliestDepartureTime > convertedTime){
                earliestDepartureTime=convertedTime;
                earliestDepartureDateTime=new Date(departureDateTime).getTime()
            }
            if(latestDepartureTime < convertedTime){
                latestDepartureTime=convertedTime
                latestDepartureDateTime=new Date(departureDateTime).getTime()
            }
        }else{
            let convertedArrivalTime = Moment(arrivalDateTime);
            
            if(earliestArrivalTime > convertedArrivalTime){
                earliestArrivalTime=convertedArrivalTime
                earliestArrivalDateTime=new Date(arrivalDateTime).getTime()
            }
            if(latestArrivalTime < convertedArrivalTime){
                latestArrivalTime=convertedArrivalTime
                latestArrivalDateTime=new Date(arrivalDateTime).getTime()
            }

        }
    })

    let obj={}

    if(type=="departure"){
        obj={
            earliestDepartureTime: Moment(earliestDepartureTime).format('h:mm A'),
            latestDepartureTime: Moment(latestDepartureTime).format('h:mm A'),
            earliestDepartureDateTime:earliestDepartureDateTime,
            latestDepartureDateTime:latestDepartureDateTime
        }
    }else{
        obj={
            earliestArrivalTime: Moment(earliestArrivalTime).format('DD-MM-YYYY hh:mm:A'),
            latestArrivalTime: Moment(latestArrivalTime).format('DD-MM-YYYY hh:mm:A'),
            earliestArrivalDateTime:earliestArrivalDateTime,
            latestArrivalDateTime:latestArrivalDateTime
        }
    }

    return obj


}

export const getTotalDuration=(normalisedData)=>{
    let minTotalDuration= Number(normalisedData[0].outbound.totalDurationMinutes);
    let maxTotalDuration=Number(normalisedData[0].outbound.totalDurationMinutes);

    normalisedData.forEach((itinerary)=>{
        const {outbound: {totalDurationMinutes}} = itinerary;
        
        if(minTotalDuration > Number(totalDurationMinutes)){
            minTotalDuration=Number(totalDurationMinutes)
        }

        if(maxTotalDuration < Number(totalDurationMinutes)){
            maxTotalDuration=Number(totalDurationMinutes)
        }

    })

    return{
        minTotalDuration:minTotalDuration,
        maxTotalDuration:maxTotalDuration,
        minTotalTime:timeConvert(minTotalDuration),
        maxTotalTime:timeConvert(maxTotalDuration)
    }
    
}

export const getLayoverTime=(normalisedData)=>{
    let minLayoverTime=Infinity;
    let maxLayoverTime=0;
    normalisedData.forEach((itinerary)=>{
        let { outbound:{layovers} } = itinerary;
        let layoverTime=0
        layovers.forEach((item)=>{
            let {durationMinutes}= item;
            layoverTime+=Number(durationMinutes)

        })
        if(layoverTime != 0 && minLayoverTime > layoverTime){
            minLayoverTime=layoverTime
        }
        if(layoverTime != 0 && maxLayoverTime < layoverTime){
            maxLayoverTime=layoverTime
        }

    })

    return{
        minLayoverDuration:minLayoverTime,
        maxLayoverDuration:maxLayoverTime,
        minLayoverTime:timeConvert(minLayoverTime),
        maxLayoverTime:timeConvert(maxLayoverTime)
    }

}

export const fetchAllFilterResults=(normalisedData)=>{
    return {
        noOfStops: noOfStops(normalisedData,"totalFare"),
        findMinMaxValues: findMinMaxValues(normalisedData,"totalFare"),
        findCarriers: findCarriers(normalisedData),
        getBaggageData: getBaggageData(normalisedData,"totalFare"),
        getDepartureAirport: getAirport(normalisedData,"departure"),
        getArrivalAirport: getAirport(normalisedData,"arrival"),
        getOutboundLayovers: getLayovers(normalisedData,"outbound"),
        getDepartureTimeRange:getTimeRange(normalisedData,"departure"),
        getArrivalTimeRange:getTimeRange(normalisedData,"arrival"),
        getTotalDuration:getTotalDuration(normalisedData),
        getLayoverTime:getLayoverTime(normalisedData)
    }
}

export const  timeConvert = (n) => {
    let number = n;
    let hours = (number / 60);
    let rhours = Math.floor(hours);
    let minutes = (hours - rhours) * 60;
    let rminutes = Math.round(minutes);
   
    return number = rhours + " hr " + rminutes + " min";
  }

  /**
 * Formats a date string into 'YYYYMMDD' format.
 * 
 * @param {string} dateString - The date string to format.
 * @returns {string} The formatted date string.
 */

  export const formatDate = (dateString) => {
    return Moment(dateString).format('YYYYMMDD');
  };
