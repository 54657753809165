
const requestSuccessCallback = (config: any): Promise<any> => {
    return config;
}

const requestFailCallback = (error: any): Promise<any> => {
    return Promise.reject(error);
}

const responseSuccessCallback = (response: any): Promise<any> => {
    const data = response.data;
    const {error}: any = data;
    if(error){
        return Promise.reject(error.message)
    }
    return data;
}

const responseFailCallback = (error: any): Promise<any> => {
    return Promise.reject(error);
}

export {requestSuccessCallback,requestFailCallback,responseSuccessCallback,responseFailCallback};