import React, { useState } from 'react';
import {getGiftcardService,preAuthGiftcardService,postAuthGiftcardService,cancelAuthGiftcardService} from "../services";
const GetGiftCardAPI = () => {
    const [giftCardData, setgiftCardData] = useState(null);
    const [error, setError] = useState(false);
    const [giftCardLoding, setLoading] = useState(false);
    const giftcardApi = async(payload: any,correlationId:string) => {
        try {
            setLoading(true);
            setError(null);
            const res = await getGiftcardService({payload,correlationId});
            setgiftCardData(res)
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    }
    return [giftcardApi, giftCardData, giftCardLoding, error];
};


const PreAuthGiftCard = () => {
    const [preAuthdata, setpreAuthdata] = useState(null);
    const [preAutherror, setError] = useState(false);
    const [preAuthGifrcardLoading, setLoading] = useState(false);
    
    const preAuthGiftCardAPI = async (payload: any, correlationId: string) => {
        try {
            setLoading(true);
            setError(null);
            let postAuthReq = payload;
            const res = await preAuthGiftcardService({ payload, correlationId });
            if(res.response.status == 'success' && res.response.referenceNumber){
                postAuthReq.referenceNumber = res.response.referenceNumber;
                res.postGiftCardReq = postAuthReq;
            }
            setpreAuthdata(res); 
            return res;  
        } catch (error) {
            setError(error);
            throw error;  
        } 
    }
   
    return [preAuthGiftCardAPI, preAuthdata, preAuthGifrcardLoading, preAutherror];
};

const PostAuthGiftCard = () => {
    const [postAuthdata, setpostAuthdata] = useState(null);
    const [postAutherror, setError] = useState(false);
    const [postAuthGifrcardLoading, setLoading] = useState(false);
    const PostAuthGiftCardAPI = async(payload: any,correlationId:string) => {
        try {
            setLoading(true);
            setError(null);
            const res = await postAuthGiftcardService({payload,correlationId});
            setpostAuthdata(res)
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    }
    return [PostAuthGiftCardAPI, postAuthdata, postAuthGifrcardLoading, postAutherror];
};
const CancelPreAuth = () => {
    const [cancelAuthdata, setcancelAuthdata] = useState(null);
    const [cancelAutherror, setError] = useState(false);
    const [cancelAuthGifrcardLoading, setLoading] = useState(false);
    const CancelAuthGiftCardAPI = async(payload: any,correlationId:string) => {
        try {
            setLoading(true);
            setError(null);
            const res = await cancelAuthGiftcardService({payload,correlationId});
            setcancelAuthdata(res)
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    }
    return [CancelAuthGiftCardAPI, cancelAuthdata, cancelAuthGifrcardLoading, cancelAutherror];
};

export {GetGiftCardAPI,PreAuthGiftCard,PostAuthGiftCard,CancelPreAuth};