import React from 'react';
import { Grid, Box, Typography, TextField, Container, RadioGroup, Radio, FormControlLabel, Button, Checkbox, FormControl, InputLabel, NativeSelect, Select, MenuItem } from '@mui/material';
import LogosAnimation from '../common/BookWithPaymentComponents/loaders/LogosAnimation';

const Loader = () => {
    return (
        <>
            <Container className="loader-list loader-searchList ">
                <Grid container spacing={2}>

                    <Grid item sm={4} xs={12}>
                 
                        <div className="center-space">
                            <div className="placeholder short"></div>
                            <div className="placeholder short"></div>
                        </div>
                        <div className='bg-card'>
                            <div className="center-space">
                                <div className="placeholder short"></div>
                                <div className="placeholder short"></div>
                            </div>
                            <div className="center-space">
                                <div className="placeholder short"></div>
                            </div>
                            <div className="center-space">
                                <div className="checkbox-left">
                                    <div className="placeholder checkbox"></div>
                                    <div className="placeholder checkbox-text"></div>
                                </div>
                                <div className="placeholder short"></div>
                            </div>
                            <div className="center-space">
                                <div className="checkbox-left">
                                    <div className="placeholder checkbox"></div>
                                    <div className="placeholder checkbox-text"></div>
                                </div>
                                <div className="placeholder short"></div>
                            </div>
                            <div className="center-space">
                                <div className="checkbox-left">
                                    <div className="placeholder checkbox"></div>
                                    <div className="placeholder checkbox-text"></div>
                                </div>
                                <div className="placeholder short"></div>
                            </div>
                        </div>
                        <div className='bg-card'>
                            <div className="center-space">
                                <div className="placeholder short"></div>
                                <div className="placeholder short"></div>
                            </div>
                            <div className="center-space">
                                <div className="checkbox-left">
                                    <div className="placeholder checkbox"></div>
                                    <div className="placeholder checkbox-text"></div>
                                </div>
                                <div className="placeholder short"></div>
                            </div>
                            <div className="center-space">
                                <div className="checkbox-left">
                                    <div className="placeholder checkbox"></div>
                                    <div className="placeholder checkbox-text"></div>
                                </div>
                                <div className="placeholder short"></div>
                            </div>
                            <div className="center-space">
                                <div className="checkbox-left">
                                    <div className="placeholder checkbox"></div>
                                    <div className="placeholder checkbox-text"></div>
                                </div>
                                <div className="placeholder short"></div>
                            </div>
                            <div className="center-space">
                                <div className="checkbox-left">
                                    <div className="placeholder checkbox"></div>
                                    <div className="placeholder checkbox-text"></div>
                                </div>
                                <div className="placeholder short"></div>
                            </div>
                        </div>

                    </Grid>
                    <Grid item sm={8} xs={12}>
                        <div className="center-space">
                    
                            <div className="placeholder short"></div>
                            <div className="placeholder short"></div>
                        </div>
                        <div className="placeholder full" style={{ height: 100 }}>
                        <LogosAnimation/>
                        </div>
                        <div className="bg-card">
                            <div className="center-space">
                                <div className="placeholder small"></div>
                                <div className="placeholder small"></div>
                                <div className="placeholder small"></div>
                            </div>
                            <div className="center-space">
                                <div className="placeholder small"></div>
                                <div className="placeholder small"></div>
                                <div className="placeholder small"></div>
                            </div>
                            <div className="placeholder small"></div>
                            <div className="placeholder full"></div>
                        </div>
                        <div className="bg-card">
                            <div className="center-space">
                                <div className="placeholder small"></div>
                                <div className="placeholder small"></div>
                                <div className="placeholder small"></div>
                            </div>
                            <div className="center-space">
                                <div className="placeholder small"></div>
                                <div className="placeholder small"></div>
                                <div className="placeholder small"></div>
                            </div>
                            <div className="placeholder small"></div>
                            <div className="placeholder full"></div>
                        </div>
                    </Grid>

                </Grid>

            </Container>
        </>
    );
};

export default Loader;