import { AxiosRequestConfig, AxiosResponse } from "axios";
import { b2cService } from "../axiosServices";

interface RequestBodyInterface {
    reqBody:Object;
    correlationId:String;

}

const getBookingDetailsService = async (postBody: RequestBodyInterface,config: AxiosRequestConfig = {}) => {
    const { reqBody, correlationId }=postBody
    const headers = {
        "Bookingchannel": "external", // Example header, adjust as needed
        "Correlationid": `${correlationId}`, //" f149d7a0-76e0-4e6b-a629-34944c2a6374",
        "Flighttype": "CAD"
        // Add more headers as needed
    };

    // Merge provided config with headers
    const requestConfig: AxiosRequestConfig = {
        ...config,
        headers: {
            ...config?.headers,
            ...headers
        }
    };

    let data: any = await b2cService.post(`/booking/getBookingDetails`, reqBody,requestConfig);

    return data.data;
}

export { getBookingDetailsService };