import React, { ElementType } from 'react'
import { Autocomplete, TextField, Typography, Select, MenuItem, SelectChangeEvent, Grid } from '@mui/material'
import { DEFAULT_FUNCTION } from '../../utils/constant';
import { GridInterface } from '../../interfaces/commonInterface';

interface SelectComponentInterface {
    value?: string;
    selectClassName?: string;
    onChangeMethod?: Function;
    label?: string;
    labelClassName?: string;
    options?: [];
    placeHolder?: string;
    fullWidth?: boolean;
    name: string;
    gridOptions: GridInterface & { component?: ElementType<any> };
}

const SelectComponent = ({value = "", onChangeMethod = DEFAULT_FUNCTION, options = [], label = "", selectClassName = "", labelClassName = "", placeHolder = "", fullWidth = true, name = "", gridOptions = {}}: SelectComponentInterface) => {
    return (
        <Grid {...gridOptions}>
            <Typography className={labelClassName}>{label}</Typography>
            <Select name={name} className={selectClassName} value={value} onChange={(e: SelectChangeEvent) => onChangeMethod(parseInt(e.target.value))} fullWidth={fullWidth} placeholder={placeHolder} label={placeHolder}>
                {options.map((option, index) => (
                    <MenuItem key={index} value={option} style={{ fontSize: '14px' }}>
                        {option}
                    </MenuItem>
                ))}
            </Select>
        </Grid>
    )
}

export default SelectComponent;