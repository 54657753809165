import React, { useState } from 'react';
import { Typography, TextField, Select, MenuItem, Box, Grid, Button } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { Add } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

interface FrequentFlyerData {
    carrierName: string;
}

interface FrequentFlyerProps {
    frequentFlyerData: FrequentFlyerData[];
    handleInputChange: (frequentFlyers: { programId: string; number: string }[]) => void;
    fieldErrors: { programId: boolean; programNumber: boolean };
}

type FrequentFlyerField = 'programId' | 'number';

function FrequentFlyer({ frequentFlyerData, handleInputChange, fieldErrors }: FrequentFlyerProps) {
    const { t } = useTranslation();
    const [focusStates, setFocusStates] = useState<{ [key: string]: boolean }>({
        showFrequentFlyerCard: false,
    });
    const [frequentFlyers, setFrequentFlyers] = useState([
        { programId: '', number: '' }
    ]);

    const handleFocus = (field: string, index: number) => {
        setFocusStates((prevStates) => ({
            ...prevStates,
            [field + index]: true,
        }));
    };

    const handleBlur = (field: string, index: number) => {
        setFocusStates((prevStates) => ({
            ...prevStates,
            [field + index]: false,
        }));
    };

    const toggleFrequentFlyerCard = () => {
        setFocusStates((prevStates) => ({
            ...prevStates,
            showFrequentFlyerCard: !prevStates.showFrequentFlyerCard,
        }));
    };

    const handleFrequentFlyerChange = (index: number, name: FrequentFlyerField, value: string) => {
        const updatedFrequentFlyers = [...frequentFlyers];
        updatedFrequentFlyers[index][name] = value;
        setFrequentFlyers(updatedFrequentFlyers);
        handleInputChange(updatedFrequentFlyers); // Correctly send the updated frequent flyer list
    };

    const addMoreFrequentFlyer = () => {
        setFrequentFlyers([...frequentFlyers, { programId: '', number: '' }]);
    };

    return (
        <>
            <Box className="FrequentPro" onClick={toggleFrequentFlyerCard}>
                <Typography className='FrequentFlyer_Title' variant='h3'>{t('Frequent Flyer Program')}</Typography>
                {focusStates.showFrequentFlyerCard ? <ArrowDropUpIcon className='ArrowIcon' /> : <ArrowDropDownIcon className='ArrowIcon' />}
            </Box>
            {focusStates.showFrequentFlyerCard && (
                <>
                    {frequentFlyers.map((flyer, index) => (
                        <Grid container spacing={2} className='FrequentFlyerCard' key={index} style={{ marginTop: "8px", }}>
                            <Grid item xs={12} sm={6}>
                                <Box>
                                    <Typography
                                        component={"h2"}
                                        sx={{ color: focusStates['programId' + index] ? 'primary.main' : 'text.primary' }}
                                        className='booking_DOB'
                                        variant='h2'
                                    >
                                        {t('Frequent Flyer Program')}
                                    </Typography>
                                    <Select
                                        name="programId"
                                        fullWidth
                                        variant="standard"
                                        value={flyer.programId}
                                        onFocus={() => handleFocus('programId', index)}
                                        onBlur={() => handleBlur('programId', index)}
                                        IconComponent={ArrowDropDownIcon}
                                        onChange={(e) => handleFrequentFlyerChange(index, 'programId', e.target.value as string)}
                                        error={fieldErrors.programId}
                                    >
                                        {/* <MenuItem value="">
                                            <em>--</em>
                                        </MenuItem> */}
                                        {frequentFlyerData?.map((item: any, i: number) => (
                                            <MenuItem value={item.carrierName} key={i}>{item?.carrierName}</MenuItem>
                                        ))}
                                    </Select>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Box>
                                    <Typography
                                        component={"h2"}
                                        sx={{ color: focusStates['number' + index] ? 'primary.main' : 'text.primary' }}
                                        className='booking_DOB'
                                        variant='h2'
                                    >
                                        {t('Program Number')}

                                    </Typography>
                                    <TextField
                                        variant="standard"
                                        fullWidth
                                        value={flyer.number}
                                        onFocus={() => handleFocus('number', index)}
                                        onBlur={() => handleBlur('number', index)}
                                        name='number'
                                        onChange={(e) => handleFrequentFlyerChange(index, 'number', e.target.value)}
                                        error={fieldErrors.programNumber}
                                        helperText={fieldErrors.programNumber ? 'Invalid Program Number' : ''}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    ))}
                    <Grid container spacing={2} className="FrequentFlyerAddMoreBtn_Grid">
                        <Typography variant='caption' className="FrequentFlyerAddMoreBtn_Desc FreFlyerPro_Tips">{t("We share program details with the airline, though we cannot guarantee point awards")}</Typography>
                        {/* <Button startIcon={<Add />} className="FrequentFlyerAddMoreBtn" onClick={addMoreFrequentFlyer} sx={{ mt: 2 }}>
                            Add More
                        </Button> */}
                    </Grid>
                </>
            )}
        </>
    );
}

export default FrequentFlyer;
