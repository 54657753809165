import { createAction } from "@reduxjs/toolkit";
import { ACTIONS } from "../constants/actions";
import { Dispatch } from "redux";

const updateSearchResults = createAction<any[]>(`searchResults/${ACTIONS.UPDATE_SEARCH_RESULTS}`);
const isSearchLoading = createAction<boolean>(`searchResults/${ACTIONS.SEARCH_IS_LOADING}`);
const tripType = createAction<boolean>(`searchResults/${ACTIONS.TRIP_TYPE}`);
const searchRequest = createAction<any[]>(`searchResults/${ACTIONS.SEARCH_REQUEST}`);
const correlationId = createAction<any[]>(`searchResults/${ACTIONS.CORRELATIONID}`);
const searchResultHit = createAction<any[]>(`searchResults/${ACTIONS.SEARCHRESULTHIT}`);

export const UpdateSearchResultsData: any = (resultData) => {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(updateSearchResults(resultData));
    } catch (error) {
      // Handle errors
      console.error("Error fetching search results:", error);
    }
  };
};

export const SearchIsLoading: any = (isLoading = false) => {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(isSearchLoading(isLoading));
    } catch (error) {
      // Handle errors
      console.error("Error", error);
    }
  };
};

export const UpdateSearchRequest: any = (requestData) => {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(searchRequest(requestData));
    } catch (error) {
      // Handle errors
      console.error("Error fetching search request:", error);
    }
  };
}

export const setTripType: any = (value = false) => {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(tripType(value));
    } catch (error) {
      // Handle errors
      console.error("Error", error);
    }
  };
};

export const UpdateCorrelationId: any = (value) => {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(correlationId(value));
    } catch (error) {
      // Handle errors
      console.error("Error", error);
    }
  };
};

export const setSearchResultHit: any = (value) => {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(searchResultHit(value));
    } catch (error) {
      // Handle errors
      console.error("Error", error);
    }
  };
};