import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SearchResultsListComponent, LoadingScreen } from 'gigi-component-library';
import { SearchResultTranslation } from './SearchResultTranslation';
import { BrandedFareAPI, GetPromoCode } from 'gigi-api-layer';
import { updateSelectedBrandedFareId, updateBrandedFaresData } from '../../redux/actions/brandedFare';
import { ROUTES } from '../../utility/constants';
import {ErrorNotifier, ErrorHandler} from 'gigi-component-library';
import UpliftInitResult from '../Uplift/upliftInitResult';
import { setSearchResultHit } from '../../redux/actions/search';

const AllSearchResults = () => {
    const searchResults = useSelector((state: any) => state.search.searchResults);
    const searchRequest = useSelector((state: any) => state.search.searchRequest);
    const isLoading = useSelector((state: any) => state.search.searchIsLoading);
    const triptype = useSelector((state: any) => state.search.tripType);
    const brandFareData = useSelector((state:any)=>state.brandedFares.brandedFareResult);
    const correlationId = useSelector((state: any) => state.search.correlationId);
    const searchResultApiHit = useSelector((state:any)=>state.search.searchClicked);
    const [outboundData, setOutboundData]: any = useState([]);
    const [inboundData, setInboundData]: any = useState([]);
    const [searchResultsData, setSearchResultsData] = useState<SearchResultTranslation | null>(null);
    const [brandedFaresResultsAPI, brandedFaresResults, brandedFaresInProgress, brandedFaresError]= BrandedFareAPI();
    const [version, setVersion] = useState(0); // version state to trigger re-render
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const TripInfoData = useSelector((state: any) => state.tripInfoResult.uplift);
    
    const {UPLIFT_CONFIG = {}, PROMO_CONFIG = {}} = process.env;
    const [getPromoCode, promoCodeResults, promoCodeInProgress, promoCodeError] = GetPromoCode();
    
    const handleApplyPromo = async (payload) => {
        getPromoCode(payload, correlationId)
    }

    useEffect(() => {
        if(searchResults?.faresOptions?.length > 0 ){
            const allSearchResults = new SearchResultTranslation(searchResults);
            setSearchResultsData(allSearchResults);   
            dispatch(setSearchResultHit(false)) 
        }else{
            if(!isLoading && searchResultApiHit){
                dispatch(setSearchResultHit(false))
                ErrorHandler('No Search Result Found', 'No search result found for your search. Please change some parameters and try again. ',null ,  'Ok',  'warning','alertModal')
            }
            setSearchResultsData(null)
        }
        dispatch(updateBrandedFaresData([]))
    }, [searchResults]);

    const setNextLevel = (refId:any, index?:number) => {
        if (searchResultsData) {
            searchResultsData.setReferenceId = refId
            searchResultsData.setDataLevel = (index || index === 0) ? index : searchResultsData.getCurrentLevel + 1;
            setVersion((prevVersion) => prevVersion + 1); // Update version to force re-render
        }
    };

    function setPricingAPIData(fareID){
        if(fareID){
            dispatch(updateSelectedBrandedFareId({fareID}))
            const queryParams = new URLSearchParams({ uid: correlationId});
            navigate(`${ROUTES.booking}?${queryParams.toString()}`);
        }
    }

    useEffect(()=>{
        if(brandedFaresInProgress){
            dispatch(updateBrandedFaresData([]))
        }else{
            dispatch(updateBrandedFaresData(brandedFaresResults));
            if ((brandedFaresResults && (!brandedFaresResults.hasOwnProperty('itineraryFares')))) {
                if(brandedFaresResults.hasOwnProperty('MESSAGE')){
                    ErrorHandler('Branded Fares Error', brandedFaresResults.MESSAGE,null ,  'Ok',  'warning','alertModal')
                }else{
                    ErrorHandler('Branded Fares Error', 'A technical error prevents us from booking.',null ,  'Ok',  'warning','alertModal')
                }
            }
            
        }
    },[brandedFaresInProgress,brandedFaresResults])

    return (
        <div>
            {isLoading && <LoadingScreen />}
            {!isLoading && searchResultsData && (
                <>
                 {searchRequest!='' && UPLIFT_CONFIG["ENABLE_FEATURE"] ? <UpliftInitResult  />:''} 
                <SearchResultsListComponent
                    key={version} // Use version as key to force re-render
                    getSearchResult={searchResultsData.getCurrentLevelData}
                    inboundData={inboundData}
                    filterData={searchResultsData.allFilterData || {}}
                    ifNextLevelExist={searchResultsData.doesNextLevelExist}
                    setNextLevel={setNextLevel}
                    brandedFaresResultsAPI={(fareID) => brandedFaresResultsAPI(fareID, searchResults.token, correlationId)}
                    brandedFaresResults={brandFareData}
                    pricingAPI={(fareID) => setPricingAPIData(fareID)}
                    brandedFaresLoader={brandedFaresInProgress}       
                    allReference = {searchResultsData.getReferenceId}
                    dataLevelId = {searchResultsData.getDataLevelIds}
                    triptype={triptype}
                    searchRequest = {searchRequest}
                    TripInfoData = {TripInfoData}
                    promoconfig={PROMO_CONFIG} 
                    callPromoCodeApI={handleApplyPromo} 
                    promoCodeResults={promoCodeResults} 
                    promoCodeInProgress={promoCodeInProgress}
                />
                </>
            )}

            <ErrorNotifier/>
        </div>
    );
};

export default AllSearchResults;
