import React from "react";
import { Provider } from "react-redux";
import { createRoot } from 'react-dom/client';
import { ErrorBoundary } from "gigi-component-library";

import App from "./components/App";
import "./styles/main.scss"
import store from "./redux/store";

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<Provider store={store}>
  <ErrorBoundary>
    <App />
  </ErrorBoundary>
</Provider>
);