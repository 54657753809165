import React,{useState} from 'react';
import { Box, Button, Container, Grid, Table, TableCell, TableRow, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import TermsOfService from '../common/BookingConfirmation/TermsOfService';
import { CardLabel } from '../common/CardLabel';
import BillingAddress from '../common/BookingConfirmation/BillingAddress';
import BillingDetails from '../common/BookingConfirmation/BillingDetails';
import ThankYouSection from '../common/BookingConfirmation/ThankYouSection';
import TravelerDetails from '../common/BookingConfirmation/TravelerDetails';
import FlightDetailsCard from '../common/BookingConfirmation/FlightDetailsCard';
import BookingSummary from '../common/BookingConfirmation/BookingSummary';
import FlightDetailsCardSkeleton from '../Loader/FlightDetailsCardSkeleton';
import { BookingSummarySkeleton } from '../Loader';
import { TRIPDIRECTION , INSURANCEPLANCODE, INSURANCESTR, BookingFrom, PASSENGERTYPE} from '../../utils/constant';
import PriceSummaryComponent from '../common/BookWithPaymentComponents/PriceSummary';
import InsuranceDetails from '../common/BookingConfirmation/InsuranceDetails';
import { Stepper } from '../Stepper/Stepper';
import { useTranslation } from 'react-i18next';

const FlightDetailCardHeader = ({ title, BookingTitle, BookingCode }: any) => {
    return (<>
        <Box className="flightDet_cardHeader">
            <Typography className='flightDet_cardTitle'> {title}</Typography>
            <Box>
                <Typography className='flightDet_cardTitle' component={'span'}> {BookingTitle}</Typography>
                <Typography className='flightDet_cardCode' component={'span'}> {BookingCode} </Typography>
            </Box>

        </Box>
    </>)
}

const FlightDetailCard = ({ segments }: any) => {

}

interface FareRuleDetail {
    title: string;
    text: string;
}

interface FareRulesInterface {
    fareBasisCode: string;
    fareDirection: string;
    carrier: string;
    departureAirport: string;
    arrivalAirport: string;
    departureDateTime: string;
    FareRuleDetails: FareRuleDetail[];
}

interface InsurancePlan {
    adult: number;
    child: number;
    planname: string;
    perDay: number;
    amount: string;
    childamount: string;
    base: string;
    tax: string;
}

interface InsBreakdown  {
    [key: string]: InsurancePlan;  // Dynamic keys like 'RGIN', 'REMU', etc.
};


export default function Confirmation(props: any) {
    const { t } = useTranslation();
    const [customPassengerData, settCustomPassengerData] = useState([]);
    let allData = props?.getBookingDetailsData;
    let bookingDetails = allData?.bookingDetails
    let getBookingDetailsInProgress = props?.getBookingDetailsInProgress;
    let passengerData = bookingDetails?.passengers
    let segmentData = bookingDetails?.segments
    let paymentData = bookingDetails?.payments
    let statusDetail = allData?.bookingStatusDetail
    let recordLocator = bookingDetails?.recordLocator
    let itineraryFare = bookingDetails?.itineraryFare
    let totalAmount = itineraryFare?.totalAmount?.amount;

    let giftCardPayload = props?.giftCardPayload;

    let PaymentMethod = props?.PaymentMethod;

    const { API_KEY, LOCALE, CURRENCY } = (process.env.UPLIFT_CONFIG ? process.env.UPLIFT_CONFIG : {}) as { API_KEY?: string; LOCALE?: string; CURRENCY?: string };

    let quoteBookingData = props?.quoteBookingData;
    
    let [outboundFareRules, setOutboundFareRules] = React.useState([])
    let [inboundFareRules, setInboundFareRules] = React.useState([])
    const [insuranceData, setInsuranceData] = React.useState({
        insuranceTotalAmount: null,
        insuranceStatusText: '',
        insuranceBreakdown: {}
    })

    const faresRules: FareRulesInterface[] = props?.fareRulesForPNRData?.fareRules || [];

    React.useEffect(() => {
        if(bookingDetails?.passengers){
            console.log("customPax", bookingDetails?.passengers)
            let paxData = bookingDetails?.passengers?.reduce((acc: any, data: any, index: number) => {
                const passengerInfo = { ...data, index: index + 1 }; // Add a 1-based index to passenger data
            
                // Group passengers by paxType and map them with their details
                if (data.paxType === 'Adult') {
                    acc.Adult.push(passengerInfo);
                } else if (data.paxType === 'Child') {
                    acc.Child.push(passengerInfo);
                } else if (data.paxType === 'InfantInLap') {
                    acc.InfantInLap.push(passengerInfo);
                }
                
                return acc;
            }, { Adult: [], Child: [], InfantInLap: [] });

            
            settCustomPassengerData([...paxData.Adult, ...paxData.Child, ...paxData.InfantInLap]);
        }
    }, [bookingDetails])

    React.useEffect(() => {
        if (quoteBookingData) {
            let insuranceStatusText = '';
            if (quoteBookingData?.number) { // you have selected insurance
                insuranceStatusText = INSURANCESTR.comConfirmed;
            } else if (quoteBookingData?.error) { // you have selected insurance but got error
                insuranceStatusText = INSURANCESTR.comPending;
            } else { // you have declined insurance
                insuranceStatusText = INSURANCESTR.comDecline;
            }

            let insbreakdown: InsBreakdown = {};
            quoteBookingData?.quoteTranslation?.paxInsArray.forEach((paxItem: any, index: number) => {
            const passenger = quoteBookingData.quoteTranslation.travellerArray[index];

                if (!insbreakdown[paxItem.insCode]) {
                    insbreakdown[paxItem.insCode] = {
                        adult: passenger.paxType === PASSENGERTYPE.ADULT ? 1 : 0,
                        child: passenger.paxType !== PASSENGERTYPE.ADULT ? 1 : 0,
                        planname: paxItem.planName,
                        perDay: paxItem.perDay,
                        amount: paxItem.amount,
                        childamount: paxItem.childamount,
                        base: paxItem.base,
                        tax: paxItem.tax,
                    };
                } else {
                    if (passenger.paxType === PASSENGERTYPE.ADULT) {
                        insbreakdown[paxItem.insCode].adult += 1;
                    } else {
                        insbreakdown[paxItem.insCode].child += 1;
                        insbreakdown[paxItem.insCode].childamount = paxItem.childamount;
                    }
                }
            });

            let insTotalAmount = null;
            if(insuranceStatusText == INSURANCESTR.comConfirmed)
            {
                if(quoteBookingData?.quoteTranslation?.paxInsArray){
                    
                    insTotalAmount = quoteBookingData.quoteTranslation.paxInsArray.reduce((sum:number, option: any) => sum + Number(option.amount), 0);
                    insTotalAmount = insTotalAmount.toFixed(2);
                }
            }

             // Update insuranceData with all values
            setInsuranceData({
                insuranceTotalAmount: insTotalAmount,
                insuranceStatusText: insuranceStatusText,
                insuranceBreakdown: insbreakdown,
            });
        }
    }, [quoteBookingData])

    React.useEffect(() => {
        if (faresRules.length > 0) {
            let outboundFareRule = faresRules.filter((item): boolean => item.fareDirection === TRIPDIRECTION.OUTBOUND).flatMap((item) => item.FareRuleDetails);
            let inboundFareRule = faresRules.filter((item): boolean => item.fareDirection === TRIPDIRECTION.INBOUND).flatMap((item) => item.FareRuleDetails);
            setOutboundFareRules(outboundFareRule)
            setInboundFareRules(inboundFareRule)
        } 
    }, [faresRules])
      
    React.useEffect(() => {
        if(recordLocator && PaymentMethod == 'upliftpayment'){
            window.Uplift.Payments.init({
                apiKey: API_KEY,
                locale: LOCALE,
                currency: CURRENCY,
            });
            window.Uplift.Payments.confirm(recordLocator)
        }
    }, [recordLocator])

    React.useEffect(() => {

    }, [allData])

    return (
        <>
            <Stepper page='CONFIRMATION'/>  
            <Box className='confirmation__Main'>
                {/* {segmentData && <FlightDetails segments={segmentData.flatMap((item:any)=>Object.values(item))} />}
            { itineraryFare && <PriceSummaryComponent itineraryFare={itineraryFare} />} */}

                <Container>
                    <ThankYouSection recordLocator={recordLocator} statusDetail={statusDetail} insuranceStatusText={insuranceData.insuranceStatusText} allData={allData} />

                    {(!getBookingDetailsInProgress) && <BookingSummary segmentData={segmentData} tripType={allData?.tripType} />}
                    {getBookingDetailsInProgress && <BookingSummarySkeleton />}
                    <Box className="conftion__wrapper">
                        <Box className="conftion__header">
                            <Typography className='conftionTitle'>{t('Flight Details')}</Typography>
                        </Box>
                        <hr className='conftion_hr' />
                        <Box className="flightDetails">
                            {(segmentData && (!getBookingDetailsInProgress)) && <FlightDetailsCard segments={segmentData} recordLocator={recordLocator} />}
                            {getBookingDetailsInProgress && <FlightDetailsCardSkeleton />}
                        </Box>
                        <Box className="conftion__infoTag">
                            <Box className="conftion_infoheader">
                                <InfoIcon />


                                <Typography className='conftion_infoTitle'>
                                    {t('You are responsible for reconfirming your flight times with Air Canada at least 24 hours prior to your flight departure via their website, by calling them or check with your local airport authority. You must check in at least 3 hours prior to your flight departure.')}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>

                    {outboundFareRules && <TermsOfService outboundFareRules={outboundFareRules} inboundFareRules={inboundFareRules} segmentData={segmentData} />}

                    <InsuranceDetails quoteBookingData={quoteBookingData} passengerData={passengerData} />
                    <TravelerDetails passenger={customPassengerData} />

                    {/* <Box className="conftion__wrapper travel_scetion">
                        <Box className="conftion__header">
                            <Typography className='conftionTitle'>Travel insurance Details</Typography>
                        </Box>
                        <hr className='conftion_hr' />
                        <Box className="travelIns_content">
                            <Typography className='travelIns_Title'>Manulife Policy: </Typography>  <Typography className='travelIns_status'> Declined</Typography>
                        </Box>
                        <Button className='travelIns_AddBtn'>Add Insurance</Button>
                    </Box> */}

                    {itineraryFare && <PriceSummaryComponent itineraryFare={itineraryFare}  insuranceBreakdown={insuranceData.insuranceBreakdown} giftCardBreakdown={giftCardPayload} insuranceTotal={insuranceData.insuranceTotalAmount} componentCalledFrom={BookingFrom.CONFIRMATION} />}
                    {/* <Box className="conftion__wrapper">
                        <Box className="conftion__header">
                            <Typography className='conftionTitle'>Price Summary</Typography>
                        </Box>
                        <hr className='conftion_hr' />
                        <Box className='PriceSummary'>
                            <Box className="PriceSummary_Row">
                                <Typography className='priceSum_subTitle'>Invoice No.: 123456</Typography>
                                <Typography className='priceSum_subTitle'>Date: 28 May, 2023</Typography>
                            </Box>
                            <Box className="PriceSummary_Row">
                                <Box>
                                    <Typography className='priceSum_Title'>Base price </Typography>
                                    <Typography className='priceSum_subTitle'>2 Adults X $550.00</Typography>
                                </Box>
                                <Typography className='priceSum_Title'> $1,100.00</Typography>
                            </Box>
                            <Box className="PriceSummary_Row">
                                <Box>
                                    <Typography className='priceSum_Title'>Taxes and fees </Typography>
                                    <Typography className='priceSum_subTitle'> 2 Adults X $175.73</Typography>
                                </Box>
                                <Typography className='priceSum_Title'> $351.46</Typography>
                            </Box>
                            <Box className="PriceSummary_Row priceSum_Total">
                                <Box>
                                    <Typography component={'span'} className='priceSum_TotalTitle'> Total Price </Typography>
                                    <Typography component={'span'} className='priceSum_TotalpaidBy'> (paid by Visa 1234)</Typography>
                                </Box>
                                <Typography className='priceSum_TotalPrice'>CA $1,451.46</Typography>
                            </Box>
                        </Box>
                    </Box> */}


                    <BillingDetails paymentData={paymentData} paymentMode={PaymentMethod} totalAmount={totalAmount} insuranceTotal={insuranceData.insuranceTotalAmount} insuranceStatusText={insuranceData.insuranceStatusText}/>

                    {/* <BillingAddress /> */}


                </Container>

            </Box>

        </>

    );
}
