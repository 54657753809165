import React, { useState, ErrorInfo, ReactNode } from 'react';

interface ErrorBoundaryProps {
  children: ReactNode;
  fallbackComponent?: ReactNode;
}

interface ErrorBoundaryState {
  error: Error | null;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { error: null };
  }

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // Log the error to an error reporting service
    console.error('Error caught by error boundary:', error, errorInfo);
  }

  render() {
    const { error } = this.state;
    const { fallbackComponent, children } = this.props;

    if (error) {
      // If a fallback component is provided, render it
      if (fallbackComponent) {
        return fallbackComponent;
      }
      // Otherwise, render a default error message
      return <div>Something went wrong: {JSON.stringify(error)}</div>;
    }

    return children;
  }
}

export default ErrorBoundary;
