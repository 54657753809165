import React, { useEffect } from 'react';
import { useSelector,useDispatch } from 'react-redux';
import { formatDate } from '../../utility/commonFunctions';
import { TRIPTYPE,PASSENGERTYPE, UPLIFTTRIPTYPE } from '../../utility/constants';

import { updateUpliftData } from '../../redux/actions/uplift';
declare global {
  interface Window {
    Uplift: any;
    upReady: () => void;
  }
}

interface Segment {
  departureLocation: string;
  arrivalLocation: string;
  departureDate: string;
}

interface PassengersArr{
  quantity:number,
  type:string
}
interface SearchRequest {
  tripType: string;
  segments: Segment[];
  passengers: PassengersArr[];
}

interface Itinerary {
  departure_apc: string;
  arrival_apc: string;
  departure_time: string;
}

interface TripInfoRequest {
  air_reservations: Array<{
    trip_type: string;
    itinerary: Itinerary[];
  }>;
  travelers: Array<{
    last_name: string;
    first_name: string;
    id: number;
  }>;
}

const getPassengerDetails = (passengers: PassengersArr[]): Array<{ last_name: string; first_name: string; id: number }> => {

  try {
    if (!passengers || !Array.isArray(passengers)) {
      throw new Error("Invalid passengers array");
    }

    const adultPassenger = passengers.find(p => p.type === PASSENGERTYPE.ADULT);

    if (!adultPassenger) {
      throw new Error("No valid adult passengers found");
    }

    const adults = adultPassenger.quantity;

    const passengerArray = Array.from({ length: adults }, (_, index) => ({
      last_name: `pax-last-${index}`,
      first_name: `pax-first-${index}`,
      id: index
    }));

    return passengerArray;

  } catch (error) {
    console.error("Error in getPassengerDetails:", error.message);
    return [];  
  }
};

const getTripData = (searchRequest: SearchRequest, itinerary: Itinerary[]): TripInfoRequest => {

  const getPassengerData = getPassengerDetails(searchRequest.passengers);
  let tripTypelabel = UPLIFTTRIPTYPE.ONEWAY;
  if(searchRequest.tripType === TRIPTYPE.ROUNDTRIP){
    tripTypelabel = UPLIFTTRIPTYPE.ROUNDTRIP;
  }
  return {
    air_reservations: [
      {
        trip_type: tripTypelabel,
        itinerary,
      },
    ],
    travelers: getPassengerData,
  };
};

const UpliftInitResult: React.FC = () => {
  const dispatch = useDispatch();

  const searchRequest = useSelector((state: { search: { searchRequest: SearchRequest } }) => state.search.searchRequest);
  const {UPLIFT_CONFIG = {}} = process.env;
 
  const itinerary_temp: Itinerary[] = searchRequest?.segments.map((elem) => ({
    departure_apc: elem.departureLocation,
    arrival_apc: elem.arrivalLocation,
    departure_time: formatDate(elem.departureDate),
  })) || [];

  const tripInfoRequest = searchRequest ? getTripData(searchRequest, itinerary_temp) : null;
  
  const initializeUplift =  () => {
    try {
      if (tripInfoRequest && window.Uplift?.Payments) {
        dispatch(updateUpliftData(tripInfoRequest));
         window.Uplift.Payments.init({
          apiKey: UPLIFT_CONFIG["API_KEY"],
          locale: UPLIFT_CONFIG["LOCALE"],
          currency: UPLIFT_CONFIG["CURRENCY"],
        });

        const timeoutId = setTimeout(function() {
          window.Uplift.Payments.load(tripInfoRequest, function () {
              clearTimeout(timeoutId);
          });
      }, 100);;
      }
    } catch (error) {
      console.error("Failed to initialize Uplift Payments:", error);
    }
  };


  useEffect(() => {
    if (searchRequest) {
      if (!window?.Uplift?.Payments) {
        window.upReady = initializeUplift;
        return;
      }
      initializeUplift();
    }
  }, [searchRequest]);

  return null;
};

export default UpliftInitResult;
