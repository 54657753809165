import React from 'react';
import { Box, Skeleton ,Typography} from '@mui/material';
import { useTranslation } from 'react-i18next';

export const BookingSummarySkeleton = () => {
    const { t } = useTranslation();
    return (
        <Box className="conftion__wrapper">
            <Box className="conftion__header">
            <Typography className='conftionTitle'>Booking Summary</Typography>
                <Box sx={{display:'flex'}}>
                    <Typography component={'span'} className='conftion_title'> {t('Supplier')}:</Typography>
                    <Skeleton variant="text" width={300} height={30}  />
                </Box>
            </Box>
            <hr className='conftion_hr' />
            <Box>
                <Box className="Booking_SummaryContent">
                    
                    <Skeleton variant="text" width={150} height={20} style={{marginRight:"5px"}}/>
                    <Skeleton variant="text" width={200} height={20} />
                </Box>
                <Box className="Booking_SummaryContent">
                    <Skeleton variant="text" width={150} height={20} />
                    <Skeleton variant="text" width={200} height={20} />
                </Box>
            </Box>
        </Box>
    );
};


