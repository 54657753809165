import { createAction } from "@reduxjs/toolkit";
import { ACTIONS } from "../constants/actions";
import { Dispatch } from "redux";

const updateBrandedFares = createAction<any[]>(`brandedFareResult/${ACTIONS.BRANDED_FARE_RESULTS}`);
const updateBrandedFareId = createAction<any[]>(`brandedFareResult/${ACTIONS.SELECTED_BRANDED_FARE_ID}`);
const pricingData = createAction<any[]>(`brandedFareResult/${ACTIONS.PRICING_RESULTS}`);

export const updateBrandedFaresData: any = (resultData) => {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(updateBrandedFares(resultData));
    } catch (error) {
      // Handle errors
      console.error("Error fetching search results:", error);
    }
  };
};

export const updateSelectedBrandedFareId: any = (resultData) => {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(updateBrandedFareId(resultData));
    } catch (error) {
      // Handle errors
      console.error("Error fetching search results:", error);
    }
  };
};

export const updatePricingData: any = (resultData) => {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(pricingData(resultData));
    } catch (error) {
      // Handle errors
      console.error("Error dispatching Pricing Data:", error);
    }
  };
};