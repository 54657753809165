import React from "react";
import { Provider } from "react-redux";
import { createRoot } from 'react-dom/client';
import { ErrorBoundary } from "gigi-component-library";

import App from "./components/App";
import "./styles/main.scss"
import store from "./redux/store";

// Function to dynamically set favicons
const faviconUrl = process.env.FAVICON_URL;

const setFavicons = () => {
  const links = [
    { rel: "icon", type: "image/png", href: `${faviconUrl}/favicon-96x96.png` },
    { rel: "icon", type: "image/svg+xml", href: `${faviconUrl}/favicon.svg` },
    { rel: "shortcut icon", href: `${faviconUrl}/favicon.ico` },
    { rel: "apple-touch-icon", href: `${faviconUrl}/apple-touch-icon.png` },
    { rel: "manifest", href: `${faviconUrl}/site.webmanifest` },
  ];

  links.forEach((linkData) => {
    const link = document.createElement("link");
    Object.entries(linkData).forEach(([key, value]) => {
      link.setAttribute(key, value as string);
    });
    document.head.appendChild(link);
  });
};

// Call the function to set favicons
setFavicons();

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<Provider store={store}>
  <ErrorBoundary>
    <App />
  </ErrorBoundary>
</Provider>
);