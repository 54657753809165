import React, { useState, useEffect } from 'react';
import { Box, Button, Skeleton, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { useTranslation } from 'react-i18next';
import { TravellerData } from '../BookWithPaymentComponents/PassengerDetails';
import FirstSeatRow from './FirstRow';
import SeatRows from './SeatRows';
import Traveller from './Traveller';
import MapLegend from './MapLegend';

interface SeatMapDetailsProps {
  travellers: TravellerData[];
  onClickSeat: (letter: string, row: number, travellerId: string, isSwapping: boolean) => void;
  selectedSeats: any;
  seatMap: any;
  selectedFlightIndex: number;
  totalFlights: number;
}

const SeatMapDetails = ({
  onClickSeat,
  selectedSeats,
  travellers,
  seatMap,
  selectedFlightIndex,
  totalFlights
}: SeatMapDetailsProps) => {
  const { t } = useTranslation();
  const [seatMapKey, setSeatMapKey] = useState(true);
  const [highlightedTravellerIndex, setHighlightedTravellerIndex] = useState(0); 
  const [swappingSeat, setSwappingSeat] = useState(true); 

  useEffect(() => {
    const firstUnselectedIndex = travellers.findIndex(traveller =>
      !selectedSeats.some((seat: any) => seat.travellerId === traveller.id)
    );
    setHighlightedTravellerIndex(firstUnselectedIndex);
  }, [selectedSeats, travellers]);

  const { seatMapData, apiStatus, error: seatMapError } = seatMap;

  if (apiStatus === 'init') {
    return null;
  }

  if (apiStatus === 'pending') {
    return <Skeleton variant="rectangular"  height='100%' width='100%'/>;
  }

  if (apiStatus === 'rejected') {
    return <Typography>Failed to load seat map</Typography>;
  }
  if (seatMapError || !seatMapData?.results?.cabins) {
    return <Typography variant='body1'>Unable to retrieve seat map. Please try again.</Typography>
  }

  const handleTravellerClick = (index: number) => {
    setHighlightedTravellerIndex(index); 
    setSwappingSeat(true);
  };

  return (
    <Box className="SeatMap_content">
      {seatMap && (
        <Box className="SeatBody">
          <MapLegend />
          <Box className="cabin_section seat_header">
            <Box className='seatMap_content'>
              <FirstSeatRow columns={seatMapData?.results?.cabins?.[0]?.columns} />
            </Box>
          </Box>
          {seatMapData?.results?.cabins?.map((cabin: any, index: number) => (
            <Box key={index} className="cabin_section">
              <Box className='seatMap_content'>
                <SeatRows
                  rowsRange={cabin.rowsRange}
                  columns={cabin.columns}
                  seats={cabin.seats}
                  travellers={travellers}
                  selectedSeats={selectedSeats}
                  onClickSeat={(letter: string, row: number) => {
                    const highlightedTraveller = travellers[highlightedTravellerIndex];
                    if (!highlightedTraveller)  { return; }
                    if (swappingSeat)
                    onClickSeat(letter, row, travellers[highlightedTravellerIndex].id, swappingSeat)
                  }}
                />
              </Box>
            </Box>
          ))}
        </Box>
      )}

      {seatMapKey && (
        <Box className="SeatMap_footer">
          {/* <Box className='SeatMap_footerHeader'>
            <InfoIcon /> <Typography className='SeatMap_footerTitle'>{t('Seatmap key')}</Typography>
          </Box> */}
          <Typography component={'span'} className='SeatMap_footerSubTitle'>
            {t('Flight')} {selectedFlightIndex} {t('of')} {totalFlights}:
          </Typography>
          <Box className="SeatMap_footerSeatSet" style={{ display: 'flex', flexDirection: 'column' }}>
            {travellers.map((traveller, index) => {
              const seat = selectedSeats.find((seat: any) => seat.travellerId === traveller.id);
              const isHighlighted = index === highlightedTravellerIndex;
              return (
                <Traveller
                  key={`${traveller.id}-${index}`}
                  traveller={traveller}
                  selectedSeat={seat?.seatId ?? ''}
                  isHighlighted={isHighlighted}
                  index={index}
                  onClickTraveller={() => handleTravellerClick(index)}
                />
              );
            })}
          </Box>
          {/* <Box className="SeatMap_footerBtn">
            <Button className='SeatMap_footerAdd seat_footerAddActive'>
              Add seat
            </Button>
            <Button className='SeatMap_footerSkip' onClick={() => setSeatMapKey(false)}>
              Skip
            </Button>
          </Box> */}
        </Box>
      )}
    </Box>
  );
};

export default SeatMapDetails;
