// rootReducer.ts

import { combineReducers } from 'redux';
import searchResultsReducer, { SearchResultState } from "./reducer/searchResults";
import brandedFares, { brandedFareResultState } from './reducer/brandedFares';
import bookingResult, {BookingResultState} from "./reducer/booking";
import tripInfoResult,{tripInfoResultState} from "./reducer/upliftFeature";
import quoteBookingResult, {QuoteBookingResultState} from "./reducer/quoteBooking";
import languageChangeReducer, { LanguageChangeState } from './reducer/languageChange';
import  giftCardResult,{ giftCardResultState } from './reducer/giftCardResult';
export interface RootState {
    search: SearchResultState;
    brandedFares: brandedFareResultState,
    bookingResult: BookingResultState,
    tripInfoResult:tripInfoResultState,
    quoteBookingResult: QuoteBookingResultState,
    languageChange: LanguageChangeState
    giftCardResult:giftCardResultState
}

const rootReducer = combineReducers({
    search: searchResultsReducer,
    brandedFares: brandedFares,
    bookingResult: bookingResult,
    tripInfoResult:tripInfoResult,
    quoteBookingResult: quoteBookingResult,
    languageChange: languageChangeReducer,
    giftCardResult:giftCardResult
});

export default rootReducer;
