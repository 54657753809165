import { useState, useCallback } from 'react';
import { SvgIconComponent } from '@mui/icons-material';

export interface ErrorState {
  title: string;
  message: string;
  icon?: SvgIconComponent;
  btnTitle?: string;
  className?: string;
  type?: 'snackbar' | 'alertModal';
}

const useErrorHandler = () => {
  const [error, setError] = useState<ErrorState | null>(null);
  const [open, setOpen] = useState(false);

  const handleError = useCallback(
    (errorTitle: string, errorMessage: string, icon?: SvgIconComponent, btnTitle = 'Close', className = '', type: 'snackbar' | 'alertModal' = 'alertModal') => {
      setError({ title: errorTitle, message: errorMessage, icon, btnTitle, className, type});
      setOpen(true);
    },
    []
  );

  const clearError = useCallback(() => {
    setError(null);
    setOpen(false);
  }, []);

  return {
    error,
    open,
    handleError,
    clearError,
    setOpen
  };
};

export { useErrorHandler };