import React, { useState } from 'react';
import { getCountries, getStates } from "../services";

const GetCountriesAPI = () => {
    const [countriesData, setCountriesData] = useState(null);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const getCountriesAPI = async () => {
        
        try {
            setLoading(true);
            setError(null);
            const {data = []} = await getCountries();
            setCountriesData(data)
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    }
    return [getCountriesAPI, countriesData, loading, error];
};

const GetStateAPI = () => {
    const [stateData, setStateData] = useState(null);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const getStateAPI = async (country: string) => {
        try {
            setLoading(true);
            setError(null);
            const {data = []} = await getStates(country);
            setStateData(data)
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    }
    return [getStateAPI, stateData, loading, error];
};

export { GetCountriesAPI, GetStateAPI };