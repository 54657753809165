import { TravellerData } from "../common/BookWithPaymentComponents/PassengerDetails"
import { SegmentFlight } from "../common/SeatMap/SeatMap"
import { AddOn, SelectedSeats } from "./Booking"

export const getAddOns = (traveller: TravellerData, flights: SegmentFlight[], selectedSeatsData: SelectedSeats ): AddOn[] => {
    let addOns: AddOn[] = []

    Object.keys(selectedSeatsData).forEach(flightId => {
        const selectedSeats = selectedSeatsData[flightId].selectedSeats;
        selectedSeats.forEach(seat => {
            const flight = flights.find(f => f.id === seat.flightId);
            if (flight && traveller.id === seat.travellerId) {
                addOns.push({
                    flightNumber: flight.number,
                    seat: {
                        seatNumber: seat.seatId
                    },
                    carrier: flight.carrier
                });
            }
        });
    });

    return addOns
}
