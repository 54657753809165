import React from 'react';
import { ErrorPage } from 'gigi-component-library';

const ErrorPageComponent = () => {

    return (
        <ErrorPage />
    );
};

export default ErrorPageComponent;