import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ACTIONS } from "../constants/actions";

export interface QuoteBookingResultState {
    quoteBooking: {};
}

const initialState: QuoteBookingResultState = {
    quoteBooking: {}
}

const QuoteBookingResult = createSlice({
    name: "quoteBookingResult",
    initialState,
    reducers: {
        [`${ACTIONS.BOOKING_QUOTE}`]: (state, action: PayloadAction<any[]>) => {
            return {...state, quoteBooking: action.payload}
        }
    }
});

export const { BOOKING_QUOTE } = QuoteBookingResult.actions;
export default QuoteBookingResult.reducer;
