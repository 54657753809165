import React, { useState } from 'react';
import { getBrandingInfo } from "../services";

const GetBrandingData = () => {
    const [brandingData, setBrandingData] = useState(null);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const getBranding = async (reqBody:any) => {

        try {
            setLoading(true);
            setError(null);
            const {data = []} = await getBrandingInfo(reqBody);
            // let result=res?.data
            setBrandingData(data)
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    }
    return [getBranding, brandingData, loading, error];
};

export { GetBrandingData };