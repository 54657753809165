import { initReactI18next } from "react-i18next";
import i18n from "i18next";
// import { EnglishMonts, FrenchMonts, EnglishShortMonths, FrenchShortMonths } from "./appStore/thunks/getCalenderLanguage";
import { englishMessages } from "../utils/Messages/EN-US-MSG";
import { frenchMessages } from "../utils/Messages/FR-DA-MSG";
import { FrenchErrorMessages } from "../utils/Messages/FR-Error-MSG";
import { englishErrorMessages } from "../utils/Messages/EN-Error-MSG";
i18n.use(initReactI18next).init({
    lng: "en",
    resources: {
        en: {
            translation: {
                ...englishMessages,
                ...englishErrorMessages,
                // ...EnglishMonts,
                // ...EnglishShortMonths
            }
        },
        fr: {
            translation: {
                ...frenchMessages,
                ...FrenchErrorMessages,
                //    ...FrenchMonts,
                //    ...FrenchShortMonths
            }
        }
    },
    keySeparator: false,
    interpolation: { escapeValue: false }
});

export default i18n;
