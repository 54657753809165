import React from "react";
import { Alert, Box, Button, Typography } from "@mui/material";
import WcIcon from '@mui/icons-material/Wc';
import CoffeeIcon from '@mui/icons-material/Coffee';
import InfoIcon from '@mui/icons-material/Info';
import CheckroomIcon from '@mui/icons-material/Checkroom';

const MapLegend = () => {
	return (
		<Box className="mapLegend">
			<Box className="mapLegend_titleRow">
				<InfoIcon /> &nbsp; <Typography className='mapLegend_title'>Seatmap Key:</Typography>
			</Box>
			<Box className="mapLegend_alertBox">
				<Alert severity="info">Prepaid seats must be booked with the carrier</Alert>
			</Box>
			<Box className="mapLegend_row no-bottom-padding">
				<Box className="mapLegend_column">
					<Box className="mapLegend_row">
						<Button className="seats seat_EmetyActive mapLegend_button"></Button>
						<Typography variant="body2" className="mapLegend_label">Available Seat</Typography>
					</Box>
					<Box className="mapLegend_row">
						<Button className="seats seat_SelectActive mapLegend_button"></Button>
						<Typography variant="body2" className="mapLegend_label">Selected Seat</Typography>
					</Box>
					<Box className="mapLegend_row">
						<Button className="seats seat_Yellow mapLegend_button"></Button>
						<Typography variant="body2" className="mapLegend_label">Chargeable Seat</Typography>
					</Box>
				</Box>
				<Box className="mapLegend_column">
					<Box className="mapLegend_row">
						<Button className="seats seat_Emety mapLegend_button"></Button>
						<Typography variant="body2" className="mapLegend_label">Unavailable</Typography>
					</Box>
					<Box className="mapLegend_row">
						<Button className="seats seat_Emety mapLegend_button">
							<WcIcon className="mapLegend_icon" />
						</Button>
						<Typography variant="body2" className="mapLegend_label">Lavatory</Typography>
					</Box>
					<Box className="mapLegend_row">
						<Button className="seats seat_Emety mapLegend_button">
							<CoffeeIcon className="mapLegend_icon" />
						</Button>
						<Typography variant="body2" className="mapLegend_label">Galley</Typography>
					</Box>
				</Box>
				<Box className="mapLegend_column">
					<Box className="mapLegend_row">
						<Button className="seats seat_Emety mapLegend_button">
							<CheckroomIcon className="mapLegend_icon" />
						</Button>
						<Typography variant="body2" className="mapLegend_label">Closet</Typography>
					</Box>
				</Box>
			</Box>
		</Box>
	)
}

export default MapLegend;
