import { Box, Button, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { calculateTotalForPassenger } from '../../../utils/util';
import InfoIcon from '@mui/icons-material/Info';
import { useTranslation } from 'react-i18next';

const UpliftPriceComponent = ({ itineraryFare,tripInfoRequest }: any) => {
    const { t } = useTranslation();
    const UPLIFT_CONFIG = process.env.UPLIFT_CONFIG || {};
    const { UPLIFT_LOGO } = UPLIFT_CONFIG as { [key: string]: string };

    useEffect(() => {
        if (tripInfoRequest && window.Uplift?.Payments) {
            window.Uplift.Payments.load(tripInfoRequest);
        }  
    }, [tripInfoRequest]);

    const totalFlightPrice = itineraryFare?.paxFares?.reduce((acc: number, current: any) => {
        return acc + calculateTotalForPassenger(current);
    }, 0);
    return (
        <div>
            <Box className="Booking_PayInfo priceSummary_uplift">
                <Box className="Booking_payTitle payUpliftTitle"><span> Pay monthly from</span>
                {totalFlightPrice > 0 && (<>
                    <Button className={"Booking_payPrice displayFlex"}
                        data-up-price-value={totalFlightPrice * 100}
                        data-up-price-type='total'
                        data-up-comparison-type=""
                        data-up-price-model='total'
                        data-up-taxes-included='true'
                    >
                        <span className="up-from-currency" data-up-from-currency="">$</span>
                        <span data-up-from-currency-unit-major="" className="mb-0"  ></span>/Mon
                        <span data-up-promo="zero-percent" className="upliftStyle">  </span>
                    </Button>
                </>)} <span> with</span> <img alt="uplift logo" className="uplift-logo-2" src={UPLIFT_LOGO} /> 
                <Button className={"upliftIcon"}
                                                    data-up-price-value={totalFlightPrice * 100}
                                                    data-up-price-type='total'
                                                    data-up-comparison-type=""
                                                    data-up-price-model='total'
                                                    data-up-taxes-included='true'
                                                >
                                                    <InfoIcon />
                                                </Button>
                </Box>
            </Box>
        </div>
    );
};

export default UpliftPriceComponent;