import React, { useState } from 'react';
import { applyPromoService } from "../services";

const GetPromoCode = () => {
    const [promoCodeData, setPromoCodeData] = useState(null);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const getPromoCode = async (payload:string,correlationId:string) => {
        try {
            setLoading(true);
            setError(null);
            const applyPromoCodeData = await applyPromoService({payload,correlationId});
            setPromoCodeData(applyPromoCodeData);
        } catch (error) {
            console.log("error",error)
            setError(error);
        } finally {
            setLoading(false);
        }
    }
    return [getPromoCode, promoCodeData, loading, error];
};
export { GetPromoCode };
