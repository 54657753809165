import { createAction } from "@reduxjs/toolkit";
import { ACTIONS } from "../constants/actions";
import { Dispatch } from "redux";

const quoteBookingData = createAction<any[]>(`quoteBookingResult/${ACTIONS.BOOKING_QUOTE}`);

export const updateQuoteBookingData: any = (resultData) => {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(quoteBookingData(resultData));
    } catch (error) {
      // Handle errors
      console.error("Error fetching quote booking results:", error);
    }
  };
};